import React from "react";
import { AiOutlineRight } from "react-icons/ai";

export const menuData = [
  {
    title: "ICICI Loan Key Features and Benefits",
    id: "features-benefits",
    subMenu: [
      {
        id: "features-benefits-iciic",
        title: "Salient Features",
        content: (
          <p>
            <ol className="expense-list">
              <li>Collateral Free Loan</li>
              <li>
                Tax benefit under section 80 E of Income Tax Act of India, 1961.
              </li>
              <li>Flexible repayment options</li>
              <li>Zero processing fees</li>
              <li>
                Quick and hassle free ICICI Bank overseas education loans
                process.
              </li>
              <li>Preferential forex rates for international disbursements.</li>
            </ol>
          </p>
        ),
      },
    ],
  },

  {
    title: "Compare Features of the ICICI Education Loans",
    id: "compare-features",
    subMenu: [
      {
        id: "loan-documentation-intro",
        title: "Intro",
        content: (
          <p>
            ICICI Bank charges the same base interest rate of 11.5 % to students
            whether they plan for <b>ICICI BAnk education loan</b> in India or{" "}
            <b> ICICI BAnk overseas education loans</b>. The details for
            Domestic and International students are shared below:
          </p>
        ),
      },
      {
        id: "domestic-students",
        title: "Domestic Students",
        content: (
          <p>
            <ul className="expense-list">
              <table
                className="table table-bordered"
                style={{
                  margin: "10px 0px",
                }}>
                <thead>
                  <tr>
                    <th scope="col">Details</th>
                    <th scope="col">Post Graduate</th>
                    <th scope="col">Under Graduate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <b>Interest Rate</b>
                    </th>
                    <td>Starts at 10.9 % - 11.5%</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Loan Tenure Without Collateral</b>
                    </th>
                    <td>
                      Up to eight years after the completion of the course with
                      additional six months
                    </td>
                    <td>
                      Up to five years post course period with additional six
                      months
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Loan Tenure with Collateral</b>
                    </th>
                    <td>
                      Up to ten years after the course period with additional
                      six months
                    </td>
                    <td>
                      Up to seven years post course period with additional six
                      months
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Maximum Loan Amount</b>
                    </th>
                    <td>Rs. 50 lakhs</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Collateral Requirement</b>
                    </th>
                    <td>
                      According to the institute. Provides collateral-free loans
                      for selected institutes: up to ₹ 40 lakh for PG courses.
                    </td>
                    <td>Up to ₹ 20 lakh for UG </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Margin</b>
                    </th>
                    <td colspan="2">
                      15 percent margin (Margin/ FD/ Scholarship) only for loans
                      above ₹ 20 Lakh.
                    </td>
                  </tr>
                </tbody>
              </table>
            </ul>
          </p>
        ),
      },
      {
        id: "international-students",
        title: "International Students",
        content: (
          <p>
            <ul className="expense-list">
              <table
                className="table table-bordered"
                style={{
                  margin: "10px 0px",
                }}>
                <thead>
                  <tr>
                    <th scope="col">Details</th>
                    <th scope="col">Post Graduate</th>
                    <th scope="col">Under Graduate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <b>Interest Rate</b>
                    </th>
                    <td>Starts at 10.9 - 11.5 %</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Loan Tenure Without Collateral</b>
                    </th>
                    <td>
                      Up to eight years after the course period with additional
                      six months
                    </td>
                    <td>
                      Up to five years post course period with additional 6
                      months
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Loan Tenure with Collateral</b>
                    </th>
                    <td>
                      Up to 10 years post course period with additional 6 months
                    </td>
                    <td>
                      Up to seven years after the course period with additional
                      6 months
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Maximum Loan Amount</b>
                    </th>
                    <td colspan="2">
                      For Unsecured its INR 40 Lakhs, for secured its 1 Cr
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Collateral Requirement</b>
                    </th>
                    <td colspan="2">
                      As per the college or institute. Collateral-free loans for
                      select institutes: Up to ₹ 20 lakh for UG and up to ₹ 40
                      lakh for PG courses. They have a rating system for
                      Universities and the interest rate and loan amount depends
                      on the rating system
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Moratorium Period</b>
                    </th>
                    <td colspan="2">As per the course and institute</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Margin</b>
                    </th>
                    <td colspan="2">
                      15% margin (Margin/ FD/ Scholarship) only for loans above
                      ₹ 20 Lakh
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <b>
                ICICI Bank also gives funds for some of the non tuition related
                expenses like :{" "}
              </b>
              <ul>
                <li>Examination, laboratory, and library fees </li>
                <li>Hostel fees and travel expenses </li>
                <li>Passage money for overseas studies </li>
                <li>Insurance premium for student borrowers </li>
                <li>
                  Caution or refundable deposits supported by institution bills
                  and receipts along with building funds{" "}
                </li>
                <li>Books, uniform, equipment, and instruments </li>
                <li>
                  Reasonably priced laptop or computer if vital to complete the
                  course{" "}
                </li>
                <li>
                  Any other expenses required to complete the course like study
                  tour, thesis, and project work
                </li>
              </ul>
            </ul>
          </p>
        ),
      },
    ],
  },
  {
    title: "Loan Documentation",
    id: "loan-documentation",
    subMenu: [
      {
        id: "doc-required",
        title: "Documents Required",
        content: (
          <p>
            The following documents are required by the bank for students to be
            considered for an <b>ICICI Bank education loan</b> : <br />
            <ol className="expense-list">
              <li>
                Know your Customer (KYC) documents of both the co-borrower and
                student
              </li>
              <li>
                Mark sheets of 10th, 12th, undergraduate courses, and entrance
                exams
              </li>
              <li>Admission Letter</li>
              <li>Fee structure</li>
              <li>Income Proof of the co-borrower</li>
              <li>
                Residential and commercial property plots which should be
                non-agricultural
              </li>
              <li>Fixed deposits</li>
              <li>Income proof required only for specific courses</li>
            </ol>
            <b>
              The following documents are sought against the varied categories:
            </b>
            <table
              className="table table-bordered"
              style={{
                margin: "10px 0px",
              }}>
              <tbody>
                <tr>
                  <th scope="col">
                    <b>Identity Proof</b>
                  </th>
                  <th scope="col">
                    Passport, Aadhaar Card, PAN Card, Driving Licence, Voter’s
                    ID Card, Photo Credit Card, Central and State Govt.issued
                    Photo ID Proof, and Central and ID Card
                  </th>
                </tr>
                <tr>
                  <th scope="col">
                    <b>Residence Proof</b>
                  </th>
                  <th scope="col">
                    Aadhaar Card, Passport, Ration Card, Voter’s ID Card, Bank
                    Account Statement, Letter from any recognized public
                    authority, Electricity Bill, Water Bill, and Telephone Bill.
                  </th>
                </tr>
                <tr>
                  <th scope="col">
                    <b>Income Proof</b>
                  </th>
                  <th scope="col">
                    Salary Slips (not more than 3 months old), Bank Statements
                    (not more than 3 months old), and ITR, Form-16
                  </th>
                </tr>
              </tbody>
            </table>
          </p>
        ),
      },
    ],
  },
  {
    title: "Loan Features",
    id: "loan-features",
    subMenu: [
      {
        id: "loan-features-loan-amount",
        title: "Loan Amount",
        content: (
          <p>
            The ICICI Bank provides ICICI Bank overseas education loans upto one
            crore and with deviation up to two crores as per A1 to A4 list. For
            unsecured ICICI Bank education loan, the students get the assistance
            of up to 40 lakhs as per A1 list, 20 lakhs as per A2 list, and 7.5
            lakhs as per A3 and A4 list.
          </p>
        ),
      },
      {
        id: "loan-features-interest-rate",
        title: "Percentage of Loan Against Collateral",
        content: (
          <p>
            The bank provides a loan amount upto 100 percent of Property’s
            Realizable Value and FD value.
          </p>
        ),
      },
      {
        id: "loan-features-collateral-property",
        title: "Collateral Property",
        content: (
          <p>
            If the students are keeping their property as security, they will
            have to submit all the required registered documents of the property
            along with occupational certificate and completion certification
            registered by the Municipal corporation of India. Also the property
            should be a non-agriculture land and should be inside the premises
            of the city.
          </p>
        ),
      },
      {
        id: "loan-features-student-profile-requirements",
        title: "Student’s Profile",
        content: (
          <p>
            To provide ICICI Bank overseas education loan to any applicant, the
            bank will not check the academic background of the student (no. of
            KTs throughout his last education) but will consider the details of
            the course and institution he is applying for.
          </p>
        ),
      },

      {
        id: "loan-features-interest-paying-during-moratorium-period",
        title: "Interest Paying During Moratorium Period",
        content: (
          <p>
            The bank also provides an option of paying the interest on ICICI
            BAnk overseas education loan during moratorium period or along with
            EMIs.
          </p>
        ),
      },
      {
        title: "Processing Fees",
        id: "loan-features-processing-fees",
        content: <p>The bank takes 1% processing fees for the loan amount</p>,
      },
    ],
  },
  {
    title: "Loan Eligibility",
    id: "loan-eligibility",
    subMenu: [
      {
        id: "eligibility-parameters",
        title: "Eligibility Criteria",
        content: (
          <p>
            <ul className="expense-list">
              <li>Applicants should be an Indian National.</li>
              <li>
                Applicants should have secured admission or an invite from a
                recognized university or college.
              </li>
            </ul>
          </p>
        ),
      },
    ],
  },
  {
    title: "Eligible Courses",
    id: "eligible-courses",
    subMenu: [
      {
        id: "domestic-courses",
        title: "Domestic Courses",
        content: (
          <p>
            Domestic courses that are approved for{" "}
            <b>ICICI Bank education loan</b> are the courses that comes under
            Graduate, Post Graduate degree/diploma and are conducted by any
            college or university recognized by the University Grants
            Commission, Government, All India Council for Technical Education,
            All India Board of Management Studies, and Indian Council of Medical
            Research.
          </p>
        ),
      },
      {
        id: "international-courses",
        title: "International Courses",
        content: (
          <p>
            ICICI Bank does provide <b>ICICI Bank overseas education loans</b>{" "}
            for students headed abroad with a criterion that the course that
            should be job oriented
          </p>
        ),
      },
    ],
  },
  {
    title: "Loan EMI Calculator",
    id: "loan-emi-calculator",
    subMenu: [
      {
        id: "loan-emi-calculator-intro",
        title: "Overview",
        content: (
          <p>
            Students who have applied for ICICI Bank education loan can also
            check on the amount of Equated Monthly Installments (EMI) that is
            required to pay easily through Eduloan’s simple and effective tool.
            <br /> <br />
            <b>
              {" "}
              Follow the steps laid out below to use Eduloan’s student loan EMI
              calculator.
            </b>
            <br />
            <ol className="expense-list">
              <li>Go to Eduloan’s student loan EMI calculator.</li>
              <li>In loan amount, enter the amount borrowed from the bank</li>
              <li>
                In Rate of Interest, enter the interest that the bank is
                charging
              </li>
              <li>
                In loan tenure, enter the time period under which the loan has
                to be paid off
              </li>
            </ol>
            The screen will showcase the EMI that are liable to pay with the
            given combination of loan amount, tenure, and rate of interest. The
            EMI can also vary with the change the contributing factors of the
            education loan EMI calculator.
          </p>
        ),
      },
    ],
  },
  {
    title: "Loan FAQs:",
    id: "bob-loan-faqs",
    subMenu: [
      {
        id: "bob-loan-faqs-intro",
        title: "Most Common FAQs",
        content: (
          <p>
            <div>
              <b>
                {" "}
                1. Can the student apply for subsidy on ICICI Bank education
                loan?
              </b>{" "}
              <br />
              Yes, the applicant can apply for a subsidy on{" "}
              <b>ICICI Bank education loan</b> if the co-borrower has an income
              of less than ₹ 4.5 lakh per annum from all sources.
            </div>{" "}
            <br />
            <div>
              <b>
                {" "}
                2. Does ICICI Bank charge processing fees for the ICICI Bank
                education loan?
              </b>{" "}
              <br />
              ICICI Bank may charge some processing fees depending on the
              college and course applied for.
            </div>{" "}
            <br />
            <div>
              <b>
                {" "}
                3. Can the applicant get a loan exemption on the ICICI Bank
                education loan?
              </b>{" "}
              <br />
              Yes the applicant can get exemption under section 80 E of Income
              Tax Act of India, 1961 where they can claim the interest paid on
              the education loan as a deduction..
            </div>{" "}
            <br />
            <div>
              <b> 4. Does the EMI’s start right after college?</b> <br />
              No ICICI Bank gives six additional months to secure a job before
              you have start paying the EMIs
            </div>
            <br />
            <div>
              <b>
                {" "}
                5. How much of the education cost will be funded by ICICI Bank
                Education Loan?
              </b>{" "}
              <br />
              ICICI Bank covers 100% of the ICICI Bank education loan up to ₹ 20
              lakh. After that there will be a margin of 15%.
            </div>
            <br />
            <div>
              <b> 6. Does ICICI Bank student loan only cover tuition fee?</b>{" "}
              <br />
              ICICI Bank education loans cover hostel fee, lab fee, and even a
              study tour or laptop if it is required to finish a course.
            </div>
            <br />
            <div>
              <b> 7. Does ICICI Bank student loan cover travel expenses?</b>{" "}
              <br />
              Yes.
            </div>
            <br />
            <div>
              <b> 8. Does ICICI Bank allow prepayment or part payment?</b>{" "}
              <br />
              ICICI Bank has not placed any restrictions on pre-payment or part
              payment of ICICI Bank overseas education loan.
            </div>
            <br />
            <div>
              <b>
                {" "}
                9. How soon will the loan be disbursed for ICICI Bank overseas
                education loan?
              </b>{" "}
              <br />
              The applicants can expect a pre-visa disbursal of ICICI Bank
              overseas education loan amount.
            </div>
          </p>
        ),
      },
    ],
  },
];

const LeftSidebar = () => {
  return (
    <div className="left-sidebar" style={{ padding: "24px" }}>
      <div className="index-menu-bar">
        {menuData.map((item, index) => (
          <ul key={index} className="menu">
            <div className="menu-title">
              <a href={`#${item.id}`}>{item.title}</a>
            </div>
            <div className="submenu">
              {item.subMenu.map((subMenuItem, subIndex) => (
                <li key={subIndex}>
                  <a href={`#${subMenuItem.id}`}>
                    <AiOutlineRight /> {subMenuItem.title}
                  </a>
                </li>
              ))}
            </div>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default LeftSidebar;
