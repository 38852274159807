import React from "react";

const Accomodation = () => {
  return (
    <div className="card" id="accomodation">
      <h1 className="title">
        Benefits of Finalising {""}
        <span
          className=""
          style={{
            color: "#13589D",
            textDecoration: "underline",
          }}>
          Accommodation
        </span>{" "}
        through Globaldreamz
      </h1>
      <ul className="list-style-1 mb--20">
        <li>
          <i className="icon-checkbox-circle-fill-solid"></i>Accommodation in
          700+ Cities{" "}
        </li>
        <li>
          <i className="icon-checkbox-circle-fill-solid"></i>100% Verified
          Listing{" "}
        </li>
        <li>
          <i className="icon-checkbox-circle-fill-solid"></i>Price Match
          Guarantee{" "}
        </li>
        <li>
          <i className="icon-checkbox-circle-fill-solid"></i>Personal
          Relationship Manager{" "}
        </li>
        <li>
          <i className="icon-checkbox-circle-fill-solid"></i>Quick & Easy
          Bookings{" "}
        </li>
      </ul>
    </div>
  );
};

export default Accomodation;
