import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { slugify } from "../../utils";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";

import PostData from "../../data/blog/PostData.json";
import axios from 'axios';

const BlogDetailsOne = () => {
  const { id } = useParams();
  const blogId = parseInt(id, 10);

  const postURL = "https://public-api.wordpress.com/rest/v1.1/sites/globaldreamzedu.wordpress.com/posts/" + id;
  const [data, setData] = useState({ isLoading: false, postData: {} })
  //removed placed holderdata, comment this line to get placeholder blogs
  PostData = [];

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }


  useEffect(() => {
    // console.log("called");
    axios.get(`${postURL}`, {}).then((response) => {
      if (response) {
        let blogPost = response.data;
        // console.log(blogPost);
        let title_img;
        for (let key in blogPost.attachments) {
          title_img = blogPost.attachments[key].URL;
        }
        PostData = {
          "id": blogPost.ID,
          "title": blogPost.title,
          "image": title_img,
          "date": formatDate(new Date(blogPost.modified)),
          "shortDate": "06 Nov",
          "author": ["John Kite"],
          "readingTime": "3 Min Read",
          "view": "232 View",
          "comment": "02 Comments",
          "categories": ["Business", "Education"],
          "tags": ["Enrol", "Course", "Education Abroad"],
          "details": blogPost.excerpt,
          "content": blogPost.content
        };

        setData({ isLoading: true, postData: PostData });
      } else {
        //not fetched
      }
    });
  },[]);

  return (
    <>
      <SEO title={data.postData.title} />
      <Layout>
        <BreadcrumbOne
          title={data.postData.title}
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Blog Details"
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-10 offset-lg-1">
                <div className="blog-details-1 style-variation3">
                  <div className="content-blog-top">
                    <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                      <div className="status-group">
                      </div>
                      <ul className="blog-meta">
                        <li>
                          <i className="icon-calendar-2-line"></i>
                          {data.postData.date}
                        </li>
                        <li>
                          <i className="icon-discuss-line"></i>
                          {data.postData.comment}
                        </li>
                        <li>
                          <i className="icon-time-line"></i>
                          {data.postData.readingTime}
                        </li>
                      </ul>
                    </div>

                    <h4 className="title">{data.postData.title}</h4>

                    <div className="thumbnail block-alignwide">
                      <img
                        className="radius-small w-100 mb--30"
                        src={`${data.postData.image}`}
                        alt="Blog Thumb"
                      />
                    </div>
                  </div>

                  <div className="blog-main-content" dangerouslySetInnerHTML={{__html: data.postData.content}}>
                  </div>

                  <div className="blog-tag-and-share mt--50">
                    {data.postData.tags && data.postData.tags.length > 0 && (
                      <div className="blog-tag">
                        <div className="tag-list bg-shade">
                          {data.postData.tags.map((tag, i) => {
                            return (
                              <Link key={i} to={`/tag/${slugify(tag)}`}>
                                {tag}
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <div className="mediashark-post-share">
                      <span>Share: </span>
                      <a className="linkedin" href="#">
                        <i className="icon-linkedin"></i>
                      </a>
                      <a className="facebook" href="#">
                        <i className="icon-Fb"></i>
                      </a>
                      <a className="twitter" href="#">
                        <i className="icon-Twitter"></i>
                      </a>
                      <a className="youtube" href="#">
                        <i className="icon-youtube"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsOne;
