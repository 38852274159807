import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import HeaderTwo from "../../common/header/HeaderTwo";


const AboutUsThree = () => {
  return (
    <>
      <SEO title="About Us - GlobalDreamz" description="GlobalDreamz specialises in exam preparation, online and offline counselling, admissions, and career counselling. Our primary goal is to ensure the achievement of our students." keywords="GlobalDreamz, Exam preparation, career counselling" canonical="https://globaldreamzedu.com/about" />
      <HeaderTwo styles="header-transparent header-style-2" />
      <Layout>

      <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="thumbnail">

            <div className="text-center offset-3 col-lg-7 col-12">
                
            </div>

                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="edu-feature-area bg-color-white mt--100">
        <div className="container mediashark-animated-shape my-5">
          <div className="row my-5">
            <div className="text-center offset-3 col-lg-7 col-12 my-5">
                <h3>Thank you, we will get back soon! </h3>
            </div>
          </div>
        </div>
      </div>

      </Layout>
    </>
  );
};

export default AboutUsThree;
