import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import EventData from "../../data/event/EventData.json";
import InstructorData from "../../data/instructor/InstructorData.json";
import CourseData from "../../data/course/CourseData.json";
import CategoryMba from "../../components/category/CategoryMba";
import ScrollAnimation from "react-animate-on-scroll";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import AboutSix from "../../components/about/AboutSix";
import { slugify } from "../../utils";
import CurriculumTabContent from "../../data/course/CurriculumTabContent.json";

const accordionItems = [
  {
    id: 1,
    title: "What are the benefits of preparing for the IELTS?",
    body: "IELTS helps students in checking and gaining the required English Language skills. At GlobalDreamz, we help you to prepare you comprehensively for the IELTS examination.",
  },
  {
    id: 2,
    title: "Which country/countries require the IELTS score?",
    body: "The US, UK, Australia, New Zealand, and Canada require an IELTS score. Some countries in Asia and Europe use IELTS scores. ",
  },
  {
    id: 3,
    title: "What are the prerequisites for pursuing the IELTS?",
    body: "You need to have cleared the pre-university/high school exam/Class 12 exams (Intermediate) for being eligible for the IELTS exam. However, the IDP has no criteria for the exam. You just need to be 16 years of age. The academic eligibility mentioned above is only for students who wish to immigrate for studying abroad. ",
  },
  {
    id: 4,
    title: "Is the IELTS compulsory?",
    body: "Yes, it is compulsory if you wish to go abroad and be legibly employed or admitted to a college or a university. However, some institutions might not require an IELTS score. ",
  },
];

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button">
      {children}
    </button>
  );
};

const CurriculumContent = () => {
  const [activeId, setActiveId] = useState("0");

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
      {CurriculumTabContent.map((accordion, i) => (
        <Accordion.Item
          eventKey={i.toString()}
          key={i}
          onClick={() => toggleActive(i.toString())}
          bsPrefix={`edu-accordion-item ${
            activeId === i.toString() ? "bg-active" : ""
          }`}>
          <div className="edu-accordion-header">
            <CustomToggle eventKey={i.toString()}>
              {accordion.title}
            </CustomToggle>
          </div>
          <Accordion.Body bsPrefix="edu-accordion-body">
            <ul>
              {accordion.content.map((title, index) => (
                <li key={index}>
                  <div className="text">
                    <i className="icon-draft-line"></i>
                    {title}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const IeltsDreamz = () => {
  const { id } = useParams();
  const eventId = parseInt(1, 10);
  const data = EventData.filter((event) => event.id === eventId);
  const eventItem = data[0];

  const [activeId, setActiveId] = useState("0");
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const courseId = parseInt(1, 10);
  const dataCourse = CourseData.filter((course) => course.id === courseId);
  const courseItem = dataCourse[0];

  const indexOfInstructor = InstructorData.findIndex(function (instructor) {
    return slugify(instructor.name) === slugify(courseItem.instructor);
  });
  const instructor = InstructorData[indexOfInstructor];
  const instructorExcerpt = instructor.details.substring(0, 190) + "...";

  const [contentTab, setContentTab] = useState("overview");
  const handleTab = (content) => {
    if (content === "overview") {
      setContentTab("overview");
    } else if (content === "curriculum") {
      setContentTab("curriculum");
    } else if (content === "instructor") {
      setContentTab("instructor");
    } else if (content === "reviews") {
      setContentTab("reviews");
    }
  };

  return (
    <>
      {/* <SEO title={eventItem.title} /> */}
      <SEO
        title="IELTS Exam Preparation | IELTS coaching in pune"
        description="Get the best IELTS coaching in Pune. Access comprehensive IELTS exam preparation resources. Achieve your IELTS success!"
        keywords="ielts coaching in pune, ielts preparation online, ielts test preparation, ielts training online"
        canonical="https://globaldreamzedu.com/ielts"
      />
      <Layout>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_7.jpg`}
                    alt="ielts exam prep students"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="content">
                  <h1 className="title">Why should you prepare for IELTS?</h1>
                  <p>
                    The International English Language Testing System (IELTS)
                    helps to get admission in six fields currently. The language
                    test has been designed to help you to study, work, or to
                    migrate to a native English-speaking country like the UK,
                    USA, Australia, New Zealand, and Canada. The IELTS will help
                    you to gauge and improve your English language communication
                    which is at par with native English speakers. That is why
                    IELTS is one of the precursors to your visa registration
                    process. Earlier, immigration personnel, college and
                    university authorities, and employees faced a lot of
                    problems because many people reached the country without the
                    knowledge of English which created a communication gap. But
                    thanks to IELTS, this problem was eliminated.
                  </p>
                  <p>
                    IELTS is conducted by a body called IDP Education Ltd. The
                    IELTS Test is accepted by multiple universities and colleges
                    worldwide. It is also necessary for your visa to get issued.
                    This standardized language exam is computer-based and has
                    both multiple-choice questions and writing tests. The IELTS
                    test is further divided into four sections –Listening,
                    Reading, Writing, and Speaking. The scores of the IELTS
                    subject are used by all the authorities that have been
                    mentioned above.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <p>
                  Contact our IELTS counsellors at GlobalDreamz to fulfilling
                  your IELTS aspirations!
                </p>
                <h4>What we offer-</h4>
                <ul className="list-style-1 mb--20">
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Customised classes as per the student’s language skills
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Best IELTS coaching faculty
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    One-on-one learning environment
                  </li>
                </ul>
                <p>
                  IELTS preparation focuses on preparing for all the sections of
                  the IELTS – Listening, Reading, Writing, and Speaking. The
                  pedagogy is adjusted according to the language skills and
                  prowess of the student. In the first level, we help the
                  student to gain the basic language skills which strengthen
                  their foundation. At the next level, we take them to the
                  advanced level where they can comprehend and use the language,
                  verbally and in written form. After that, we polish off the
                  rough edges, i.e. help them to manipulate the language enough
                  to crack the exam. We also give them the required mock tests
                  developing
                </p>

                <CategoryMba />
              </div>

              <div className="col-12">
                <div className="edu-accordion-area mediashark-home-three-accordion accordion-shape-1 edu-section-gap bg-color-white">
                  <div className="container mediashark-animated-shape">
                    <div className="row g-5">
                      <div className="col-lg-6 gallery-wrapper">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="thumbnail">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-01.jpg`}
                                alt="About Thumb 1"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 mt--35 mt_sm--15">
                            <div className="thumbnail">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-02.jpg`}
                                alt="students discussion"
                              />
                            </div>
                            <div className="thumbnail mt--15">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-03.jpg`}
                                alt="students discussion"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <SectionTitle
                          classes="text-start mb--40"
                          slogan="FAQs"
                          title="Get Every General Answers From Here"
                        />
                        <ScrollAnimation
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          className="accordion-style-1"
                          animateOnce={true}>
                          <Accordion
                            bsPrefix="edu-accordion"
                            defaultActiveKey="0"
                            flush>
                            {accordionItems.map((accordion, i) => (
                              <Accordion.Item
                                eventKey={i.toString()}
                                key={i}
                                onClick={() => toggleActive(i.toString())}
                                bsPrefix={`edu-accordion-item ${
                                  activeId === i.toString() ? "active-item" : ""
                                }`}>
                                <div className="edu-accordion-header">
                                  <CustomToggle eventKey={i.toString()}>
                                    {accordion.title}
                                  </CustomToggle>
                                </div>
                                <Accordion.Body bsPrefix="edu-accordion-body">
                                  {accordion.body}
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </ScrollAnimation>
                      </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                      <div className="shape shape-1">
                        <span className="shape-dot"></span>
                      </div>
                      <div className="shape-image shape-image-2">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/shapes/shape-11-04.png`}
                          alt="Shape Thumb"
                        />
                      </div>
                      <div className="shape-image shape-image-3">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/shapes/shape-25-01.png`}
                          alt="Shape Thumb"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="course-details-content">
                  <h3 className="title">Our Services</h3>
                  <ul className="edu-course-tab nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "overview"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Overview"
                        onClick={() => handleTab("overview")}>
                        Course Description
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "curriculum"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Curriculum"
                        onClick={() => handleTab("curriculum")}>
                        Curriculum
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "instructor"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Instructor"
                        onClick={() => handleTab("instructor")}>
                        Instructor
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "reviews"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Reviews"
                        onClick={() => handleTab("reviews")}>
                        Reviews
                      </button>
                    </li>
                  </ul>

                  <div className="tab-content">
                    {contentTab === "overview" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "overview" ? "active" : ""
                        } `}>
                        <div className="course-tab-content">
                          <h6>Overview</h6>
                          <p>
                            The IELTS is a standardized English Language test
                            for studying, working, or staying in
                            English-speaking countries. The Online IELTS
                            Coaching exam has been created to help people master
                            a certain level of English. You get 2 hours and 44
                            minutes to complete the test.
                          </p>

                          <h6>What will you learn from this course?</h6>
                          <p>
                            For getting a good score in the IELTS, you require a
                            proper plan for the preparation. At GlobalDreamz, we
                            coach you in all four sections of the IELTS and then
                            we help you ace the exams with the help of mock
                            tests in an exam-simulated environment.
                          </p>

                          <h6>Curriculum</h6>
                          <p>
                            The primary goal of the IELTS is to prepare students
                            for a management program. The students will be
                            provided extensive training in all the four sections
                            of the IELTS exam on both spoken and written skills.
                          </p>
                        </div>
                        <br></br>
                      </div>
                    )}

                    {contentTab === "curriculum" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "curriculum" ? "active" : ""
                        } `}>
                        <div className="course-tab-content">
                          <p>
                            The primary goal of an MBA is to educate students in
                            Accounting, Marketing, Human Resources, and
                            Operations Management.
                          </p>
                          <p>
                            <b>An MBA will cover the following topics:</b>
                          </p>
                          <ul>
                            <li>Introduction to Marketing </li>
                            <li>Micro Economics </li>
                            <li>Accounting</li>
                            <li>Human resource management </li>
                            <li>Business Law</li>
                            <li>Introduction to Finance</li>
                            <li>Operations and Information management </li>
                            <li>Organizational Behaviour </li>
                          </ul>
                        </div>
                      </div>
                    )}

                    {contentTab === "instructor" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "instructor" ? "active" : ""
                        } `}></div>
                    )}

                    {contentTab === "reviews" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "reviews" ? "active" : ""
                        } `}></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AboutSix />
      </Layout>
    </>
  );
};
export default IeltsDreamz;
