import React from "react";
import { AiOutlineRight } from "react-icons/ai";

export const menuData = [
  {
    title: "Features",
    id: "Features",
    subMenu: [
      {
        id: "pan-india-reach",
        title: "Pan India Reach ",
        content: (
          <p>
            Amongst other NBFC’s Auxilo has one of the Finest Network and Decent
            # of Servicing Offices throughout all major and minor cities in
            mostly all States of India. The loan processes through Dedicated
            Relationship Managers (RM’s) who are linked with the Loan Centre’s
            (Area Wise) and have The Credit Team / Underwriters, which are the
            sanctioning authority.
          </p>
        ),
      },
      {
        id: "attractive-interest-rate",
        title: "Interest Rate",
        content: (
          <p>
            They provide a very reasonable interest rate amongst all the NBFC’s
            for both Collateral and Non Collateral Loans.
          </p>
        ),
      },
      {
        id: "flexible-loan-amount",
        title: "High Loan Amount",
        content: (
          <p>
            Auxilo is one of the Financial Institutions that can give the
            maximum loan amount (Upto Cost of Education) and up to Rs.35 Lakhs
            (Unsecured).
          </p>
        ),
      },
      {
        id: "security-of-loan-secured-loan",
        title: "Consideration On Basis Of (Secured Loan) ",
        content: (
          <p>
            They accept Residential property, Commercial property,
            non-agricultural land and Fixed Deposits.
          </p>
        ),
      },
      {
        id: "security-of-loan-unsecured-loan",
        title: "Consideration On Basis Of (Unsecured Loan)",
        content: (
          <p>
            The students profile, his competitive exam scores, University and
            Course applied for, Co-Applicants (Blood Relations / Maternity /
            Paternity 1st Relatives) IT Returns and Cibil Report.
          </p>
        ),
      },
      {
        id: "margin-money",
        title: "Margin Money",
        content: (
          <p>
            This is the amount that a student is supposed to pay from his side
            on every disbursement. Auxilo gives 100 % funding, Zero Margin
            Money.
          </p>
        ),
      },

      {
        id: "loan-to-value",
        title: "Loan To Value",
        content: (
          <p>
            For Secured Loans Auxilo provides approximately 100 % of the value
            of the Mortgaged Collateral. They do the latest property evaluation.
            Property Valuation or Cost of Education whichever is lower is
            funded.
          </p>
        ),
      },
      {
        id: "repayment",
        title: "Repayment-period",
        content: (
          <p>
            They have a long term for the repayment of the Loan which is up to
            10 years (120 months) and EMI starts after the Moratorium Period .
          </p>
        ),
      },
      {
        id: "moratorium-period",
        title: "Moratorium Period",
        content: (
          <p>
            Moratorium on repayment during study period available{" "}
            <b>+ 6 Months to 1 Year</b> after completion of course.
          </p>
        ),
      },
      {
        id: "loan-preceeds",
        title: "Loan Proceeds ",
        content: (
          <p>
            <b>Door Step Home Service Available. </b>
            Initially the documents are submitted by students to Dedicated
            Relationship Managers (RM’s) who are linked with the Loan Centre’s
            (Area Wise) and have The Credit Team / Underwriters, who get the
            Valuation and Legal Formalities done, post which they decide on the
            Sanctioning of the Loan. Once the loan is Sanctioned, Disbursements
            happen from these Loan Centre’s.
          </p>
        ),
      },
      {
        id: "credit-grading",
        title: "Credit Grading",
        content: (
          <p>
            For Secured Loan they look at Students Profile, Parents IT Returns,
            Cibil Report and Collateral Security. Incred is heavily dependent on
            the security provided for the loan followed by the student profile.
            Weak IT returns of parents are sometimes considered in case the
            former are strong. Additional Co-applicants / Co-signors can even be
            a 3rd party instead of a relative, if their Collateral Mortgage has
            to be considered. There can be other additional conditions depending
            on the particular case.
          </p>
        ),
      },
      {
        id: "time",
        title: "Time for processing",
        content: (
          <p>
            Auxilo is a large organization and Education Loans are done through
            their Loan Centre’s which are faster compared to other Financial
            Institutions. The average turnaround time is at least 15-20 days
            (for both Secured and Unsecured Loans).
          </p>
        ),
      },
      {
        id: "low-fees",
        title: "Low processing fees",
        content: (
          <p>
            1 % of the Loan Amount + GST which can be considerable on Case to
            Case basis.
          </p>
        ),
      },
      {
        id: "universities-funded",
        title: "Universities Funded",
        content: (
          <p>
            Auxilo funds majority of the universities and also majority of the
            courses (Mostly Masters - MS) under Secured and Unsecured Loans.
          </p>
        ),
      },

      {
        id: "sanction-letter",
        title: "Pre Sanction Letter",
        content: (
          <p>
            They can give a Sanction Letter prior to receiving I20 for US.
            Validity of Sanction Letter is up to 6 months (Can be extended).
            Funds can be used by students to show for their I20 and Visa
            purpose.
          </p>
        ),
      },
      {
        id: "flexible-repayment-options",
        title: "Flexible Repayment Options",
        content: (
          <p>
            Partial payment of Interest option available during Moratorium
            Period (Case to Case Basis and Country Specific).
          </p>
        ),
      },
    ],
  },
];

const LeftSidebar = () => {
  return (
    <div className="left-sidebar" style={{ padding: "24px" }}>
      <div className="index-menu-bar">
        {menuData.map((item, index) => (
          <ul key={index} className="menu">
            <div className="menu-title">
              <a href={`#${item.id}`}>{item.title}</a>
            </div>
            <div className="submenu">
              {item.subMenu.map((subMenuItem, subIndex) => (
                <li key={subIndex}>
                  <a href={`#${subMenuItem.id}`}>
                    <AiOutlineRight /> {subMenuItem.title}
                  </a>
                </li>
              ))}
            </div>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default LeftSidebar;
