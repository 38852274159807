import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const items = [
  {
    title: "Resume",
    info: "The counselling process at GlobalDreamz begins with understanding the individual through his academic, non-academic, and professional accomplishments. This is achieved by collaborating with the individual to create a concise and lucid resume. The resume component of the application process is extremely critical because it provides the admission committee with a summary of the applicant.",
    numberOfCourses: "23 Courses",
    icon: "icon-student-read",
    link: "#",
  },
  {
    title: "Identification of Goals",
    info: "Sometimes, potential candidates simply choose to pursue an MBA without thinking about the end goals or objectives. GlobalDreamz focuses on assisting applicants in navigating their goals and identifying their short-term and long-term objectives. Furthermore, GlobalDreamz collaborates with the student to develop a plan to help the students attain this goal through essays, resumes, and interviews.",
    numberOfCourses: "39 Courses",
    icon: "icon-lab",
    link: "#",
  },
  {
    title: "School Selection",
    info: "GlobalDreamz will recommend colleges and universities that are best suited to the applicant's career goals after identifying the applicant's goals and aspirations. GlobalDreamz critically evaluates the opportunities available for gaining employment and residency overseas, potential scholarship opportunities, and the potential ROI when making recommendations.",
    numberOfCourses: "30 Courses",
    icon: "icon-science",
    link: "#",
  },
  {
    title: "Recommendations & Essays",
    info: "One of the most crucial aspects of the application procedure is the essays and recommendations. GlobalDreamz takes a simple three-step approach to assist aspirants in composing their essays, starting with ideation, then editing, and finally a critical quality review by a professional. It has worked brilliantly for many aspirants, with many gaining admission to the world's top MBA programmes.",
    numberOfCourses: "45 Courses",
    icon: "icon-compass",
    link: "#",
  },
  {
    title: "Preparation for an Interview",
    info: "It's disheartening to have worked hard to compose essays and then fall short in the actual interview. We conduct mock interviews until the applicants ace their responses. GlobalDreamz makes certain that each applicant is fully prepared to appear in front of the interview panel.",
    numberOfCourses: "15 Courses",
    icon: "icon-calculator",
    link: "#",
  },
  {
    title: "Financial Preparation",
    info: "GlobalDreamz works with students' families to alleviate financial stress. We create personalised budgeting plans for applicants. We have agreements with: (a) foreign exchange vendors providing remittances, cash at lucrative rates, and forex travel cards; and (b) leading lenders who provide financial assistance for the total cost of tuition and living costs. ",
    numberOfCourses: "25 Courses",
    icon: "icon-microscopes",
    link: "#",
  },
  {
    title: "VISA Consultation",
    info: "GlobalDreamz makes your goal of studying overseas achievable by guiding you through the final hurdle with grace and ease. We hand-hold students in obtaining a study visa successfully. By conducting continuous mock interviews and completing all paperwork, we groom the applicants through personality development sessions to help ace the interview.",
    numberOfCourses: "18 Courses",
    icon: "icon-calculator",
    link: "#",
  },
  {
    title: "Following the landing",
    info: "Once you arrive, we will assist you in finding accommodations, preparing your cover letter and resume, connecting with our alumni’s placed in various organisations, and conducting interview sessions for jobs and/or internship opportunities. Our objective is to make sure that our candidates accomplish the goal for which they set out.",
    numberOfCourses: "15 Courses",
    icon: "icon-pen-tool",
    link: "#",
  },
];

const CategoryMba = ({ wrapperClass, styleClass }) => {
  return (
    <div className={`row ${wrapperClass || "g-5 mt--25"}`}>
      {items.map((data, i) => (
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          className={`col-lg-6 col-md-6 col-sm-6 col-12 ${
            styleClass ? styleClass : ""
          }`}
          animateOnce={true}
          key={i}
        >
          <div className="service-card service-card-4">
            <div className="inner">
              <div className="icon">
                <i className={data.icon}></i>
                <span className="subtitle">{data.numberOfCourses}</span>
              </div>
              <div className="content">
                <h6 className="title">
                  <a href={data.link}>{data.title}</a>
                </h6>
                <p className="description">{data.info}</p>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      ))}
    </div>
  );
};

export default CategoryMba;
