import React from "react";
import { AiOutlineRight } from "react-icons/ai";

export const menuData = [
  {
    title: "Features",
    id: "Features",
    subMenu: [
      {
        id: "pan-india-reach",
        title: "Pan India Reach ",
        content: (
          <p>
            Prodigy Finance is a UK-based financial institution which provides
            funding for top-ranked US, Canada, UK , Europe and Asia graduate
            schools and has their India Team based out of Bangalore (Karnataka).
            The entire process is online and so coordination between the company
            officials and the students happens pretty smoothly via their India
            toll free contact # and also the Live Chat available through their
            website after registration.
          </p>
        ),
      },
      {
        id: "attractive-interest-rate",
        title: "Interest Rate",
        content: (
          <p>
            Their ROI (Rate Of Interest) consists of two components – a fixed
            margin between 5 % to 8.5 % + 2.80 % floating USD LIBOR rate
            (variable rate changes every 3 months).
          </p>
        ),
      },
      {
        id: "flexible-loan-amount",
        title: "High Loan Amount",
        content: (
          <p>
            Up to 100 % of cost of attendance subject to University and Course.
            Pre-approved loan for 2nd year in the initial proposal itself. Just
            required to complete some basic formalities to avail 2nd year
            approval which would be disbursed directly to the university
            account. Large loan sizes without collateral or co-signer.
          </p>
        ),
      },
      {
        id: "top-up-loans-available",
        title: "Top up Loans Available",
        content: (
          <p>
            If the student has applied for a loan elsewhere and is still falling
            short of the actual requirement as per the course expenses, then
            Prodigy Finance’s Education Loan is a boon, as the remaining amount
            can be availed through them. Existing debt will be taken into
            consideration when deciding how much additional top-up loan can be
            approved.
          </p>
        ),
      },
      {
        id: "considerations",
        title: "Consideration On Basis Of",
        content: (
          <p>
            Prodigy Finance has a list of approx. 430+ top-ranked graduate
            schools in engineering, business, law, policy and health sciences.
            If the student’s finalized school is in this list, then the student
            is eligible to get the loan.
          </p>
        ),
      },
      {
        id: "no-collateral",
        title: "No co-signer/co-borrower, no collateral required",
        content: (
          <p>
            Prodigy Finance bases its credit decision primarily on a student’s
            future earning potential and believes that students should be
            independent of others for their education funding.
          </p>
        ),
      },
      {
        id: "builds-uk-credit-history",
        title: "Builds UK Credit History",
        content: <p>They help in creating a UK credit history on GBP loans.</p>,
      },

      {
        id: "loan-to-value",
        title: "Loan To Value",
        content: (
          <p>
            Up to100 % funding of cost of attendance subject to University and
            Course and if as per eligibility the students course expenses are
            not being funded completely by them, they would ask for an
            explanation and proof of how the student plans to fund the remainder
            of the amount required.
          </p>
        ),
      },
      {
        id: "repayment",
        title: "Repayment-period",
        content: (
          <p>
            Term for the repayment of the loan is up to 20 years (240 months)
            and repayment starts 6 months after classed end.
          </p>
        ),
      },
      {
        id: "moratorium-period",
        title: "Moratorium Period",
        content: (
          <p>
            Prodigy Finance does not not require any payments to be made during
            the study period + grace period. However, borrowers are free to make
            pre-payments at any time without any penalties. Interest only
            accrues on loan balance outstanding.
          </p>
        ),
      },
      {
        id: "loan-preceeds",
        title: "Loan Proceeds ",
        content: (
          <p>
            Fast Online Paperless Process. The free online application takes 30
            minutes to complete. Since loan offers are customized, one can make
            multiple applications – one for each supported school applied for.
            The intial free quote is non-binding – one can have multiple quotes
            for multiple schools. Once the applicant decides which quote to
            accept (based on admits), documents are required to be uploaded by
            the students on the Prodigy Finance portal. The entire application
            is then reviewed by their Credit Team who then decide on the
            sanctioning of the loan. Once the loan is sanctioned, disbursements
            happen smoothly and directly from Prodigy Finance to the university
            (university certifies that the student is accepted/enrolled). No
            hidden costs such as FX conversion, Insurance or sanction letter
            fee.
          </p>
        ),
      },

      {
        id: "time",
        title: "Time for processing",
        content: (
          <p>
            Prodigy Finance’s processing of education loans is much faster
            compared to other financial institutions. A conditional approval can
            be obtained within five business days (majority of applicants
            receive an instant quote) and after submitting all documents, the
            average turnaround time for loan approval is just 10 business days.
          </p>
        ),
      },
      {
        id: "low-fees",
        title: "processing fees",
        content: (
          <p>
            One-time administration fee of 2.5% of loan approved amount is added
            to the loan outstanding during the first disbursement. Interest
            accrues on loan amount disbursed + admin fee. This is captured in
            the Annual Percentage Rate (APR) disclosed by Prodigy Finance.
            Borrowers are encouraged to compare loan offers by comparing APR
            across loans.
          </p>
        ),
      },
      {
        id: "universities-funded",
        title: "Universities Funded",
        content: (
          <p>
            They fund only top-ranked international masters programmes in
            engineering, business, law, policy and health sciences. Funding for
            Undergraduate Courses / PhD programmes is currently not available.
          </p>
        ),
      },

      {
        id: "support-letter",
        title: "Support Letter",
        content: (
          <p>
            They can give a free visa support letter which can be used by
            students to obtain their I-20 in US (which they need to get their
            student visa).
          </p>
        ),
      },
      {
        id: "sanction-letter",
        title: "Pre Sanction Letter",
        content: (
          <p>
            They issue a free sanction letter (‘loan approval letter’) once the
            university certifies the loan and the same can be used for visa
            purposes. Validity of the sanction letter{" "}
            <b>is for the specified school, programme and intake</b>. Disbursals
            coincide with the due date for tuition fees after student reaches
            university.
          </p>
        ),
      },
    ],
  },
];

const LeftSidebar = () => {
  return (
    <div className="left-sidebar" style={{ padding: "24px" }}>
      <div className="index-menu-bar">
        {menuData.map((item, index) => (
          <ul key={index} className="menu">
            <div className="menu-title">
              <a href={`#${item.id}`}>{item.title}</a>
            </div>
            <div className="submenu">
              {item.subMenu.map((subMenuItem, subIndex) => (
                <li key={subIndex}>
                  <a href={`#${subMenuItem.id}`}>
                    <AiOutlineRight /> {subMenuItem.title}
                  </a>
                </li>
              ))}
            </div>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default LeftSidebar;
