import React from "react";

const ProcessingFee = () => {
  return (
    <div className="tile" id="processing-fee">
      {/* create a dot */}
      <li> Processing Fees</li>
      <p>
        No processing fee is charged for{" "}
        <b>State Bank of India education loan</b> and{" "}
        <b>SBI overseas education loans</b>.
      </p>
    </div>
  );
};

export default ProcessingFee;
