import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import EventData from "../../data/event/EventData.json";
import InstructorData from "../../data/instructor/InstructorData.json";
import CourseData from "../../data/course/CourseData.json";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import AboutSix from "../../components/about/AboutSix";
import { slugify } from "../../utils";
import CurriculumTabContent from "../../data/course/CurriculumTabContent.json";

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button">
      {children}
    </button>
  );
};

const CurriculumContent = () => {
  const [activeId, setActiveId] = useState("0");

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
      {CurriculumTabContent.map((accordion, i) => (
        <Accordion.Item
          eventKey={i.toString()}
          key={i}
          onClick={() => toggleActive(i.toString())}
          bsPrefix={`edu-accordion-item ${
            activeId === i.toString() ? "bg-active" : ""
          }`}>
          <div className="edu-accordion-header">
            <CustomToggle eventKey={i.toString()}>
              {accordion.title}
            </CustomToggle>
          </div>
          <Accordion.Body bsPrefix="edu-accordion-body">
            <ul>
              {accordion.content.map((title, index) => (
                <li key={index}>
                  <div className="text">
                    <i className="icon-draft-line"></i>
                    {title}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const StudyInUSADreamz = () => {
  const { id } = useParams();
  const eventId = parseInt(1, 10);
  const data = EventData.filter((event) => event.id === eventId);
  const eventItem = data[0];

  const [activeId, setActiveId] = useState("0");
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const courseId = parseInt(1, 10);
  const dataCourse = CourseData.filter((course) => course.id === courseId);
  const courseItem = dataCourse[0];

  const indexOfInstructor = InstructorData.findIndex(function (instructor) {
    return slugify(instructor.name) === slugify(courseItem.instructor);
  });
  const instructor = InstructorData[indexOfInstructor];
  const instructorExcerpt = instructor.details.substring(0, 190) + "...";

  const [contentTab, setContentTab] = useState("overview");
  const handleTab = (content) => {
    if (content === "overview") {
      setContentTab("overview");
    } else if (content === "curriculum") {
      setContentTab("curriculum");
    } else if (content === "instructor") {
      setContentTab("instructor");
    } else if (content === "reviews") {
      setContentTab("reviews");
    }
  };

  return (
    <>
      <SEO
        title="Study in the USA Guide for International Students"
        description="Get incredible opportunities for higher education in the USA, including studying at renowned universities and colleges. "
        keywords="study in USA, education in USA, higher education in USA, universities in USA, colleges in USA, academic programs in USA, student visa for USA, scholarships for international students in USA, living in USA as a student, career opportunities in USA, international student experiences in USA."
        canonical="https://globaldreamzedu.com/study-in-usa"
      />
      <Layout>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--100"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_14.jpg`}
                    alt="study usa students"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h3 className="title">Why should you Study in USA?</h3>
                  <p>
                    The USA is the first country that springs to mind whenever
                    the subject of studying abroad is raised in any discussion.
                    The reason is apparent. The USA is the leader in
                    international education, with over 10,00,000 students
                    enrolled in USA universities. The abundance of alternatives
                    and programme flexibility at US colleges continue to draw
                    the majority of international students.
                  </p>
                  <p>
                    The best course to study in the USA, and the highest paying
                    employment in the USA for Indian students, must be
                    determined once you have decided to pursue your education
                    there.
                  </p>
                  <p>
                    A spectrum of job-oriented courses and degrees are available
                    through universities and colleges in all subjects. Studying
                    in the USA is an excellent option for undergraduates and
                    graduates.
                  </p>
                  <p>
                    A degree from a reputable USA university unlocks much more
                    professional opportunities. Companies seeking candidates
                    with an international perspective are aware of the need to
                    find candidates with a different perspective. It is made
                    possible by the varied insight into various fields of study
                    offered by universities in the United States, which also
                    broadens the scope of the career path that the student
                    chooses.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <h4>List of Best Courses to Study in the USA</h4>
                <ul className="list-style-1 mb--20">
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Business
                    management
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Engineering and computer science
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Social Sciences
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Data
                    Science
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Pharmacy
                  </li>
                </ul>
                <p>
                  The counselling process focuses on identifying the
                  distinguishing characteristics of an individual that will set
                  him or her apart from others and then working with the
                  individual to help him or her get into his or her dream
                  school. This is accomplished through multiple interactions
                  with the individual during the application season.
                </p>
              </div>

              <div className="col-12">
                <div className="course-details-content">
                  <h3 className="title">Our Services</h3>

                  <h5>Provide Guidance in College Selection</h5>
                  <p>
                    Students often have a bandwagon effect and perceive their
                    talents and flaws. GlobalDreamz helps aspirants to identify
                    their strengths, and desires and aims to offer career
                    choices that are appropriate for them, unlike other
                    customary international educational advisors.
                    <br></br>
                    <br></br>
                    Our education counsellors have assisted students in choosing
                    the right programme based on the program's coursework and
                    the research opportunities offered by the institution.
                  </p>

                  <h5>Resume</h5>
                  <p>
                    At GlobalDreamz, we evaluate the client's academic,
                    extracurricular, and professional successes. We accomplish
                    this by collaborating to write each applicant's CV clearly
                    and concisely.
                  </p>

                  <h5>VISA Consultant</h5>
                  <p>
                    We are a specialised consulting firm in India that focuses
                    on offering quick services. GlobalDreamz aids you in
                    realising your desire to pursue an international education
                    by helping you get through the final hurdle of acquiring a
                    VISA with style and comfort.
                  </p>

                  <h5>Admission Process</h5>
                  <p>
                    A degree from one of the best universities in the USA
                    provides you with plenty of chances. We, therefore, do
                    everything possible to make sure you get into the college
                    and program of your choice. Our knowledgeable advisors will
                    work directly with you to improve your chances of enrolling
                    in a USA university.
                    <br></br>
                    <br></br>
                    For a personalised admission plan that will effectively
                    highlight your originality, expertise, talents, and
                    authenticity to get the approval of the admission
                    department, our experienced advisors give highly
                    personalised counselling.
                    <br></br>
                    <br></br>
                    We keep you informed of important dates, timetables, and
                    other prerequisites for the application procedure for
                    international education.
                  </p>
                </div>
              </div>

              <div className="col-12">
                <div className="course-details-content">
                  <h3 className="title">Why choose us?</h3>

                  <p>
                    We at GlobalDreamz help you navigate the complex web of
                    questions to identify the answers that are most appropriate
                    for your situation while still letting you make the greatest
                    career decision.
                    <br></br>
                    <br></br>
                    Professional and experienced counsellors are available at
                    GlobalDreamz to help students determine their professional
                    goals, help them make more informed academic decisions, and
                    provide one-on-one guidance as they select the courses that
                    serve their needs.
                    <br></br>
                    <br></br>
                    We also organise direct conversations with representatives
                    from universities and provide thorough and unbiased
                    information on different study options. Through career
                    counselling, GlobalDreamz seeks to not only assist you in
                    making the decisions you must make right away but also
                    provide you with the information and abilities you'll need
                    to make decisions about your future professional and
                    personal life.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AboutSix />
      </Layout>
    </>
  );
};
export default StudyInUSADreamz;
