import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";

import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import EventData from "../../data/event/EventData.json";
import CategoryMba from "../../components/category/CategoryMba";
import ScrollAnimation from "react-animate-on-scroll";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import AboutSix from "../../components/about/AboutSix";
import CurriculumTabContent from "../../data/course/CurriculumTabContent.json";

const accordionItems = [
  {
    id: 1,
    title: "What are the benefits of preparing for the GMAT?",
    body: "GMAT is a standardised test for gaining entry to some of the top colleges and universities across the top international educational destinations. Not only does it help you in securing admission, it also helps you to get access to alumni from these prestigious institutes, and those who have previously appeared for this test. At GlobalDreamz, we give you a gateway to these two facilities. ",
  },
  {
    id: 2,
    title:
      "Which country/countries have the best management colleges and courses?",
    body: "Coveted management programmes are provided by the top institutes in the US, UK, Australia, Canada, Germany, and France. There are other countries also where the GMAT score is applicable and these countries also have good institutes. ",
  },
  {
    id: 3,
    title: "What are the prerequisites for pursuing the GMAT?",
    body: "Any candidate with a recognized bachelor's degree from a recognised university can appear for the GMAT. But for getting admission to a management course, you might be required to have some work experience also. This varies from institute to institute. ",
  },
  {
    id: 4,
    title:
      "Can I pursue MBA abroad even if I don't possess any work experience?",
    body: "Yes, you can pursue MBA in countries like Canada, Sweden, and Germany which offer premium MBA programmes even without prior work experience.",
  },
];

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button"
    >
      {children}
    </button>
  );
};

const CurriculumContent = () => {
  const [activeId, setActiveId] = useState("0");

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
      {CurriculumTabContent.map((accordion, i) => (
        <Accordion.Item
          eventKey={i.toString()}
          key={i}
          onClick={() => toggleActive(i.toString())}
          bsPrefix={`edu-accordion-item ${
            activeId === i.toString() ? "bg-active" : ""
          }`}
        >
          <div className="edu-accordion-header">
            <CustomToggle eventKey={i.toString()}>
              {accordion.title}
            </CustomToggle>
          </div>
          <Accordion.Body bsPrefix="edu-accordion-body">
            <ul>
              {accordion.content.map((title, index) => (
                <li key={index}>
                  <div className="text">
                    <i className="icon-draft-line"></i>
                    {title}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const GmatDreamz = () => {
  const { id } = useParams();
  const eventId = parseInt(1, 10);
  const data = EventData.filter((event) => event.id === eventId);
  const eventItem = data[0];

  const [activeId, setActiveId] = useState("0");
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

 

  const [contentTab, setContentTab] = useState("overview");
  const handleTab = (content) => {
    if (content === "overview") {
      setContentTab("overview");
    } else if (content === "curriculum") {
      setContentTab("curriculum");
    } else if (content === "instructor") {
      setContentTab("instructor");
    } else if (content === "reviews") {
      setContentTab("reviews");
    }
  };

  return (
    <>
      {/* <SEO title={eventItem.title} /> */}
      <SEO title="GMAT - GlobalDreamz" description="study for GMAT" keywords="study for GMAT" canonical="https://globaldreamzedu.com/gmat" />
      <Layout>
      <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
src={`${process.env.PUBLIC_URL}/images/event/event-details/sh-1.jpg`}
                    alt="Event Thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/${eventItem.image}`}
                    alt="Event Thumb"
                  />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h3 className="title">Why should you study for GMAT?</h3>
                  <p>
                    The graduate management admission test (GMAT) is the
                    steeping stone in securing admission to the top management
                    institutions overseas, further accentuating your career
                    growth. GMAT is designed to gauge aspiring students from
                    overseas in a neutral scale serving as an important
                    parameter for admissions in most top global business
                    schools. The GMAT exam is widely recognized and a reputed
                    test, conducted exclusively for students who want to pursue
                    management courses overseas. The GMAT is conducted by the
                    governing body of the Graduate Management Admission Council
                    (GMAC) and has been conducting the exam for the last six
                    decades. 2100+ international universities accept and
                    incorporate the GMAT exam for admitting foreign students.
                    Approximately 2,00,000 students prepare and attempt the GMAT
                    every year and try to secure entry into leading foreign
                    graduate business schools by getting a good score.
                  </p>

                    
                </div>
              </div>
             
            </div>
          </div>
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <h4>Five major elements that we offer:</h4>
                <p>
                  Contact our GMAT counsellors at GlobalDreamz to fulfilling
                  your GMAT aspirations! What we offer
                </p>
                <ul className="list-style-1 mb--20">
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Flexible programs for the preparation
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Learn from the best GMAT faculty(both national and
                    international)
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Learner friendly environment
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i> Getting
                    access to multiple management career opportunities abroad
                  </li>
                </ul>
                <p>
                  The process of preparation process focuses on initially
                  preparing for all the sections of the GMAT program viz. verbal
                  reasoning, analytical writing, quantitative reasoning, and
                  integrated reasoning. Preparation of each section of the exam
                  requires different aptitude and strategy. Our faculty guides
                  the student and also helps in strengthening their base for the
                  exam. Then, we focus on giving mock exams to check the prowess
                  of the student and to find the problem areas in their
                  preparation.
                </p>

                <CategoryMba />
              </div>

              <div className="col-12">
                <div className="edu-accordion-area mediashark-home-three-accordion accordion-shape-1 edu-section-gap bg-color-white">
                  <div className="container mediashark-animated-shape">
                    <div className="row g-5">
                      <div className="col-lg-6 gallery-wrapper">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="thumbnail">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-01.jpg`}
                                alt="About Thumb 1"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 mt--35 mt_sm--15">
                            <div className="thumbnail">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-02.jpg`}
                                alt="students discussion"
                              />
                            </div>
                            <div className="thumbnail mt--15">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-03.jpg`}
                                alt="students discussion"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <SectionTitle
                          classes="text-start mb--40"
                          slogan="FAQs"
                          title="Get Every General Answers From Here"
                        />
                        <ScrollAnimation
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          className="accordion-style-1"
                          animateOnce={true}
                        >
                          <Accordion
                            bsPrefix="edu-accordion"
                            defaultActiveKey="0"
                            flush
                          >
                            {accordionItems.map((accordion, i) => (
                              <Accordion.Item
                                eventKey={i.toString()}
                                key={i}
                                onClick={() => toggleActive(i.toString())}
                                bsPrefix={`edu-accordion-item ${
                                  activeId === i.toString() ? "active-item" : ""
                                }`}
                              >
                                <div className="edu-accordion-header">
                                  <CustomToggle eventKey={i.toString()}>
                                    {accordion.title}
                                  </CustomToggle>
                                </div>
                                <Accordion.Body bsPrefix="edu-accordion-body">
                                  {accordion.body}
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </ScrollAnimation>
                      </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                      <div className="shape shape-1">
                        <span className="shape-dot"></span>
                      </div>
                      <div className="shape-image shape-image-2">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/shapes/shape-11-04.png`}
                          alt="Shape Thumb"
                        />
                      </div>
                      <div className="shape-image shape-image-3">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/shapes/shape-25-01.png`}
                          alt="Shape Thumb"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="course-details-content">
                  <h3 className="title">Our Services</h3>
                  <ul className="edu-course-tab nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "overview"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Overview"
                        onClick={() => handleTab("overview")}
                      >
                        Course Description
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "curriculum"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Curriculum"
                        onClick={() => handleTab("curriculum")}
                      >
                        Curriculum
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "instructor"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Instructor"
                        onClick={() => handleTab("instructor")}
                      >
                        Instructor
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "reviews"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Reviews"
                        onClick={() => handleTab("reviews")}
                      >
                        Reviews
                      </button>
                    </li>
                  </ul>

                  <div className="tab-content">
                    {contentTab === "overview" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "overview" ? "active" : ""
                        } `}
                      >
                        
                        <div className="course-tab-content">
                          <h6>Overview</h6>
                          <p>
                            The GMAT exam is an entry pathway to the majority of
                            the top graduate management business programs around
                            the world. The Online GMAT Coaching exam includes
                            preparation for sections like integrated reasoning,
                            quantitative reasoning, analytical writing, and
                            verbal reasoning assessment. A student requires
                            analytical skills and critical thinking to
                            successfully solve the questions in a stipulated
                            period of 3.5 hours and ace the program with top
                            scores.
                          </p>

                          <h6>What will you learn from this course?</h6>
                          <p>
                            For clearing the exams with flying colours, a
                            student needs to prepare for the GMAT exam with a
                            well-defined preparation strategy. They should also
                            understand and grow familiar with the pattern and
                            the question type. For developing the required pace
                            to solve all the questions in the exam, a student
                            needs to have advanced time management skills to
                            beat the competition. At GlobalDreamz, we offer a
                            wide-ranging GMAT online course with a milestone
                            approach of teaching students top-notch academic
                            resources, while preparing them through mock tests
                            which simulate the real exam and the exam
                            environment, so that the student is familiarized
                            with the high-pressure environment. We also provide
                            one-on-one revision and preparation discussions with
                            multiple doubt-clearing sessions.
                          </p>

                          <h6>Curriculum</h6>
                          <p>
                            The primary goal of the GMAT is to prepare students
                            for a management program. The students will be
                            provided extensive training in the four sections of
                            the GMAT exam:
                          </p>
                          <ul>
                            <li>Integrated reasoning</li>
                            <li>Analytical writing</li>
                            <li>Quantitative reasoning</li>
                            <li>Verbal reasoning</li>
                          </ul>
                        </div>
                        <br></br>
                      </div>
                    )}

                    {contentTab === "curriculum" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "curriculum" ? "active" : ""
                        } `}
                      >
                        <div className="course-tab-content">
                          
                          <p>
                            The primary goal of the GMAT is to prepare students
                            for a management program. The students will be
                            provided extensive training in the four sections of
                            the GMAT exam:
                          </p>
                          <ul>
                            <li>Integrated reasoning</li>
                            <li>Analytical writing</li>
                            <li>Quantitative reasoning</li>
                            <li>Verbal reasoning</li>
                          </ul>
                        </div>
                      </div>
                    )}

                    {contentTab === "instructor" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "instructor" ? "active" : ""
                        } `}
                      ></div>
                    )}

                    {contentTab === "reviews" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "reviews" ? "active" : ""
                        } `}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

         
        </div>
        <AboutSix />
      </Layout>
    </>
  );
};
export default GmatDreamz;
