import React from "react";

const CoApplicant = () => {
  return (
    <>
      <div
        className="tile"
        id="co-applicant"
        style={{
          margin: 0,
        }}>
        {/* create a dot */}
        <li> Co– Applicant / Guarantor*: (Self-attested documents)</li>
        <ol className="document-list" style={{}}>
          <li>PAN Card</li>
          <li>OVD: _________________________</li>
          <li>2 passport-size photographs</li>
          <li>
            If any other existing loan from other Banks/Lenders, then Loan A/C
            statement for last 1 year
          </li>
          <li>
            Asset-Liability Statement * wherever applicable Income Proof for
            Salaried Co-applicant / Guarantor* (Self-attested documents)
          </li>
          <li>Salary Slip or Salary Certificate of last 3 months</li>
          <li>
            Copy of Form 16 or copy of IT Returns for last 2 financial years,
            acknowledged by IT Dept (ITR-V)
          </li>
          <li>
            Bank account statement for last 6 months (of Salary Account) Income
            Proof for Self-employed Co-applicant / Guarantor* Self-attested
            documents)
          </li>
          <li>Business address proof (If applicable)</li>
          <li>IT returns for last 2 years (if IT payee)</li>

          <li>
            TDS Certificate (Form 16A, if applicable) iv. Bank account statement
            for last 6 months * wherever applicable Major Documents for
            Collateralized Loans
          </li>
        </ol>
      </div>
      <div
        className="tile"
        id="purpose"
        style={{
          margin: 0,
        }}>
        {/* create a dot */}
        <li> For Land/ building offered as collateral-</li>
        <ol className="document-list" style={{}}>
          <li>Title Deed of the property</li>
          <li>
            Any other property related document(s) (if
            applicable)_____________________________
          </li>
          <li>
            If Liquid Security offered as collateral, photocopy of same to be
            enclosed
          </li>
        </ol>
      </div>
      <p
        style={{
          margin: 0,
        }}>
        <span
          style={{
            textDecoration: "underline",
            marginRight: "10px",
            fontWeight: "bold",
          }}>
          For Loans above Rs. 4 lacs and upto Rs. 20 Lacs for studies abroad :
        </span>{" "}
        DD/ Cheque of Rs. 5000/-
      </p>
      <p
        style={{
          margin: 0,
        }}>
        <span
          style={{
            textDecoration: "underline",
            marginRight: "10px",
            fontWeight: "bold",
          }}>
          Loan Processing Fee # :
        </span>{" "}
        DD/ Cheque of Rs. 11,800/- (Rs. 10,000 processing fee +18% GST)
      </p>
      <p
        style={{
          margin: 0,
        }}>
        <span
          style={{
            textDecoration: "underline",
            marginRight: "10px",
            fontWeight: "bold",
          }}>
          Advocate/ Legal Charges :
        </span>{" "}
        As applicable for the centre
      </p>
    </>
  );
};

export default CoApplicant;
