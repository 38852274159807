import React from "react";
import SEO from "../../../common/SEO";
import HeaderTwo from "../../../common/header/HeaderTwo";
import Features from "./feature-benefits/Features";
import { AiOutlineRight } from "react-icons/ai";
import DocumentsRequired from "./DocumentsRequired/DocumentsRequired";
import LoanFeatures from "./LoanFeatures/LoanFeatures";
import LoanEligibility from "./LoanEligibility/LoanEligibility";
import EmiCalculator from "./EmiCalculator/EmiCalculator";
import FAQs from "./FAQs/FAQs";
import LeftSidebar from "./LeftSidebar";

const SBI = () => {
  return (
    <>
      <SEO
        title="Study abroad consultants in India | GlobalDreamz"
        description="Looking for the best study abroad consultants in India? GlobalDreamz leading study abroad consultants in India."
        keywords="study abroad consultants in india, abroad study visa consultants, higher education consultant in pune, best overseas education consultant in pune, GlobalDreamz"
        canonical="https://globaldreamzedu.com/"
      />
      <HeaderTwo styles="header-transparent header-style-2" />

      {/* CONTAINER */}

      <div className="eduloans-container">
        {/* TITLE */}
        <LeftSidebar />

        <div className="content">
          <div className="title bg-primary-color">
            <h2>
              <span>STATE BANK OF INDIA</span>
            </h2>
          </div>

          {/* FEATURES */}
          <Features />

          {/* DOCUMENTS REQUIRED */}
          <DocumentsRequired />

          {/* LOAN FEATURES */}
          <LoanFeatures />

          {/* LOAN ELIGIBILITY */}
          <LoanEligibility />

          {/* EMI CALCULATOR */}
          <EmiCalculator />

          {/* FAQs */}
          <FAQs />
        </div>
      </div>
    </>
  );
};

export default SBI;
