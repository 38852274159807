import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";

const TermsConditions = () => {
  return (
    <>
      <SEO title="Terms and Conditions" />
      <SEO title="TITLE" description="DESCRIPTION" keywords="KEYWORDS" canonical="www.google.com" />
      <Layout>
      <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_terms.jpg`}
                    alt="Event Thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="content">
                
                <h4 className="title">Information</h4>
               
                <p>Please read these terms and conditions ("T&Cs") before entering the Contest. You agree that, by participating in this Contest, you will be bound by these T&Cs, and you acknowledge that you satisfy all Contest eligibility requirements as provided herein below: </p>
                <p>1.Entry fee is zero to participate in this Contest. </p>
                
                <p>2.The contest will commence on 20th December 2022 at 12:00:00 AM (IST) and ends on 27th December 2022 at 11:59:00 PM, (i.e. midnight (IST). </p>


                <p>3.Indian nationals of 18 to 45 years of age with valid proof of age and citizenship can participate in this contest.</p>
                <p>4.GlobalDreamz employees, their immediate family members (spouses, domestic partners, parents, grandparents, siblings, children, and grandchildren), and their affiliates, advisors, advertising/Contest agencies are not eligible to enter and participate in the Contest.</p>
                <p>5.Use of any kind of offensive language, creating nuisance,  or publishing of objectionable content, unlawful use or /distortion of the image of pertaining to GlobalDreamz in any manner whatsoever is prohibited and if found will lead to strict legal action as per the Indian laws.</p>

                <h4 className="title">Rules to participate in the Contest are as follows:</h4>

                <p>1.The Contest is available on GlobalDreamz’s offical Facebook, Instagram handle. </p>
                <p>2.Only one entry will be accepted per person across various platforms. </p>
                <p>3.To participate in the Contest, you need to:</p>
                <p>a.Take a selfie/photo of yourself with santa and/or with your friends/or family celebrating Christmas. </p>
                <p>b.Upload the selfie/photo in the comments section or the feed of our Facebook, Instagram.</p>

                <p>c.In the same comment/feed, tag your friends, GlobalDreamz and mention the Contest hashtag #Celebrationwithsanta , @GlobalDreamz</p>
                <p>4.Tag min 3 friends along in the comment/feed, whom you think would like to participate in the contest. </p>
                <p>5.All Instagram participants must follow GlobalDreamz Instagram account: </p>
                <p>6.The Instagram account of the participant should be minimum 6 months old, to be considered as a genuine account. </p>
                <p>7.All Facebook participants must like and follow GlobalDreamz Facebook page: </p>


                <p>9.A Minimum number of 20 (Twenty) ‘likes’ on your uploaded photo are required to be qualified for the Contest.</p>
                <p>10.Use of unauthorised means to increase the likes and/or comments is strictly prohibited and will lead to immediate disqualification of the participant from the Contest. </p>
                <p>11.The winners will be selected based on maximum likes. </p>
                <p>12. Number of likes and comments on the selfie/photo will be counted and considered up to 27th December 2022 at 11:59:59 PM.</p>
                <p>13.If the number of likes are same among participants, in that case, number of comments shall be taken into consideration. </p>


                <p>14.If there is a tie in the number of likes and comments, the participant who has posted the selfie/photo earlier (time stamp will be referred) shall be considered to be the winner of the contest.</p>
                <p>15.At the end of the Contest, GlobalDreamz will announce three winners – one from Facebook, two from Instagram– to receive an Amazon e-gift card worth Rs. 500/- each. </p>
                <p>16. Participants will also get voucher worth Rs. 50/- each as per point no. 9</p>
                <p>17.Winners will be announced on 30th Dec 2022 at 12 PM on @globaldreamz  Global Dreamz (Instagram &  Facebook handles). </p>
                <p>18.Winners will be chosen by an independent adjudicator or panel of judges appointed solely by GlobalDreamz only.</p>

                <p>19.Winners will be notified by an email within 10 (ten) working days of the date of announcement of winners and about the means of delivery/collection of the prize. If the winner is not contactable due to any reason whatsoever or does not claim the prize within 7 (seven) working days of notification, GlobalDreamz reserves the right to withdraw the prize from the winner. </p>
                <p>20. Global Dreamz decision in respect of the Contest will be final and no correspondence will be entertained.</p>
                <p>21. GlobalDreamz shall have the right, at its sole discretion and at any time, to change or modify these terms and conditions and such change shall be effective immediately upon posting to the GlobalDreamz brand handles on social media webpages/apps.</p>
                <p>22.By entering this Contest, an entrant is indicating his/her agreement to bound himself/herself by these terms and conditions and agrees to allow to use the image or content shared/posted by them for GlobalDreamz promotional activity.</p>
                <p>23.The winner agrees to the use of their names and images in any publicity content, along with their entry. Any personal data relating to the winner, or any other entrants, will be used solely in accordance with current Indian laws for data protection.</p>


                <p>24.This promotion is in no way sponsored, endorsed, or administered by, or associated with Facebook, Instagram,  and Amazon or any other social network.</p>
                <p>25. Global Dreamz also reserves its right to cancel the Contest if circumstances arise beyond its control. </p>
                <p>26.If a participant uses any tool or technology to get fake Likes, Shares and Tags, then the entry of such participants will stand disqualified.</p>
                <p>27.Incomplete, indecipherable, illegible, or incorrect entries or any entry which violate the terms and conditions will automatically be disqualified.</p>
                <p>28. GlobalDreamz will not accept any responsibility for entries lost, delayed, misdirected, damaged or undelivered. GlobalDreamz shall not be responsible for any technical error(s) in telecommunications networks, Internet access or any other medium otherwise preventing entry to the Contest.</p>


                <p>29. GlobalDreamz will not be held liable for (i) any delay in performing or (ii) partial or total failure to perform any of their obligations to the winners under these terms and conditions if such delay or failure is caused by circumstances beyond its reasonable control.</p>
                <p>30.Any personal information, including without limitation, the entrant's name, age, address (including postcode), telephone number and/or email address will be used solely in connection with this Contest and for any purpose for which the entrant specifically opts in and will not be disclosed to any third party except for: </p>
                <p>a.the purpose of fulfilling the prize where applicable and </p>
                <p>b.for any purpose for which the entrant specifically opts in.</p>
                <p>31.Any personal information held by GlobalDreamz in respect to the Contest will be held on record for a reasonable period to fulfil the purpose for which such personal information was collected. If an entrant gives GlobalDreamz the permission to pass on their personal information to a third party via a specific express consent/opt-in mechanism, then the privacy policy of such third-party handling the personal information shall apply and the handling of the entrant's information by such third-party shall no longer be the responsibility of GlobalDreamz. Participants have a right to access, correct and have their personal data deleted from Global Dreamz database by sending a written info@globaldreamzedu.com</p>

                
                <p>32.Any request/suggestion/query regarding the Contest must be addressed to help@globaldreamzedu.in together with name and contact information of the participant. </p>
                <p>33.The Contest and these Terms & Conditions will be governed by Indian law and any disputes will be subject to the exclusive jurisdiction of the courts at Mumbai.</p>
                
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default TermsConditions;
