import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const funFacts = [
  {
    title: "STUDENTS ENROLLED",
    icon: "icon-Bag",
    number: 550,
    suffix: "+",
  },
  {
    title: "SATISFACTION RATE",
    icon: "icon-trophy",
    number: 100,
    suffix: "%",
  },
  {
    title: "Academic Programs",
    icon: "icon-Open-book",
    number: 300,
    suffix: "+",
  },
  {
    title: "ONLINE INSTRUCTOR",
    icon: "icon-presentation",
    number: 150,
    suffix: "+",
  },
];

const CounterUpOne = ({ wrapperClass }) => {
  return (
    <div className="container">
      </div>
  );
};

export default CounterUpOne;
