import React from "react";

const InterestRates = () => {
  return (
    <div className="tile" id="interest-rates">
      {/* create a dot */}
      <li> Interest Rates</li>
      <ul className="expense-list">
        <li>SBI Student Loan Scheme :</li>

        <table
          className="table table-bordered"
          style={{
            margin: "10px 0px",
          }}>
          <thead>
            <tr>
              <th scope="col">
                Loan Amount for State Bank of India education loan
              </th>
              <th scope="col">Rate of Interest</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">
                Up to Rs.4 lakhs for State Bank of India education loan
              </th>
              <td>3.50% above the base rate</td>
            </tr>
            <tr>
              <th scope="row">Above Rs.4 lakhs and up to Rs.7.5 lakhs</th>
              <td>3.75% above the base rate</td>
            </tr>
            <tr>
              <th scope="row">Above Rs.7.50 lakhs</th>
              <td>1.75% above the base rate</td>
            </tr>
          </tbody>
        </table>

        <p>
          The base rate for <b>State Bank of India education loan</b> is fixed
          at 9.85%. A concession of 0.50% is provided for female students and a
          one percent concession is offered for the full tenure of the{" "}
          <b>State Bank of India education loan</b> if the interest is serviced
          punctually during the moratorium and course period.
        </p>

        <li>SBI Scholar Loan scheme : </li>
        <table
          className="table table-bordered"
          style={{
            margin: "10px 0px",
          }}>
          <thead>
            <tr>
              <th scope="col">
                Type of Institution for State Bank of India education loan
              </th>
              <th scope="col">
                Interest Rate per annum for State Bank of India education loan
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">List AA</th>
              <td>25 bps above base rate (10.10% at present)</td>
            </tr>
            <tr>
              <th scope="row">List A</th>
              <td>25 bps above base rate (10.10% at present)</td>
            </tr>
            <tr>
              <th scope="row">List B</th>
              <td>
                For unsecured loan up to Rs.20 lakhs, 50 bps above base rate
                (10.35% at present)
              </td>
            </tr>
            <tr>
              <th scope="row">List C</th>
              <td>175 bps above base rate (11.60% at present)</td>
            </tr>
          </tbody>
        </table>

        <p>
          For colleges listed in list B, the interest rate is 25 bps if the
          collateral security provided by the student is equal to or greater
          than the loan amount. This situation is also valid if the student’s
          work experience is greater than two years.
        </p>
        <li>
          <b>SBI Loan for Vocational Education and Training:</b> The rate of
          interest is fixed at 3.50% above the base rate of 9.85%
        </li>
      </ul>

      {/* CREATE A table with three columns and 2 rows */}
    </div>
  );
};

export default InterestRates;
