import React, { useState, useEffect } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import GalleryItem from "../../components/gallery/GalleryItem";
import FilterData from "../../data/gallery/FilterData.json";
import GalleryData from "../../data/gallery/GalleryData.json";

const GalleryDreamz = () => {
  const [getAllItems] = useState(GalleryData);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  useEffect(() => {
    setActiveFilter(FilterData[0].text.toLowerCase());
    setVisibleItems(getAllItems);
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData;
    if (
      e.target.textContent.toLowerCase() === FilterData[0].text.toLowerCase()
    ) {
      tempData = getAllItems;
    } else {
      tempData = getAllItems.filter(
        (data) => data.category === e.target.textContent.toLowerCase()
      );
    }
    setVisibleItems(tempData);
  };

  return (
    <>
      <SEO title="Gallery Grid" />
      <SEO title="Gallery - GlobalDreamz" description="Gallery" keywords="Gallery" canonical="https://globaldreamzedu.com/gallery" />
      <Layout>
      <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
src={`${process.env.PUBLIC_URL}/images/event/event-details/sh-1.jpg`}
                    alt="Event Thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-gallery-grid-area masonary-wrapper-activation edu-section-gap bg-image overflow-hidden">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6">
                <div className="section-title text-start">
                  <span className="pre-title">Gallery</span>
                  <h3 className="title">Gallery</h3>
                </div>
              </div>
            </div>

            <div className="mediashark-gallery-items row g-5 mt--5">
              {visibleItems.map((item) => (
                <div key={item.id} className="col-lg-4 col-md-6 col-12">
                  <GalleryItem data={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default GalleryDreamz;
