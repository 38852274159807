import React from "react";

const Forex = () => {
  return (
    <div className="card" id="forex">
      <h1 className="title">
        Benefits of taking{" "}
        <span
          className=""
          style={{
            color: "#13589D",
            textDecoration: "underline",
          }}>
          Forex
        </span>{" "}
        Services through Globaldreamz
      </h1>
      <ul className="list-style-1 mb--20">
        <li>
          <i className="icon-checkbox-circle-fill-solid"></i>Streamlined &
          Hassle-Free Process for Students & Parents{" "}
        </li>
        <li>
          <i className="icon-checkbox-circle-fill-solid"></i>Pan India Network –
          Partners can provide Service in More than 35+ Cities{" "}
        </li>
        <li>
          <i className="icon-checkbox-circle-fill-solid"></i>Transparent & Value
          – No Hidden Charges & Competitive Forex Rates{" "}
        </li>
        <li>
          <i className="icon-checkbox-circle-fill-solid"></i>Reliable &
          Comprehensive Solution{" "}
        </li>
      </ul>
    </div>
  );
};

export default Forex;
