import React from "react";
import { AiOutlineRight } from "react-icons/ai";

const Purpose = () => {
  return (
    <div className="tile" id="purpose">
      {/* create a dot */}
      <li> Purpose</li>
      <p>
        The main aim of the State Bank of India education loan Scheme is to
        provide loans to students from Indian nations for pursuing higher
        studies in India or at a foreign institution. This scheme is valid only
        for students who have secured admissions in premier institutions in
        India or at any other country. A separate loan scheme is offered for
        students pursuing vocational education and training in any country.
      </p>
    </div>
  );
};

export default Purpose;
