import PropTypes from "prop-types";
import React from "react";

const SEO = ({ title, description, keywords, canonical }) => {
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="reply-to" content="info@globaldreamzedu.com"/>
      <meta name="robots" content="index, Follow"/>
      <meta name="googlebot" content="index, follow" />
      <meta name="revisit-after" content="7 day"/>
      <meta name="rating" content="GENERAL" />
      <meta name="language" content="English" />
      <meta name="distribution" content="Global" />
      <meta name="copyright" content="https://globaldreamzedu.com/" />
      <link rel="publisher" href="GlobalDreamz" />
      <link rel="author" href="GlobalDreamz" />
      <meta
        name="description"
        content={description}
      />
      <meta
        name="keywords"
        content={keywords}
      />
      <link rel="canonical" href={canonical} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
