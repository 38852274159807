import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import EventData from "../../data/event/EventData.json";
import InstructorData from "../../data/instructor/InstructorData.json";
import CourseData from "../../data/course/CourseData.json";
import CategoryMba from "../../components/category/CategoryMba";
import ScrollAnimation from "react-animate-on-scroll";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import AboutSix from "../../components/about/AboutSix";
import { slugify } from "../../utils";
import CurriculumTabContent from "../../data/course/CurriculumTabContent.json";

const accordionItems = [
  {
    id: 1,
    title: "1.	Which MS course is best for me?",
    body: "Given the financial and time commitment required for a Master of Science programme, you should evaluate your unique circumstances, evaluating the more immediate costs of the degree against the results you hope to attain. You should consider the categories we've listed below while making decisions.",
  },
  {
    id: 2,
    title:
      "2.	What are the additional resources you will get during the MS programme abroad?",
    body: "If cost is a key factor for you, find out what kind of financial aid each programme provides. There are several alternatives available besides federal grants or loans. A lot of MS programmes provide funding options like scholarships, stipends, or teaching assistantships that can help offset the expense of the degree.",
  },
  {
    id: 3,
    title: "3.	How does one obtain a Master of Science degree?",
    body: "If cost is a key factor for you, find out what kind of financial aid each programme provides. There are several alternatives available besides federal grants or loans. A lot of MS programmes provide funding options like scholarships, stipends, or teaching assistantships that can help offset the expense of the degree.",
  },
  {
    id: 4,
    title: "4.	What can you do after completing your MS degree?",
    body: "You may be able to obtain more senior-level employment and greater earnings with the degree due to the profitable disciplines in which you can receive a Master of Science. Even in fields where an MS degree is not necessary, a master's programme can help you hone the high-level abilities you'll need to succeed in managing and executive-level positions.",
  },
];

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button">
      {children}
    </button>
  );
};

const CurriculumContent = () => {
  const [activeId, setActiveId] = useState("0");

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
      {CurriculumTabContent.map((accordion, i) => (
        <Accordion.Item
          eventKey={i.toString()}
          key={i}
          onClick={() => toggleActive(i.toString())}
          bsPrefix={`edu-accordion-item ${
            activeId === i.toString() ? "bg-active" : ""
          }`}>
          <div className="edu-accordion-header">
            <CustomToggle eventKey={i.toString()}>
              {accordion.title}
            </CustomToggle>
          </div>
          <Accordion.Body bsPrefix="edu-accordion-body">
            <ul>
              {accordion.content.map((title, index) => (
                <li key={index}>
                  <div className="text">
                    <i className="icon-draft-line"></i>
                    {title}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const MsDreamz = () => {
  const { id } = useParams();
  const eventId = parseInt(1, 10);
  const data = EventData.filter((event) => event.id === eventId);
  const eventItem = data[0];

  const [activeId, setActiveId] = useState("0");
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const courseId = parseInt(1, 10);
  const dataCourse = CourseData.filter((course) => course.id === courseId);
  const courseItem = dataCourse[0];

  const indexOfInstructor = InstructorData.findIndex(function (instructor) {
    return slugify(instructor.name) === slugify(courseItem.instructor);
  });
  const instructor = InstructorData[indexOfInstructor];
  const instructorExcerpt = instructor.details.substring(0, 190) + "...";

  const [contentTab, setContentTab] = useState("overview");
  const handleTab = (content) => {
    if (content === "overview") {
      setContentTab("overview");
    } else if (content === "curriculum") {
      setContentTab("curriculum");
    } else if (content === "instructor") {
      setContentTab("instructor");
    } else if (content === "reviews") {
      setContentTab("reviews");
    }
  };

  return (
    <>
      <SEO
        title="Best Countries for ms for indian students 2023"
        description="Get expert guidance to discover top MBA programs abroad for Indian students. Explore eligibility criteria and the best countries for MBA."
        keywords="cost of ms in usa for indian students, admission counselling for ms, best country for ms for indian students, best colleges for ms in abroad"
        canonical="https://globaldreamzedu.com/ms"
      />
      <Layout>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_1.jpg`}
                    alt="ms students"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="content">
                  <h1 className="title">Why should you do MS abroad?</h1>
                  <p>
                    The Master of Science (MS) degree is an advanced degree that
                    often relates to fields like technology, math, medicine,
                    business, administration, and the natural sciences. You can
                    obtain specialised abilities you can use to boost your
                    career in several in-demand industries while advancing your
                    knowledge in a more technical, analytical, and occasionally
                    practical way through an MS degree programme.
                  </p>
                  <p>
                    Many students choose to pursue a master's degree after
                    graduating because it not only provides them with a deeper
                    knowledge of their subject matter but also opens up more
                    career options. Studying abroad for a master's degree has
                    numerous advantages. As well as enhancing one's academic
                    skills, studying abroad also enriches one's cultural and
                    socio-demographic knowledge. Are you considering pursuing
                    your MS degree abroad? To learn more about the admissions
                    process and how to apply, get in touch with our counsellors
                    at GlobalDreamz today!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <h4>Five major elements that we offer:</h4>
                <ul className="list-style-1 mb--20">
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Rich Experience – Learn from A Team with A Collective
                    Experience of Over 100+ Years
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Customised Flexibility – Rolling Batches with Timings
                    Designed to Suit the Students
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Personal Attention – Get Your Own Counsellor to Help You
                    Deal with All Your Queries and Difficulties
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Comprehensive Solutions – From Test Prep to Financial Help
                    to Arrival in Your Study Destination
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Online
                    Services - Learn Online with A State of Art System from The
                    Comfort of Your Own Home
                  </li>
                </ul>
                <p>
                  Counselling helps individuals identify the unique qualities
                  that will set them apart from others, and help them get into
                  the school of their choice.
                </p>

                <CategoryMba />
              </div>

              <div className="col-12">
                <div className="edu-accordion-area mediashark-home-three-accordion accordion-shape-1 edu-section-gap bg-color-white">
                  <div className="container mediashark-animated-shape">
                    <div className="row g-5">
                      <div className="col-lg-6 gallery-wrapper">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="thumbnail">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-01.jpg`}
                                alt="About Thumb 1"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 mt--35 mt_sm--15">
                            <div className="thumbnail">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-02.jpg`}
                                alt="students discussion"
                              />
                            </div>
                            <div className="thumbnail mt--15">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-03.jpg`}
                                alt="students discussion"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <SectionTitle
                          classes="text-start mb--40"
                          slogan="FAQs"
                          title="Get Every General Answers From Here"
                        />
                        <ScrollAnimation
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          className="accordion-style-1"
                          animateOnce={true}>
                          <Accordion
                            bsPrefix="edu-accordion"
                            defaultActiveKey="0"
                            flush>
                            {accordionItems.map((accordion, i) => (
                              <Accordion.Item
                                eventKey={i.toString()}
                                key={i}
                                onClick={() => toggleActive(i.toString())}
                                bsPrefix={`edu-accordion-item ${
                                  activeId === i.toString() ? "active-item" : ""
                                }`}>
                                <div className="edu-accordion-header">
                                  <CustomToggle eventKey={i.toString()}>
                                    {accordion.title}
                                  </CustomToggle>
                                </div>
                                <Accordion.Body bsPrefix="edu-accordion-body">
                                  {accordion.body}
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </ScrollAnimation>
                      </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                      <div className="shape shape-1">
                        <span className="shape-dot"></span>
                      </div>
                      <div className="shape-image shape-image-2">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/shapes/shape-11-04.png`}
                          alt="Shape Thumb"
                        />
                      </div>
                      <div className="shape-image shape-image-3">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/shapes/shape-25-01.png`}
                          alt="Shape Thumb"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="course-details-content">
                  <h3 className="title">Our Services</h3>
                  <ul className="edu-course-tab nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "overview"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Overview"
                        onClick={() => handleTab("overview")}>
                        Course Description
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "curriculum"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Curriculum"
                        onClick={() => handleTab("curriculum")}>
                        Curriculum
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "instructor"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Instructor"
                        onClick={() => handleTab("instructor")}>
                        Instructor
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "reviews"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Reviews"
                        onClick={() => handleTab("reviews")}>
                        Reviews
                      </button>
                    </li>
                  </ul>

                  <div className="tab-content">
                    {contentTab === "overview" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "overview" ? "active" : ""
                        } `}>
                        <div className="course-tab-content">
                          <h6>Overview</h6>
                          <p>
                            Studying for a Master of Science overseas can
                            provide you with a competitive advantage in the job
                            market and help you advance your career in research.
                            <br></br>
                            An MS degree can be obtained in a variety of fields,
                            including aviation, space studies, information
                            science, environmental studies, and the financial,
                            accounting, biological sciences, engineering, and
                            technological fields. Throughout the year, there are
                            typically two intakes. The first intake occurs in
                            September, followed by the next sometime in January
                            or February.
                          </p>

                          <h6>What will you learn from this course?</h6>
                          <p>
                            {" "}
                            It's always a good idea to complete an internship
                            before finishing your postgraduate programme. It
                            greatly aids in obtaining employment after
                            completing your degree of study. Regarding
                            employment chances, an MS in STEM—Science,
                            Technology, Engineering, and Mathematics—will
                            provide access to academic and research fields as
                            well as a variety of businesses, including those in
                            biotechnology, healthcare, hospitality, retail,
                            construction, and financial services.
                          </p>

                          <h6>Certification</h6>
                          <p>
                            You will advance more quickly by earning degrees
                            that are acknowledged and authorised on a global
                            scale.
                          </p>
                        </div>
                        <br></br>
                      </div>
                    )}

                    {contentTab === "curriculum" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "curriculum" ? "active" : ""
                        } `}>
                        <div className="course-tab-content">
                          <p>
                            The primary goal of an MBA is to educate students in
                            Accounting, Marketing, Human Resources, and
                            Operations Management.
                          </p>
                          <p>
                            <b>An MBA will cover the following topics:</b>
                          </p>
                          <ul>
                            <li>Introduction to Marketing </li>
                            <li>Micro Economics </li>
                            <li>Accounting</li>
                            <li>Human resource management </li>
                            <li>Business Law</li>
                            <li>Introduction to Finance</li>
                            <li>Operations and Information management </li>
                            <li>Organizational Behaviour </li>
                          </ul>
                        </div>
                      </div>
                    )}

                    {contentTab === "instructor" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "instructor" ? "active" : ""
                        } `}>
                        <div className="course-tab-content">
                          <div className="course-author-wrapper">
                            <div className="thumbnail">
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  `/instructor-details/${slugify(
                                    courseItem.instructor
                                  )}`
                                }>
                                <img
                                  src={`${process.env.PUBLIC_URL}/images/instructor/course-details/${instructor.image}`}
                                  alt="Author Thumb"
                                />
                              </Link>
                            </div>
                            <div className="author-content">
                              <h6 className="title">
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    `/instructor-details/${slugify(
                                      courseItem.instructor
                                    )}`
                                  }>
                                  {instructor.name}
                                </Link>
                              </h6>
                              <span className="subtitle">
                                {instructor.designation}
                              </span>
                              <p>{instructorExcerpt}</p>
                              <ul className="social-share border-style">
                                <li>
                                  <a href={instructor.facebookUrl}>
                                    <i className="icon-Fb"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={instructor.linkedInUrl}>
                                    <i className="icon-linkedin"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={instructor.pinterest}>
                                    <i className="icon-Pinterest"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={instructor.twitterUrl}>
                                    <i className="icon-Twitter"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {contentTab === "reviews" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "reviews" ? "active" : ""
                        } `}>
                        <div className="course-tab-content">
                          <div className="row row--30">
                            <div className="col-lg-4">
                              <div className="rating-box">
                                <div className="rating-number">
                                  {courseItem.rating}
                                </div>
                                <div className="rating mediashark-course-rating-stars">
                                  <i className="icon-Star"></i>
                                  <i className="icon-Star"></i>
                                  <i className="icon-Star"></i>
                                  <i className="icon-Star"></i>
                                  <i className="icon-Star"></i>
                                </div>
                                <span>({courseItem.review} Review)</span>
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <div className="review-wrapper">
                                <div className="single-progress-bar">
                                  <div className="rating-text">
                                    5 <i className="icon-Star"></i>
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{ width: "100%" }}
                                      aria-valuenow="100"
                                      aria-valuemin="0"
                                      aria-valuemax="100"></div>
                                  </div>
                                  <span className="rating-value">1</span>
                                </div>

                                <div className="single-progress-bar">
                                  <div className="rating-text">
                                    4 <i className="icon-Star"></i>
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{ width: "0%" }}
                                      aria-valuenow="0"
                                      aria-valuemin="0"
                                      aria-valuemax="100"></div>
                                  </div>
                                  <span className="rating-value">0</span>
                                </div>

                                <div className="single-progress-bar">
                                  <div className="rating-text">
                                    3 <i className="icon-Star"></i>
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{ width: "0%" }}
                                      aria-valuenow="0"
                                      aria-valuemin="0"
                                      aria-valuemax="100"></div>
                                  </div>
                                  <span className="rating-value">0</span>
                                </div>

                                <div className="single-progress-bar">
                                  <div className="rating-text">
                                    2 <i className="icon-Star"></i>
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{ width: "0%" }}
                                      aria-valuenow="0"
                                      aria-valuemin="0"
                                      aria-valuemax="100"></div>
                                  </div>
                                  <span className="rating-value">0</span>
                                </div>

                                <div className="single-progress-bar">
                                  <div className="rating-text">
                                    1 <i className="icon-Star"></i>
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{ width: "0%" }}
                                      aria-valuenow="0"
                                      aria-valuemin="0"
                                      aria-valuemax="100"></div>
                                  </div>
                                  <span className="rating-value">0</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="comment-wrapper pt--40">
                            <div className="section-title">
                              <h5 className="mb--25">Reviews</h5>
                            </div>
                            <div className="edu-comment">
                              <div className="thumbnail">
                                <img
                                  src={`${process.env.PUBLIC_URL}/images/course/student-review/student-1.png`}
                                  alt="Student Thumb"
                                />
                              </div>
                              <div className="comment-content">
                                <div className="comment-top">
                                  <h6 className="title">Elen Saspita</h6>
                                  <div className="rating mediashark-course-rating-stars">
                                    <i className="icon-Star"></i>
                                    <i className="icon-Star"></i>
                                    <i className="icon-Star"></i>
                                    <i className="icon-Star"></i>
                                    <i className="icon-Star"></i>
                                  </div>
                                </div>
                                <span className="subtitle">
                                  “ Outstanding Course ”
                                </span>
                                <p>
                                  As Thomas pointed out, Chegg’s survey appears
                                  more like a scorecard that details obstacles
                                  and challenges that the current university
                                  undergraduate student population is going
                                  through in their universities and countries.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AboutSix />
      </Layout>
    </>
  );
};
export default MsDreamz;
