import React from "react";

const Student = () => {
  return (
    <div
      className="tile"
      id="student"
      style={{
        margin: 0,
      }}>
      {/* create a dot */}
      <li> Student Applicant: (Self-attested documents)</li>
      <ol className="document-list" style={{}}>
        <li>
          {" "}
          Academic Records: 10th Result 12th Result Graduation Result-
          Semester-wise (if applicable)Entrance Exam Result
          _____________________________ (mention the Test taken)
        </li>
        <li>
          Proof of admission: Admission Letter/ Offer Letter (studies abroad
          only) / ID card (for reimbursement cases)
        </li>
        <li>Copies of letter conferring scholarship, free-ship, etc.</li>
        <li>Schedule of expenses</li>
        <li>Gap certificate, if applicable</li>
        <li>2 passport-size photographs</li>
        <li>Passport to be mandatorily obtained in case of Studies Abroad</li>
        <li>PAN Card</li>
        <li>
          AADHAAR card, if available (mandatory for availing subsidy benefit)
        </li>
        <li>OVD: _________________________</li>
        <li>
          If any other existing loan from other Banks/Lenders, then Loan A/C
          statement for last 1 year
        </li>
      </ol>
    </div>
  );
};

export default Student;
