import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const FeatureItems = [

  {
    title: "Exam Preparation",
    info: "Language proficiency tests play a major role in international admissions. Get guidance on how to ace your proficiency tests. So whether you are looking for IELTS coaching or looking for coaching in TOEFL, IELTS, GRE, or PTE, your search ends here Contact us for assistance in the preparation of the following",
    item1: "GRE",
    item3: "TOEFL",
    item4: "IELTS",
    item5: "PTE",
    item6: "SAT",
    icon: "icon-Browser",
    shape: "shape-bg-1",
    link: "#",
  },

  {
    title: "Admission Counselling",
    info: "Need assistance in choosing the right college for you? Our experienced and dedicated counselling team is here to help you out. Be it your MS Degree/ MBA/Ph.D. or for your Undergraduate admission. In other words, our counsellors hand-hold you throughout to get through to your dream college.",
    item1: "- University Shortlisting",
    item2: "- SOP",
    item3: "- LOR",
    item4: "- Resume Writing",
    item5: "- Visa",
    icon: "icon-Destination",
    shape: "shape-bg-2",
    link: "#",
  },


  {
    title: "Other Services",
    info: "We firmly believe that the right opportunity can change the lives of those with the right talent. The best-suited opportunities for your abilities and aptitudes are matched with our approach. Apart from career counselling and exam preparation, we also provide a wide range of different services enlisted below: ",
    item1: "Forex Services",
    item2: "Education Loan",
    item3: "Travel & Health Insurance",
    item4: "Accommodation",
    item5: "Pre Departure Services",
    icon: "icon-Settings",
    shape: "shape-bg-4",
    link: "#",
  },
];

const FeatureTwo = ({ wrapperClass, bgColor, alignment, style }) => {
  return (
    <div
      className={`row mediashark-about-one-service ${
        wrapperClass || "g-5 mt--20"
      }`}
    >
      {FeatureItems.map((data, i) => (
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          className={`col-lg-4 col-md-6 col-12${style ? " " + style : ""}`}
          animateOnce={true}
          key={i}
        >
          <div
            className={`service-card service-card-3 ${
              alignment ? " " + alignment : "text-left"
            } ${data.shape} ${bgColor || ""} `}
          >
            <div className="inner">
              <div className="icon">
                <a href={data.link}>
                  <i className={data.icon}></i>
                </a>
              </div>
              <div className="content">
                <h6 className="title">
                  <a href={data.link}>{data.title}</a>
                </h6>
                <p className="description">{data.info}</p>
                <p className="mb-1">{data.item1}</p>
                <p className="mb-1">{data.item2}</p>
                <p className="mb-1">{data.item3}</p>
                <p className="mb-1">{data.item4}</p>
                <p className="mb-1">{data.item5}</p>
                <p className="mb-1">{data.item6}</p>

              
              </div>
            </div>
          </div>
        </ScrollAnimation>
      ))}
    </div>
  );
};

export default FeatureTwo;
