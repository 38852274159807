import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";

import SEO from "../../common/SEO";
import Layout from "../../common/Layout";

import EventData from "../../data/event/EventData.json";
import InstructorData from "../../data/instructor/InstructorData.json";
import CourseData from "../../data/course/CourseData.json";
import CategoryMba from "../../components/category/CategoryMba";
import ScrollAnimation from "react-animate-on-scroll";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import SectionTitle from "../../components/sectionTitle/SectionTitle";

import AboutSix from "../../components/about/AboutSix";
import { slugify } from "../../utils";
import CurriculumTabContent from "../../data/course/CurriculumTabContent.json";

const accordionItems = [
  {
    id: 1,
    title: "1.	What are the benefits of doing an MBA abroad?",
    body: "The benefits of doing an MBA overseas are the handsome salary and benefits packages. Not only that it provides you with excellent career opportunities, networking opportunities, skills and knowledge regarding current business trends, and interactions with business organisations and prospective employers for a better career.",
  },
  {
    id: 2,
    title: "2.	Which country is the best for pursuing MBA?",
    body: "Some of the top countries which provide coveted MBA programmes are the US, UK, Australia, Canada, Germany, and France. Although, there are other notable programmes in various countries which are equally sought after.",
  },
  {
    id: 3,
    title: "3.	What are the prerequisites for pursuing an MBA abroad?",
    body: "A bachelor's degree from a recognised university is required to pursue an MBA abroad. You are also required to show a work experience of 3 to 5 years.",
  },
  {
    id: 4,
    title: "4.	Can I study MBA abroad if I don't have any work experience?",
    body: "Yes, countries such as Canada, Sweden, and Germany offer MBA programmes without prior work experience.",
  },
];

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button">
      {children}
    </button>
  );
};

const CurriculumContent = () => {
  const [activeId, setActiveId] = useState("0");

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
      {CurriculumTabContent.map((accordion, i) => (
        <Accordion.Item
          eventKey={i.toString()}
          key={i}
          onClick={() => toggleActive(i.toString())}
          bsPrefix={`edu-accordion-item ${
            activeId === i.toString() ? "bg-active" : ""
          }`}>
          <div className="edu-accordion-header">
            <CustomToggle eventKey={i.toString()}>
              {accordion.title}
            </CustomToggle>
          </div>
          <Accordion.Body bsPrefix="edu-accordion-body">
            <ul>
              {accordion.content.map((title, index) => (
                <li key={index}>
                  <div className="text">
                    <i className="icon-draft-line"></i>
                    {title}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const MbaDreamz = () => {
  const { id } = useParams();
  const eventId = parseInt(1, 10);
  const data = EventData.filter((event) => event.id === eventId);
  const eventItem = data[0];

  const [activeId, setActiveId] = useState("0");
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const courseId = parseInt(1, 10);
  const dataCourse = CourseData.filter((course) => course.id === courseId);
  const courseItem = dataCourse[0];

  const indexOfInstructor = InstructorData.findIndex(function (instructor) {
    return slugify(instructor.name) === slugify(courseItem.instructor);
  });
  const instructor = InstructorData[indexOfInstructor];
  const instructorExcerpt = instructor.details.substring(0, 190) + "...";

  const [contentTab, setContentTab] = useState("overview");
  const handleTab = (content) => {
    if (content === "overview") {
      setContentTab("overview");
    } else if (content === "curriculum") {
      setContentTab("curriculum");
    } else if (content === "instructor") {
      setContentTab("instructor");
    } else if (content === "reviews") {
      setContentTab("reviews");
    }
  };

  return (
    <>
      {/* <SEO title={eventItem.title} /> */}
      <SEO
        title="Best admission consultants for mba abroad- GlobalDreamz"
        description="Discover business schools and programs tailored to Indian students. With our expert advice and guidance, find the right MBA for you and take your career to the next level."
        keywords="mba in abroad eligibility, best mba colleges abroad for indian students, best country for mba for indian students, best admission consultants for mba abroad"
        canonical="https://globaldreamzedu.com/mba"
      />
      <Layout>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_2.jpg`}
                    alt="mba students"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="content">
                  <h3 className="title">Why should you do MBA abroad?</h3>
                  <p>
                    A Master of Business Administration (MBA) can help you
                    improve your professional skills while gaining industry
                    knowledge and is among the most sought-after degrees for
                    career growth. Pursuing an MBA from a top B-school in
                    another country may provide you with significantly more
                    career opportunities by raising your professional status in
                    the job market. Your MBA will set you apart, in a
                    competitive job market. An MBA will provide you with the
                    necessary managerial skills, irrespective of your
                    professional goals, whether being a corporate leader or
                    launching a successful entrepreneurial venture. To better
                    understand the admission and application process and kick
                    start your dream to study MBA abroad, contact our
                    counsellors for MBA Counselling at GlobalDreamz today!
                  </p>
                  <p>
                    An MBA from the top tier international business schools has
                    a significant global acceptance in the industry, thus
                    improving your professional prospects. These universities
                    also provide vocational training in their programmes to
                    ensure that students gain hands-on industry experience. It
                    gives students access to business experts, allowing them to
                    build a strong professional network. Aside from increasing
                    your earning potential highly, studying MBA abroad provides
                    you with better opportunities to learn in a diverse
                    environment and to understand different countries' work
                    ethics.
                  </p>
                  <p>
                    An MBA will provide you with the necessary managerial
                    skills, irrespective of your professional goals, whether
                    being a corporate leader or launching a successful
                    entrepreneurial venture. To better understand the admission
                    and application process and kick start your dream to study
                    MBA abroad, contact our counsellors for MBA Counselling at
                    GlobalDreamz today!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <h4>Five major elements that we offer:</h4>
                <ul className="list-style-1 mb--20">
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Rich Experience – Learn from A Team with A Collective
                    Experience of Over 100+ Years
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Customised Flexibility – Rolling Batches with Timings
                    Designed to Suit the Students
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Personal Attention – Get Your Own Counsellor to Help You
                    Deal with All Your Queries and Difficulties
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>{" "}
                    Comprehensive Solutions – From Test Prep to Financial Help
                    to Arrival in Your Study Destination
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Online
                    Services - Learn Online with A State of Art System from The
                    Comfort of Your Own Home
                  </li>
                </ul>
                <p>
                  The counselling process focuses on identifying the
                  distinguishing characteristics of an individual that will set
                  him or her apart from others and then working with the
                  individual to help him or her get into his or her dream
                  school. This is accomplished through multiple interactions
                  with the individual during the application season.
                </p>

                <CategoryMba />
              </div>

              <div className="col-12">
                <div className="edu-accordion-area mediashark-home-three-accordion accordion-shape-1 edu-section-gap bg-color-white">
                  <div className="container mediashark-animated-shape">
                    <div className="row g-5">
                      <div className="col-lg-6 gallery-wrapper">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="thumbnail">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-01.jpg`}
                                alt="About Thumb 1"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 mt--35 mt_sm--15">
                            <div className="thumbnail">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-02.jpg`}
                                alt="students discussion"
                              />
                            </div>
                            <div className="thumbnail mt--15">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-03.jpg`}
                                alt="students discussion"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <SectionTitle
                          classes="text-start mb--40"
                          slogan="FAQs"
                          title="Get Every General Answers From Here"
                        />
                        <ScrollAnimation
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          className="accordion-style-1"
                          animateOnce={true}>
                          <Accordion
                            bsPrefix="edu-accordion"
                            defaultActiveKey="0"
                            flush>
                            {accordionItems.map((accordion, i) => (
                              <Accordion.Item
                                eventKey={i.toString()}
                                key={i}
                                onClick={() => toggleActive(i.toString())}
                                bsPrefix={`edu-accordion-item ${
                                  activeId === i.toString() ? "active-item" : ""
                                }`}>
                                <div className="edu-accordion-header">
                                  <CustomToggle eventKey={i.toString()}>
                                    {accordion.title}
                                  </CustomToggle>
                                </div>
                                <Accordion.Body bsPrefix="edu-accordion-body">
                                  {accordion.body}
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </ScrollAnimation>
                      </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                      <div className="shape shape-1">
                        <span className="shape-dot"></span>
                      </div>
                      <div className="shape-image shape-image-2">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/shapes/shape-11-04.png`}
                          alt="Shape Thumb"
                        />
                      </div>
                      <div className="shape-image shape-image-3">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/shapes/shape-25-01.png`}
                          alt="Shape Thumb"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="course-details-content">
                  <h3 className="title">Our Services</h3>
                  <ul className="edu-course-tab nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "overview"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Overview"
                        onClick={() => handleTab("overview")}>
                        Course Description
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "curriculum"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Curriculum"
                        onClick={() => handleTab("curriculum")}>
                        Curriculum
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "instructor"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Instructor"
                        onClick={() => handleTab("instructor")}>
                        Instructor
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "reviews"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Reviews"
                        onClick={() => handleTab("reviews")}>
                        Reviews
                      </button>
                    </li>
                  </ul>

                  <div className="tab-content">
                    {contentTab === "overview" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "overview" ? "active" : ""
                        } `}>
                        {/* <div
                          className="course-tab-content"
                          dangerouslySetInnerHTML={{
                            __html: courseItem.details,
                          }}
                        /> */}
                        <div className="course-tab-content">
                          <h6>Overview</h6>
                          <p>
                            MBA is divided into different categories based on
                            the convenience and affordability of the students to
                            stay away from the job market, such as - Full-time
                            MBA which is for students who are willing to leave
                            the workforce and devote their full time to weekday
                            classes and regular studies, and the other one is a
                            Part-time MBA option for aspirants who want to
                            advance their careers by learning managerial skills
                            and additional degree while still working. Since
                            classes are held on weekends, it is also known as
                            the Evening or Weekend MBA programme.
                          </p>

                          <h6>What will you learn from this course?</h6>
                          <p>
                            {" "}
                            Business administration is the process of organising
                            a company's personnel and resources to achieve its
                            goals and objectives. Human Resources, Operations,
                            Financial Management, and Marketing are examples of
                            such processes.
                          </p>

                          <h6>Certification</h6>
                          <p>
                            Globally accepted and accredited degrees, which
                            jump-starts your growth.
                          </p>
                        </div>
                        <br></br>
                      </div>
                    )}

                    {contentTab === "curriculum" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "curriculum" ? "active" : ""
                        } `}>
                        <div className="course-tab-content">
                          {/* <CurriculumContent /> */}
                          <p>
                            The primary goal of an MBA is to educate students in
                            Accounting, Marketing, Human Resources, and
                            Operations Management.
                          </p>
                          <p>
                            <b>An MBA will cover the following topics:</b>
                          </p>
                          <ul>
                            <li>Introduction to Marketing </li>
                            <li>Micro Economics </li>
                            <li>Accounting</li>
                            <li>Human resource management </li>
                            <li>Business Law</li>
                            <li>Introduction to Finance</li>
                            <li>Operations and Information management </li>
                            <li>Organizational Behaviour </li>
                          </ul>
                        </div>
                      </div>
                    )}

                    {contentTab === "instructor" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "instructor" ? "active" : ""
                        } `}></div>
                    )}

                    {contentTab === "reviews" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "reviews" ? "active" : ""
                        } `}></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <TestimonialSectionFour /> */}
        </div>
        <AboutSix />
      </Layout>
    </>
  );
};
export default MbaDreamz;
