import React from "react";

const Amount = () => {
  return (
    <div className="tile" id="amount">
      {/* create a dot */}
      <li> Amount of Education Loan</li>
      <ul className="expense-list">
        <li>SBI Student Loan Scheme</li>
        <li>
          The bank offers <b>State Bank of India education loan</b> up to Rs.10
          lakhs for studies in India. It also offers an{" "}
          <b>SBI overseas education loan</b> up to a maximum of Rs.1.5 crores.
        </li>
        <table
          className="table table-bordered"
          style={{
            margin: "10px 0px",
          }}>
          <thead>
            <tr>
              <th scope="col">
                Category of the educational institution for State Bank of India
                education loan
              </th>
              <th scope="col">
                Maximum Loan Limit Without Security for State Bank of India
                education loan
              </th>
              <th scope="col">
                Maximum Loan Limit With Security for State Bank of India
                education loan
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">List A</th>
              <td>Rs.20 lakhs</td>
              <td>Rs.30 lakhs</td>
            </tr>
            <tr>
              <th scope="row">List B</th>
              <td>Rs.7.5 lakhs</td>
              <td>Rs.30 lakhs</td>
            </tr>
          </tbody>
        </table>

        <li>
          <b>State Bank of India education</b> loan for Vocational Education and
          Training up to six months is offered at a maximum of Rs. 50,000. For
          courses with duration of more than six months, students get Rs.one
          lakh as a loan.
        </li>
      </ul>

      {/* CREATE A table with three columns and 2 rows */}
    </div>
  );
};

export default Amount;
