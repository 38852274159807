import React from "react";
import SBILoanFeatures from "./SBILoanFeatures";

const LoanFeatures = () => {
  return (
    <div className="features" id="loan-features">
      <h3>Loan Features</h3>
      <SBILoanFeatures />
    </div>
  );
};

export default LoanFeatures;
