import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Layout from "../../common/Layout";
import SEO from "../../common/SEO";
import WallGalleryItem from "../../components/gallery/WallGalleryItem";
import FilterData from "../../data/gallery/FilterData.json";
import WallGalleryData from "../../data/gallery/WallGalleryData.json";
import {
  TestimonialTwoThumDots,
  wallOfFameScrollParams,
} from "../../utils/script";

const data = [
  {
    name: "Omkar Panchange",
    course: "Master of Science in Industrial Engineering",
    university: "Clemson University, USA",
    sliderimage: "student-visa-1.jpeg",
    altTag: "student visa approval",
  },
  {
    name: "Abin Abraham",
    course: "Masters in Electrical Engineering",
    university: "University of Colorado Boulder, USA",
    sliderimage: "student-visa-2.jpeg",
    altTag: "student visa approval",
  },
  {
    name: "Shikha Sharma",
    course: "Masters in Computer Science",
    university: "llinois Institute of Technology, USA",
    sliderimage: "student-visa-3.jpeg",
    altTag: "student visa approval",
  },
  {
    name: "Pranay Khushalani",
    course: "Masters of Science in Business Analytics",
    university: "University of Texas at Dallas, USA",
    sliderimage: "student-visa-4.jpeg",
    altTag: "student visa approval",
  },
  {
    name: "Subodh Kadam",
    course: "MS in Information Technology and Management",
    university: "University of Texas at Dallas, USA",
    sliderimage: "student-visa-5.jpeg",
    altTag: "student visa approval",
  },
  {
    name: "Rakhi Raut",
    course: "Engineering Management",
    university: "Syracuse University, USA",
    sliderimage: "student-visa-6.jpeg",
    altTag: "student visa approval",
  },
  {
    name: "Barkha Shete",
    course: "MS - Computer Science",
    university: "Northeastern University, USA",
    sliderimage: "student-visa-7.jpg",
    altTag: "student visa approval",
  },
  {
    name: "Parth Kahane",
    course: "MS in Information Technology and Management",
    university: "University of Texas at Dallas, USA",
    sliderimage: "student-visa-8.jpg",
    altTag: "student visa approval",
  },
  {
    name: "Prabhleen Kaur Saluja",
    course: "Master's in Computer & Information Sciences",
    university: "University of Texas Arlington, USA",
    sliderimage: "student-visa-9.jpg",
    altTag: "student visa approval",
  },
  {
    name: "Parth Dabi",
    course: "Master's in Automotive Engineering",
    university: "Clemson University, USA",
    sliderimage: "student-visa-10.jpg",
    altTag: "student visa approval",
  },
  {
    name: "Tanmayee Mahesh Behere",
    course: "Master's in Computer Science",
    university: "Purdue University, USA",
    sliderimage: "student-visa-11.jpg",
    altTag: "student visa approval",
  },
  {
    name: "C. Laxmi Bhavani",
    course: "Information Technology",
    university: "Central Michigan University, USA",
    sliderimage: "student-visa-12.jpg",
    altTag: "student visa approval",
  },
  {
    name: "Prajwal Patil",
    course: "MSC in Data Science",
    university: "University of Stirling, UK",
    sliderimage: "student-visa-13.jpg",
    altTag: "student visa approval",
  },
  {
    name: "Bhagyashree Zende",
    course: "MSC in Structural Engineering",
    university: "Brunel University, London",
    sliderimage: "student-visa-14.jpg",
    altTag: "student visa approval",
  },
  {
    name: "Dipti Rajesh Khedkar",
    course: "Master of Science in Industrial Engineering",
    university: "Clemson University, USA",
    sliderimage: "student-visa-15.jpg",
    altTag: "student visa approval",
  },
  {
    name: "Amol B.",
    course: "MS in Computer Science",
    university: "Northeastern University, USA",
    sliderimage: "student-visa-16.jpg",
    altTag: "student visa approval",
  },
  {
    name: "Mrunal Deshmukh",
    course: "MS in Mechanical Engineering",
    university: "Clemson University, USA",
    sliderimage: "student-visa-17.jpg",
    altTag: "student visa approval",
  },
  {
    name: "Mrunalini Wawse",
    course: "MS in Computer Science",
    university: "University of Pacific, USA",
    sliderimage: "student-visa-18.jpg",
    altTag: "student visa approval",
  },
];

const WallofFameDreamz = () => {
  const [getAllItems] = useState(WallGalleryData);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  useEffect(() => {
    setActiveFilter(FilterData[0].text.toLowerCase());
    setVisibleItems(getAllItems);
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData;
    if (
      e.target.textContent.toLowerCase() === FilterData[0].text.toLowerCase()
    ) {
      tempData = getAllItems;
    } else {
      tempData = getAllItems.filter(
        (data) => data.category === e.target.textContent.toLowerCase()
      );
    }
    setVisibleItems(tempData);
  };

  const [testimonialNav, setTestimonialNav] = useState();
  const [testimonialThumb, setTestimonialThumb] = useState();

  return (
    <>
      <SEO title="Gallery Grid" />
      <SEO
        title="TITLE"
        description="DESCRIPTION"
        keywords="KEYWORDS"
        canonical="www.google.com"
      />
      <Layout>
        {/* <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_13.jpg`}
                    alt="Event Thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="edu-gallery-grid-area masonary-wrapper-activation edu-section-gap bg-image overflow-hidden">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6">
                <div className="section-title text-start">
                  {/* <span className="pre-title">Wall of Fame</span> */}
                  <h3 className="title">Wall of Fame</h3>
                </div>
              </div>
            </div>
            <div
              className=""
              style={
                {
                  // border: "1px solid #e5e5e5",
                }
              }>
              <Slider
                style={{
                  // border: "1px solid red",
                  padding: "0px",
                  // position: "relative",
                  // bottom: "100px",
                }}
                className="testimonial-nav-activation"
                asNavFor={testimonialThumb}
                ref={(slider1) => setTestimonialNav(slider1)}
                {...wallOfFameScrollParams}>
                {data.map((item, i) => (
                  // <a href="/global-dreamz-enquiry">
                  <div
                    className="testimonial-nav-content"
                    key={i}
                    style={
                      {
                        // border: "1px solid #e5e5e5",
                      }
                    }>
                    <img
                      className="girl-thumb"
                      src={`${process.env.PUBLIC_URL}/images/gallery/student-visa-images/${item.sliderimage}`}
                      alt={`${item.altTag}`}
                      style={{
                        padding: "10px",
                        // border: "1px solid #e5e5e5",
                        margin: "0px 10px",
                      }}
                    />
                  </div>
                  // </a>
                ))}
              </Slider>
            </div>

            <div className="mediashark-gallery-items row g-5 mt--5">
              {visibleItems.map((item) => (
                <div key={item.id} className="col-lg-4 col-md-6 col-12">
                  <WallGalleryItem data={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default WallofFameDreamz;
