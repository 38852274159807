import React from "react";
import { AiOutlineRight } from "react-icons/ai";

export const menuData = [
  {
    title: "Bank of Baroda Education Loan Key Features and Benefits",
    id: "features-benefits",
    subMenu: [
      {
        id: "features-benefits-baroda-vidya",
        title: "Baroda Vidya",
        content: (
          <p>
            One of the major features of Bank of Baroda education loan is the
            Baroda Vidya education loan which is customized for the students who
            wish to pursue school education starting fro Nursery to Senior
            Secondary School. <br />
            <b>The main features of this type of loan are:</b>
            <ul className="expense-list">
              <table
                className="table table-bordered"
                style={{
                  margin: "10px 0px",
                }}>
                <tbody>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Loan Amount of Bank of Baroda education loan
                    </th>
                    <th scope="col">Rs. 4 lakhs</th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Eligibility for Bank of Baroda education loan
                    </th>
                    <td>
                      1. Only for Indian nationals, residing in India
                      <br /> 2.The applicant should have secured admission in a
                      recognized institute with ICSE, state board, or CBSE for
                      courses like Stage I( Nursery to Std. V), Stage II(Std. VI
                      to Std. VIII), and Stage III( Std. IX to Std. XII).
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Interest Rate of Bank of Baroda education loan
                    </th>
                    <td>
                      1. <b>Bank of Baroda education loan</b> up to Rs.4 lakhs:
                      Base Rate + 2.50 percent <br />
                      <br />
                      Note:
                      <ul>
                        <li>Fee payable to college / school.</li>
                        <li>Examination / Library / Laboratory Fee.</li>
                        <li>Fee and other charges payable to hostel.</li>
                        <li>
                          Purchase of books / equipments / instruments /
                          uniforms.
                        </li>
                        <li>Personal Computers / Laptops wherever required.</li>
                        <li>
                          Caution deposit / building fund / refundable deposit
                          supported by instituion bills / receipts.
                        </li>
                        <li>
                          Cost of external coaching/tuition is not to be
                          considered
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Processing Fee of Bank of Baroda education loan
                    </th>
                    <td>
                      There are <b>no processing fees</b> and documentation
                      charges in Baroda Vidya’s under Bank of Baroda education
                      loan.
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Security of Bank of Baroda education loan
                    </th>
                    <td>
                      No security. If the loan is being taken for the purchase
                      of items like a computer or laptop, then those items will
                      be pledged as security for the loan.
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Margin of Bank of Baroda education loan
                    </th>
                    <td>None</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Repayment Tenure of Bank of Baroda education loan
                    </th>
                    <td>
                      It is important to repay the yearly sub-limit in 12 EMIs.
                      The first installment is due 12 months after the dispersal
                      of every year’s loan component.
                    </td>
                  </tr>
                </tbody>
              </table>
            </ul>
          </p>
        ),
      },
      {
        id: "features-benefits-baroda-gyan",
        title: "Baroda Gyan",
        content: (
          <p>
            Baroda Gyan is one of the features that come under the Bank of
            Baroda education loan and applies to the students who wish to pursue
            their undergraduate and postgraduate courses or any other
            professional courses within India.
            <br />
            <b>
              {" "}
              Some of the features that make this Bank of Baroda education loan
              student-friendly are:
            </b>
            <ul className="expense-list">
              <table
                className="table table-bordered"
                style={{
                  margin: "10px 0px",
                }}>
                <tbody>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Maximum Loan Limit
                    </th>
                    <th scope="col">
                      Normal 10 Lakhs. The maximum loan amount for this Bank of
                      Baroda education loan is Rs. 80 lakhs for medical &
                      aviation education and Rs. 60 lakhs for other courses
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Eligibility
                    </th>
                    <td>
                      <ul>
                        <li>Graduation courses : BA, B.Com, B.Sc., etc </li>
                        <li>Post Graduation courses : Masters and Phd. </li>
                        <li>
                          Professional courses like: Engineering, Medical,
                          Agriculture, Veterinary, Law, Dental, Management,
                          Architecture, Computer etc.{" "}
                        </li>
                        <li>
                          (Management courses include Hotel Mgt., Hospitality
                          Mgt., Event Mgt., Mass Communication etc offered by
                          reputed institutions having assured employment
                          prospects)
                        </li>{" "}
                        <li>
                          Computer certificate courses of reputed institutes
                          accredited{" "}
                        </li>
                        <li>
                          to Dept. of Electronics or institutes affiliated to an
                          approved university.{" "}
                        </li>
                        <li>Courses like ICWA, CA, CFA etc. </li>
                        <li>
                          Courses conducted by IIM, IIT, IISc, XLRI. NIFT etc.{" "}
                        </li>
                        <li>
                          Regular Degree / Diploma courses like Aeronautical,
                          Pilot training, Shipping etc., approved by the
                          Director General of Civil Aviation / Shipping.{" "}
                        </li>
                        <li>
                          Courses offered in India by reputed Foreign
                          Universities.{" "}
                        </li>
                        <li>Evening courses of approved institutes. </li>
                        <li>
                          Other courses leading to diploma / degree etc.
                          conducted by colleges / universities approved by UGC /
                          Govt./ AICTE / AIBMS / ICMR etc.{" "}
                        </li>
                        <li>
                          Teachers Training Course / Nursing Course / BEd will
                          be eligible for education loan provided the training
                          institutions are approved either by the Central
                          Government or by State Government and such courses
                          should lead to Degree or Diploma Courses and not to
                          Certificate Course. Branches can consider the request
                          for an education loan under our “Baroda Gyan Scheme”
                          for the above courses. The fees to be considered for
                          purpose should be the fees structure as approved by
                          the State Government or Government approved Regulatory
                          Body for merit seats in Government Colleges / Private
                          Self Financing Colleges, Cooperative and Colleges run
                          by Universities, as the case may be.
                        </li>
                      </ul>
                      <br />
                      The <b>Bank of Baroda Education Loan</b> scheme applies to
                      merit students who are eligible for a seat under the merit
                      quota but pursue a course under the management quota.{" "}
                      <br />
                      The management quota seats that are under the{" "}
                      <b>Bank of Baroda Education Loan</b> scheme and the fees
                      applicable to these seats (authorized by State Government
                      will be taken subject to viability of repayment)
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Interest Rate
                    </th>
                    <td>
                      1. BRLLR +2.00% (including Strategic Premium) <br />
                      <br />
                      Note:
                      <ul>
                        <li>
                          Female students under Bank of Baroda education loan
                          get an applicable 0.50 percent concession.{" "}
                        </li>
                        <li>
                          The bank will charge simple interest through the
                          moratorium period with monthly rests.{" "}
                        </li>
                        <li>
                          If the loan amount is more than two lakhs, the penal
                          interest at the rate of two percent will apply to the
                          overdue amount.{" "}
                        </li>
                        <li>
                          Added concession of one percent applies to the
                          students who are admitted in some of the premier
                          institutions like IIT, AIIMS, IIM, IIFT, ISB, NIT,
                          IISc, XLRI, MDI, and SPJIM.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Processing Fee
                    </th>
                    <td>
                      No processing charges for this Bank of Baroda education
                      loan.
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Security
                    </th>
                    <td>
                      No security for loan amount up to rupees four lakhs in
                      this Bank of Baroda education loan.
                      <br /> <br /> Loan amount from to rupees four lakhs to
                      rupees seven and a half lakhs: third party assignment
                      towards future salaries and guarantee towards loan
                      repayment. <br /> <br />
                      Loan amount above seven and a half lakh: Tangible
                      collateral as security that is equal to 100 percent of the
                      loan amount. Also the assignment of future salaries
                      towards loan repayment.
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Margin
                    </th>
                    <td>
                      None for loans up to rupees four lakhs.
                      <br /> <br />
                      Five percent for loan above rupees four lakhs.
                      <br /> <br />
                      <b>Note-</b> Margin is applied on a pro-rata basis on a
                      yearly basis as and when disbursements are availed.
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Repayment Tenure
                    </th>
                    <td>
                      For loan amount up to rupees seven and a half lakhs,
                      repayment should be in maximum 120 installments. <br />{" "}
                      <br />
                      Loan amount above rupees seven and a half lakhs and a
                      repayment in max 180 installments
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Moratorium Period
                    </th>
                    <td>
                      The repayment holiday starts from Course Period + one year
                      or six months after getting the job (whichever comes
                      first).
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Expenses Covered
                    </th>
                    <td>
                      <ol>
                        <li>The fee of the institute </li>
                        <li>
                          Fee for the library, examination, hostel, and
                          laboratories{" "}
                        </li>
                        <li>
                          Purchase of books, uniform, instruments, and equipment{" "}
                        </li>
                        <li>
                          Purchase of personal laptop or computer only if it is
                          necessary for the course{" "}
                        </li>
                        <li>
                          Extra payments like Caution deposit, building funds,
                          and Refundable deposit which are backed up with proper
                          receipts and bills.{" "}
                        </li>
                        <li>
                          Additional expenses that are important for the
                          completion of the course including projects, study
                          tours, and thesis.
                        </li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Perks
                    </th>
                    <td>
                      Free Debit Card comes with this Bank of Baroda education
                      loan.
                    </td>
                  </tr>
                </tbody>
              </table>
            </ul>
            <br />
          </p>
        ),
      },
      {
        id: "features-benefits-baroda-scholar",
        title: "Baroda Scholar",
        content: (
          <p>
            This is one of the specialized offerings from Bank of Baroda
            overseas education loan department exclusively for the students who
            want to take up higher studies in some reputed universities in the
            foreign land.
            <br />
            <b> Some of the key features of this loan are:</b>
            <ul className="expense-list">
              <table
                className="table table-bordered"
                style={{
                  margin: "10px 0px",
                }}>
                <tbody>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Maximum Loan Limit
                    </th>
                    <th scope="col">
                      Rs. 60 lakhs for non Premier, 80 Lakhs for Premier
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Eligibility
                    </th>
                    <td>
                      <ul>
                        <li>
                          The applicant must be an Indian citizen and should
                          have got admission in a reputed college abroad in some
                          professional/technical course.{" "}
                        </li>
                      </ul>
                      The <b>Bank of Baroda Education Loan</b> scheme applies to
                      merit students who are eligible for a seat under the merit
                      quota but pursue a course under the management quota.{" "}
                      <br /> The management quota seats that are under the{" "}
                      <b>Bank of Baroda Education Loan</b> scheme and the fees
                      applicable to these seats (authorized by State Government
                      will be taken subject to viability of repayment)
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Interest Rate
                    </th>
                    <td>
                      Loans up to rupees four lakhs: Base Rate + 2.50 percent
                      <br />
                      Loans from rupees four lakhs to rupees seven and a half
                      lakhs: Base Rate + 2.50 percent
                      <br />
                      Loans above rupees seven and a half lakhs: Base Rate +
                      1.75%
                      <br />
                      Bank of Baroda Overseas Education Loan for Vocational
                      Education and Training: Base Rate + 2.00 percent
                      <br /> <br />
                      <b>Note-</b> Female students under this Bank of Baroda
                      education loan will get a concession of .5 percent. Also,
                      the simple interest will be charged through the moratorium
                      period with monthly rests. If the loan amount is more than
                      two lakhs rupees, a panel interest rate of two percent
                      will be applied to the overdue amount. Students admitting
                      to some premier institutions like IIT, IIFT, IIM, AIIMS,
                      NIT, MDI, XLRI, and SPJIM will get an added concession of
                      one percen
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Processing Fee
                    </th>
                    <td>
                      INR 10,000 that is refundable upon acquisition of the
                      loan.
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Security
                    </th>
                    <td>
                      Loan amount up to rupees four lakhs: None <br />
                      <br />
                      Loan amount from four lakhs to seven and a half lakhs:
                      Third-party guarantee and assignment of future salaries
                      towards loan repayment.
                      <br />
                      <br />
                      Loan amount above rupees seven and a half lakhs: Tangible
                      collateral as security equivalent to 100 percent of the
                      loan amount. Plus the assignment of future salaries
                      towards loan repayment.
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Margin
                    </th>
                    <td>0 to 10.00 percent</td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Repayment Tenure
                    </th>
                    <td>
                      Loan amount up to rupees seven and a half lakhs and the
                      repayment in maximum 120 installments <br />
                      Loan amount above rupees seven and a half lakhs and the
                      repayment within180 installments
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Moratorium Period
                    </th>
                    <td>
                      The repayment holiday applies from the course Period + six
                      months or one year after getting the job (whichever comes
                      first).
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Expenses Covered
                    </th>
                    <td>
                      <ol>
                        <li>The fee of the institute </li>
                        <li>
                          Fee for the library, examination, hostel, and
                          laboratories{" "}
                        </li>
                        <li>
                          Fee for the library, examination, hostel, and
                          laboratories{" "}
                        </li>
                        <li>
                          Purchase of books, uniform, instruments, and equipment{" "}
                        </li>
                        <li>
                          Purchase of personal laptop or computer only if it is
                          necessary for the course{" "}
                        </li>
                        <li>
                          Extra payments like Caution deposit, building funds,
                          and Refundable deposit which are backed up with proper
                          receipts and bills.{" "}
                        </li>
                        <li>
                          Additional expenses that are important for the
                          completion of the course including projects, study
                          tours, and thesis.{" "}
                        </li>
                        <li>Expenses for one-way travel.</li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      style={{
                        fontWeight: "bold",
                      }}>
                      Courses Eligibility
                    </th>
                    <td>
                      <ul>
                        <li>
                          All undergraduate, Doctorate, post-graduate, and
                          Technical courses along with other job oriented
                          courses offered by recognized foreign Universities.
                        </li>
                        Degree/Dip{" "}
                        <li>
                          loma in Aeronautical, shipping, and pilot training
                          which are approved by Director General of Civil
                          Aviation/shipping in India as well as a competent
                          local aviation/shipping authority.
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ul>
            <br />
          </p>
        ),
      },
    ],
  },
  {
    title: "Loan Documentation",
    id: "loan-documentation",
    subMenu: [
      {
        id: "loan-documentation-intro",
        title: "Intro",
        content: (
          <p>
            <b>Bank of Baroda education loan</b> documentation is a simple
            process and doesn’t involve any hard-core paperwork. It can be
            conducted online without paying any physical visits to the banks.
            However, the process may vary depending on the criteria set by
            different banks or lending organizations. Some banks who are
            extremely strict with their conditions may reject the application
            with failure to submit all the required documents.
          </p>
        ),
      },
      {
        id: "required-documents",
        title: "Required Documents",
        content: (
          <>
            <ol className="expense-list">
              <li>
                Duly filled and signed application form with the applicant’s
                affixed photographs{" "}
              </li>
              <li>Two passport size photographs </li>
              <li>
                One copy of exam mark sheets of the latest education certificate{" "}
              </li>
              <li>Statement of course expenses/cost of study </li>
              <li>
                Aadhaar Card and Pan Card of the Student and Parent/ Guardian
              </li>
              <p>
                <b>Age Proof : </b>
                <br />
                Copy of Aadhaar Card /Voter ID/Passport/Driving License <br />{" "}
                <br />
                <b>Identity Proof : </b>
                <br />
                Copy of Voter ID/Aadhaar Card/Driving License/Passport <br />{" "}
                <br />
                <b>Residence Proof : </b>
                <br />
                Rental agreement/guarantor/Bank statement of 6 months of the
                student or co-borrower/Copy of Ration card/Gas Book/Electricity
                Bill/Tel Bill
                <br />
                <br />
                <b>Income Proof : </b>
                <br />
                <ul>
                  <li>
                    Most recent salary slips or Form 16 of the parent/
                    co-borrower/guardian{" "}
                  </li>
                  <li>
                    Six months bank statement of the borrower or updated
                    passbook of bank{" "}
                  </li>
                  <li>
                    Updated ITR (Income Tax Return with income computation) of
                    two years or IT assessment order of last two years of
                    parent/ guardian/co-borrower{" "}
                  </li>
                  <li>
                    Documents stating the assets and liabilities of the
                    parent/co-borrower/guardian
                  </li>
                </ul>
                <br />
                <br />
              </p>
            </ol>
          </>
        ),
      },
    ],
  },
  {
    title: "Loan Features",
    id: "loan-features",
    subMenu: [
      {
        id: "loan-features-loan-amount",
        title: "Loan Amount",
        content: (
          <p>
            The Bank of Baroda provides loans upto 80 lakhs with deviation for
            preferential list universities and 60 lakhs with deviation for other
            universities. In some cases, it can provide a loan above 60 lakhs
            for universities that are not listed in the preferential list.
          </p>
        ),
      },
      {
        id: "loan-features-interest-rate",
        title: "Percentage of Loan Against Collateral",
        content: (
          <p>
            The bank provides a loan amount upto 90 percent of Property’s
            Realizable Value and FD value and loan upto 80 percent of the LIC
            Surrender value and KPV and NSC Value. If the student is admitted to
            one of the universities listed in the preferential list, they can
            get a loan upto 100 percent of the property value.
          </p>
        ),
      },
      {
        id: "loan-features-collateral-property",
        title: "Collateral Property",
        content: (
          <p>
            If the students are keeping their property as security, they will
            have to submit all the required registered documents of the property
            along with occupational certificate and completion certification
            registered by the Municipal corporation of India. Also the property
            should be a non-agriculture land and should be inside the premises
            of the city.
          </p>
        ),
      },
      {
        id: "loan-features-student-profile-requirements",
        title: "Student’s Profile",
        content: (
          <p>
            To provide <b>Bank of Baroda overseas education loan</b> to any
            applicant, the bank will check the academic background of the
            student (no. of KTs throughout his last education) along with the
            course and institution he is applying for.
          </p>
        ),
      },
      {
        id: "loan-features-interest-rate",
        title: "Interest rate",
        content: (
          <p>
            {" "}
            Bank of Baroda charges are prime lending rate + 2% for non premier
            and for premier they are lending rate + 0.75% on repo rate. The bank
            also gives a concession of .5 percent for female applicants and .75
            percent discount for Preferential List Universities.
          </p>
        ),
      },
      {
        id: "loan-features-interest-paying-during-moratorium-period",
        title: "Interest Paying During Moratorium Period",
        content: (
          <p>
            The bank also provides an option of paying the interest on Bank of
            Baroda overseas education loan during moratorium period or along
            with EMIs.
          </p>
        ),
      },
    ],
  },
  {
    title: "Loan Eligibility",
    id: "loan-eligibility",
    subMenu: [
      {
        id: "eligibility-parameters",
        title: "Parameters",
        content: (
          <p>
            <ul className="expense-list">
              <table
                className="table table-bordered"
                style={{
                  margin: "10px 0px",
                }}>
                <thead>
                  <tr>
                    <th scope="col">Parameters</th>
                    <th scope="col">Eligibility criteria</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Age of the Applicant</th>
                    <td>
                      <ul>
                        <li>Up to 35 years for non-employed people </li>
                        <li>
                          Up to 45 years in the case of working employees and
                          executives
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Who can apply</th>
                    <td>
                      <ul>
                        <li>
                          Indian Nationals with secured admission to any
                          professional or technical course in a recognized
                          institution.
                        </li>
                        <li>
                          Permanent employees who are employed at least for
                          three years with any organization and wish to gain
                          higher professional qualifications. These
                          professionals should also have secured admissions in a
                          reputed institute.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Repayment Frequency</th>
                    <td>
                      <ul>
                        <li>Quarterly or monthly rests.</li>
                        <li>
                          Repayment of Education Loans typically starts one year
                          after completing the course or six months after
                          getting a job, whichever is earlier.
                        </li>
                        <li>
                          Loan interest re‐payment will start immediately after
                          disbursement of the first installment of the loan.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Maximum Loan Amount</th>
                    <td>
                      <ul>
                        <li>Upto Rs. 8,000,000 for studies in India</li>
                        <li>
                          Upto Rs. 8,000,000 under Bank of Baroda overseas
                          education loan studies abroad.
                        </li>
                        <li>
                          Upto Rs. 8,000,000 for studies at IIT's, ISB's, and
                          IIM's.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Repayment Period</th>
                    <td>
                      <ul>
                        <li>
                          The entire tenure of a Bank of Baroda education loan
                          includes Duration of Study, Grace Period after the
                          Study, and Repayment Period.{" "}
                        </li>
                        <li>
                          For Indian courses, repayment tenure is maximum of 15
                          years after the commencement of repayment.{" "}
                        </li>
                        <li>
                          For Abroad courses, repayment tenure is maximum of 15
                          years after the commencement of repayment.
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ul>
          </p>
        ),
      },
    ],
  },
  {
    title: "Loan EMI Calculator",
    id: "loan-emi-calculator",
    subMenu: [
      {
        id: "loan-emi-calculator-intro",
        title: "Tabular Representation",
        content: (
          <p>
            <ul className="expense-list">
              <table
                className="table table-bordered"
                style={{
                  margin: "10px 0px",
                }}>
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Courses in India</th>
                    <th scope="col">Courses Abroad</th>
                    <th scope="col">IIT, IIM, ISB Courses</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <b>Max Loan Amount fot Bank of Baroda education loan</b>
                    </th>
                    <td>Rs. 80 Lakh</td>
                    <td>Rs. 80 Lakh</td>
                    <td>Rs. 80 Lakh</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Loans upto Rs. 4 lakh</b>
                    </th>
                    <td>8.40% - 10.40%</td>
                    <td>9.65 % - 10.40% , for Girls - 9.15%</td>
                    <td>8.40%</td>
                  </tr>
                  <tr>
                    <th scope="row">Loans Rs. 4.0 - 7.5 lakh</th>
                    <td>8.40% - 10.40%</td>
                    <td>9.65 % - 10.40% , for Girls - 9.15%</td>
                    <td>8.40 % - 10.40%</td>
                  </tr>
                  <tr>
                    <th scope="row">Loans {">"} Rs. 7.5 lakh</th>
                    <td>8.40% - 10.40%</td>
                    <td>9.65 % - 10.40% , for Girls - 9.15%</td>
                    <td>8.40 % - 10.40%</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>
                        Margin requirement (own contribution by student/ family)
                      </b>
                    </th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Loans upto Rs. 4 lakh</th>
                    <td>0%</td>
                    <td>0%</td>
                    <td>0%</td>
                  </tr>
                  <tr>
                    <th scope="row">Loans above Rs. 4 lakh</th>
                    <td>5%</td>
                    <td>10%</td>
                    <td>5%</td>
                  </tr>{" "}
                  <tr>
                    <th scope="row">
                      <b>Max Tenure Post Moratorium</b>
                    </th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Loans upto Rs. 7.5 lakh</b>
                    </th>
                    <td>10 years</td>
                    <td>10 years</td>
                    <td>10 years</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Loans above Rs. 7.5 lakh</b>
                    </th>
                    <td>15 years</td>
                    <td>15 years</td>
                    <td>15 years</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Co-applicant required (yes/no)</b>
                    </th>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Third Party Guarantee</b>
                    </th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Loans upto Rs. 4 lakh</th>
                    <td>Not Required</td>
                    <td>Not Required</td>
                    <td>Not Required</td>
                  </tr>
                  <tr>
                    <th scope="row">Loans Rs. 4.0 - 7.5 lakh</th>
                    <td>Required</td>
                    <td>Required</td>
                    <td>Required</td>
                  </tr>
                  <tr>
                    <th scope="row">Loans {">"} Rs. 7.5 lakh</th>
                    <td>Not Required</td>
                    <td>Not Required</td>
                    <td>Not Required</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Collateral Security (Fixed deposit/ Property, LIC)</b>
                    </th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Loans upto Rs. 4 lakh</th>
                    <td>Not Required</td>
                    <td>Not Required</td>
                    <td>Not Required</td>
                  </tr>
                  <tr>
                    <th scope="row">Loans Rs. 4.0 - 7.5 lakh</th>
                    <td>Not Required</td>
                    <td>Not Required</td>
                    <td>Not Required</td>
                  </tr>
                  <tr>
                    <th scope="row">Loans {">"} Rs. 7.5 lakh</th>
                    <td>Required</td>
                    <td>Required</td>
                    <td>Required</td>
                  </tr>{" "}
                  <tr>
                    <th scope="row">
                      <b>Prepayment Charges (Y/N)</b>
                    </th>
                    <td>Nil</td>
                    <td>Nil</td>
                    <td>Nil</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <b>Moratorium Period (lower of the two)</b>
                    </th>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">After course period</th>
                    <td>12 months</td>
                    <td>12 months</td>
                    <td>12 months</td>
                  </tr>
                  <tr>
                    <th scope="row">After getting job</th>
                    <td>6 months</td>
                    <td>6 months</td>
                    <td>6 months</td>
                  </tr>
                </tbody>
              </table>
            </ul>
          </p>
        ),
      },
    ],
  },
  {
    title: "Loan FAQs:",
    id: "bob-loan-faqs",
    subMenu: [
      {
        id: "bob-loan-faqs-intro",
        title: "Most Common FAQs",
        content: (
          <p>
            <div>
              <b>
                {" "}
                1. What is a ‘Financing Branch’ under Bank of Baroda Education
                Loan?
              </b>{" "}
              <br />
              The Bank of Baroda branch that is closest to the applicant’s
              permanent address usually dispenses the Bank of Baroda education
              loan and therefore is termed as ‘Financing Branch’.
            </div>{" "}
            <br />
            <div>
              <b>
                {" "}
                2. How will Bank of Baroda track the educational progress of the
                applicant, borrower, or student?
              </b>{" "}
              <br />
              To apply for Bank of Baroda education loan, the bank will ask the
              applicant to submit their progress reports to the bank regularly
              so that the bank can keep a record of their progress..
            </div>{" "}
            <br />
            <div>
              <b>
                {" "}
                3. Is the applicant’s or student’s lodging and boarding covered
                in the subscribed Bank of Baroda education loan?
              </b>{" "}
              <br />
              If the student doesn’t secure the hostel facility offered by the
              concerned college or institution, they can seek to lodge
              elsewhere. The student has to confirm the authenticity of the
              place they are staying at to allow the bank directly remit the
              expenses to the boarding and lodging facility.
            </div>{" "}
            <br />
            <div>
              <b>
                {" "}
                4. What does ‘Margin' mean in the context of education loans?
              </b>{" "}
              <br />
              ‘Margin’ is termed as the difference between the loan amount
              promised by the bank and the actual disbursed amount. For
              instance- The Bank of Baroda Scholar education loan comes with a
              margin of 15 percent, which means, if the loan amount is four
              lakhs, only 85 percent of that amount, i.e 3 lakhs forty thousands
              will actually be disbursed to you. The margin will demand that
              collateral/security worth this amount be deposited with the bank
              before the student’s receive the four lakhs funds.
            </div>
          </p>
        ),
      },
    ],
  },
];

const LeftSidebar = () => {
  return (
    <div className="left-sidebar" style={{ padding: "24px" }}>
      <div className="index-menu-bar">
        {menuData.map((item, index) => (
          <ul key={index} className="menu">
            <div className="menu-title">
              <a href={`#${item.id}`}>{item.title}</a>
            </div>
            <div className="submenu">
              {item.subMenu.map((subMenuItem, subIndex) => (
                <li key={subIndex}>
                  <a href={`#${subMenuItem.id}`}>
                    <AiOutlineRight /> {subMenuItem.title}
                  </a>
                </li>
              ))}
            </div>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default LeftSidebar;
