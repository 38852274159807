import React from "react";
import { AiOutlineRight } from "react-icons/ai";

const LeftSidebar = () => {
  return (
    <div>
      <div
        className="left-sidebar"
        style={{
          padding: "24px",
        }}>
        {/* create a index menubar */}
        <div className="index-menu-bar">
          <ul className="menu">
            {/* Title */}
            <div className="menu-title">
              <a href="#features">Features & Benefits</a>
            </div>
            {/* sub menu */}
            <div className="submenu">
              <li>
                <a href="#purpose">
                  <AiOutlineRight /> Purpose
                </a>
              </li>
              <li>
                <a href="#expenses">
                  <AiOutlineRight /> Expenses
                </a>
              </li>
              <li>
                <a href="#amount">
                  <AiOutlineRight /> Amount
                </a>
              </li>
              <li>
                <a href="#interest-rates">
                  <AiOutlineRight /> Interest Rates
                </a>
              </li>
              <li>
                <a href="#processing-fee">
                  <AiOutlineRight /> Processing Fee
                </a>
              </li>
              <li>
                <a href="#repayment-tenure">
                  <AiOutlineRight /> Repayment Tenure
                </a>
              </li>
              <li>
                <a href="#security">
                  <AiOutlineRight /> Security
                </a>
              </li>
              <li>
                <a href="#eligible-courses">
                  <AiOutlineRight /> Eligible Courses
                </a>
              </li>
            </div>
          </ul>
          <ul className="menu">
            {/* Title */}
            <div className="menu-title">
              <a href="#documents">Documents Required</a>
            </div>
            {/* sub menu */}
            <div className="submenu">
              <li>
                <a href="#student">
                  <AiOutlineRight /> Student
                </a>
              </li>
              <li>
                <a href="#co-applicant">
                  <AiOutlineRight /> Co-Applicant
                </a>
              </li>
            </div>
          </ul>
          <ul className="menu">
            {/* Title */}
            <div className="menu-title">
              <a href="#loan-features">Loan Features</a>
            </div>
            {/* sub menu */}
            <div className="submenu">
              <li>
                <a href="#loan-features">
                  <AiOutlineRight /> SBI loan features
                </a>
              </li>
            </div>
          </ul>
          <ul className="menu">
            {/* Title */}
            <div className="menu-title">
              <a href="#loan-eligibility">Loan Eligibility</a>
            </div>
          </ul>
          <ul
            className="menu"
            style={{
              marginTop: "10px",
            }}>
            {/* Title */}
            <div className="menu-title">
              <a href="#emi-calculator">Emi Calculator</a>
            </div>
          </ul>
          <ul
            className="menu"
            style={{
              marginTop: "10px",
            }}>
            {/* Title */}
            <div className="menu-title">
              <a href="#FAQs">FAQs</a>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LeftSidebar;
