import React from "react";

const Insurance = () => {
  return (
    <div className="card" id="insurance">
      <h1 className="title">
        Benefits of taking{" "}
        <span
          className=""
          style={{
            color: "#13589D",
            textDecoration: "underline",
          }}>
          Travel Insurance
        </span>{" "}
        through Globaldreamz
      </h1>
      <ul className="list-style-1 mb--20">
        <li>
          <i className="icon-checkbox-circle-fill-solid"></i>Cashless
          Hospitalisation
        </li>
        <li>
          <i className="icon-checkbox-circle-fill-solid"></i>Global Assistance
        </li>
        <li>
          <i className="icon-checkbox-circle-fill-solid"></i>Covers baggage loss
        </li>
        <li>
          <i className="icon-checkbox-circle-fill-solid"></i>Covers Missed
          Flight
        </li>
        <li>
          <i className="icon-checkbox-circle-fill-solid"></i>Travel Insurance
          Cost as low as Rs 50 per Day * starting price
        </li>
      </ul>
    </div>
  );
};

export default Insurance;
