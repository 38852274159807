import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import EventData from "../../data/event/EventData.json";
import InstructorData from "../../data/instructor/InstructorData.json";
import CourseData from "../../data/course/CourseData.json";
import CategoryMba from "../../components/category/CategoryMba";
import ScrollAnimation from "react-animate-on-scroll";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import TestimonialSectionFour from "../../components/testimonial-section/TestimonialSectionFour";
import AboutSix from "../../components/about/AboutSix";
import { slugify } from "../../utils";
import CurriculumTabContent from "../../data/course/CurriculumTabContent.json";

const accordionItems = [
  {
    id: 1,
    title: "What are the benefits of preparing for the GRE?",
    body: "GRE helps students to secure admission in various Master’s and Doctoral courses in various subjects in international colleges and universities. At GlobalDreamz, we help you to prepare effectively and efficiently for the GRE examination. ",
  },
  {
    id: 2,
    title: "Which country/countries have the best postgraduate courses?",
    body: "Top colleges in the US, UK, Australia, Canada and Singapore have the best postgraduate courses for international students. There are some other countries, mostly from Europe, where the GRE score is accepted. ",
  },
  {
    id: 3,
    title: "What are the prerequisites for pursuing the GRE?",
    body: "You need to have a recognized bachelor's degree from a university that is approved by a government body looking after higher education in your country. ",
  },
  {
    id: 4,
    title:
      "Can I pursue Master’s courses and PhD abroad even if I don't possess any work experience?",
    body: "Yes, you can pursue Master’s courses and PhD abroad in countries. All you need to do is clear the GRE test. ",
  },
];

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button">
      {children}
    </button>
  );
};

const CurriculumContent = () => {
  const [activeId, setActiveId] = useState("0");

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
      {CurriculumTabContent.map((accordion, i) => (
        <Accordion.Item
          eventKey={i.toString()}
          key={i}
          onClick={() => toggleActive(i.toString())}
          bsPrefix={`edu-accordion-item ${
            activeId === i.toString() ? "bg-active" : ""
          }`}>
          <div className="edu-accordion-header">
            <CustomToggle eventKey={i.toString()}>
              {accordion.title}
            </CustomToggle>
          </div>
          <Accordion.Body bsPrefix="edu-accordion-body">
            <ul>
              {accordion.content.map((title, index) => (
                <li key={index}>
                  <div className="text">
                    <i className="icon-draft-line"></i>
                    {title}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const GreDreamz = () => {
  const { id } = useParams();
  const eventId = parseInt(1, 10);
  const data = EventData.filter((event) => event.id === eventId);
  const eventItem = data[0];

  const [activeId, setActiveId] = useState("0");
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const courseId = parseInt(1, 10);
  const dataCourse = CourseData.filter((course) => course.id === courseId);
  const courseItem = dataCourse[0];

  const indexOfInstructor = InstructorData.findIndex(function (instructor) {
    return slugify(instructor.name) === slugify(courseItem.instructor);
  });
  const instructor = InstructorData[indexOfInstructor];
  const instructorExcerpt = instructor.details.substring(0, 190) + "...";

  const [contentTab, setContentTab] = useState("overview");
  const handleTab = (content) => {
    if (content === "overview") {
      setContentTab("overview");
    } else if (content === "curriculum") {
      setContentTab("curriculum");
    } else if (content === "instructor") {
      setContentTab("instructor");
    } else if (content === "reviews") {
      setContentTab("reviews");
    }
  };

  return (
    <>
      {/* <SEO title={eventItem.title} /> */}
      <SEO
        title="Best GRE Coaching in pune | GRE Preparation - GlobalDreamz"
        description="Get the best GRE practice tests and study plan for GRE General Test prep. Our GRE study guide will help you prepare for GRE effectively."
        keywords="best gre coaching in pune, online gre training in pune, best gre classes in pune, gre classes, gre preparation in pune"
        canonical="https://globaldreamzedu.com/gre"
      />
      <Layout>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_5.jpg`}
                    alt="gre exam prep students"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="content">
                  <h1 className="title">Why should you prepare for GRE?</h1>
                  <p>
                    The graduate record examination (GRE) helps you to secure
                    admission to Master's and Doctoral programs in institutions
                    in the US, UK, Europe, Singapore and Australia. GRE helps to
                    get admission in six fields currently. They are biology,
                    chemistry, English literature, mathematics, physics and
                    psychology. It will help you to gain further specialization
                    in the subjects you had for your bachelor’s degree. GRE is
                    extremely important for your career growth. Getting
                    admission to the top Master’s and Doctoral courses in a
                    foreign college or university is quite a hectic affair. GRE
                    helps to simplify the problem by developing a standardized
                    test which is also globally acceptable and was created for
                    this purpose only.
                  </p>
                  <p>
                    GRE is conducted by a body called the Educational Testing
                    Service (ETS). There are two types of GRE tests – General
                    and Subject tests. The GRE General Test is accepted by
                    multiple graduate universities and colleges worldwide. It is
                    a standardized exam that is computer-based and has
                    multiple-choice questions whereas the GRE Subject test is a
                    paper-based test.
                  </p>
                  <p>
                    The GRE test is further divided into three sections – Verbal
                    Reasoning, Quantitative Reasoning and Analytical Writing.
                    The scores of the GRE subject tests can either be used by
                    fellowship panels. It could also be used for admissions as
                    it supplements the undergraduate records or relevant
                    qualifications of the student.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <h4>
                  Contact our GRE counsellors at GlobalDreamz to fulfilling your
                  GRE aspirations! What we offer:
                </h4>
                <ul className="list-style-1 mb--20">
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Flexible preparation program tailor-made according to the
                    student's requirements
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Best GRE
                    coaching faculty
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    One-on-one learning environment
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i> Getting
                    access to multiple post-graduate career opportunities abroad
                  </li>
                </ul>
                <p>
                  The preparation for GRE focuses on preparing for all the
                  sections of the GRE - analytical writing, verbal reasoning,
                  and quantitative reasoning. This is the first stage of the
                  exam. The second stage requires the development of the
                  necessary pace, aptitude and strategy development on cracking
                  the exam. Once we see that the student has developed strength
                  in his basic preparation and then we focus on the student by
                  helping them check their preparation in the exam environment
                  by carrying out various simulated tests. It will help to
                  assess whether the student has prepared enough for the exam
                  and also to find out the problem areas. After that, we shall
                  prepare the doubt-clearing sections accordingly.
                </p>

                <CategoryMba />
              </div>

              <div className="col-12">
                <div className="edu-accordion-area mediashark-home-three-accordion accordion-shape-1 edu-section-gap bg-color-white">
                  <div className="container mediashark-animated-shape">
                    <div className="row g-5">
                      <div className="col-lg-6 gallery-wrapper">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="thumbnail">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-01.jpg`}
                                alt="graduate students"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 mt--35 mt_sm--15">
                            <div className="thumbnail">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-02.jpg`}
                                alt="students discussion"
                              />
                            </div>
                            <div className="thumbnail mt--15">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-03.jpg`}
                                alt="students discussion"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <SectionTitle
                          classes="text-start mb--40"
                          slogan="FAQs"
                          title="Get Every General Answers From Here"
                        />
                        <ScrollAnimation
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          className="accordion-style-1"
                          animateOnce={true}>
                          <Accordion
                            bsPrefix="edu-accordion"
                            defaultActiveKey="0"
                            flush>
                            {accordionItems.map((accordion, i) => (
                              <Accordion.Item
                                eventKey={i.toString()}
                                key={i}
                                onClick={() => toggleActive(i.toString())}
                                bsPrefix={`edu-accordion-item ${
                                  activeId === i.toString() ? "active-item" : ""
                                }`}>
                                <div className="edu-accordion-header">
                                  <CustomToggle eventKey={i.toString()}>
                                    {accordion.title}
                                  </CustomToggle>
                                </div>
                                <Accordion.Body bsPrefix="edu-accordion-body">
                                  {accordion.body}
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </ScrollAnimation>
                      </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                      <div className="shape shape-1">
                        <span className="shape-dot"></span>
                      </div>
                      <div className="shape-image shape-image-2">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/shapes/shape-11-04.png`}
                          alt="Shape Thumb"
                        />
                      </div>
                      <div className="shape-image shape-image-3">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/shapes/shape-25-01.png`}
                          alt="Shape Thumb"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="course-details-content">
                  <h3 className="title">Our Services</h3>
                  <ul className="edu-course-tab nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "overview"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Overview"
                        onClick={() => handleTab("overview")}>
                        Course Description
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "curriculum"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Curriculum"
                        onClick={() => handleTab("curriculum")}>
                        Curriculum
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "instructor"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Instructor"
                        onClick={() => handleTab("instructor")}>
                        Instructor
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "reviews"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Reviews"
                        onClick={() => handleTab("reviews")}>
                        Reviews
                      </button>
                    </li>
                  </ul>

                  <div className="tab-content">
                    {contentTab === "overview" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "overview" ? "active" : ""
                        } `}>
                        <div className="course-tab-content">
                          <h6>Overview</h6>
                          <p>
                            The GRE is a standardized test to get admission to
                            the top international postgraduate programs. The
                            Online GRE Coaching exam includes preparation for
                            sections like integrated quantitative reasoning,
                            verbal reasoning, and analytical writing assessment.
                            You get 3 hours and 45 minutes to complete the test.
                          </p>

                          <h6>
                            What is the benefit of the GRE prep course by
                            GlobalDreamz?
                          </h6>
                          <p>
                            {" "}
                            For acing the GRE, you require a strategy for
                            preparation apart from the necessary aptitude. At
                            GlobalDreamz, we coach you in all three sections of
                            the GRE and then we give you mock tests in an exam
                            simulation so that you are well prepared for all the
                            aspects of the exam.
                          </p>

                          <h6>Curriculum</h6>
                          <p>
                            The primary goal of the GRE is to prepare students
                            for a management program. The students will be
                            provided extensive training in the four sections of
                            the GRE:
                          </p>
                          <ul>
                            <li>Analytical writing</li>
                            <li>Quantitative reasoning</li>
                            <li>Verbal reasoning</li>
                          </ul>
                        </div>
                        <br></br>
                      </div>
                    )}

                    {contentTab === "curriculum" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "curriculum" ? "active" : ""
                        } `}>
                        <div className="course-tab-content">
                          <p>
                            The primary goal of the GRE is to prepare students
                            for a management program. The students will be
                            provided extensive training in the four sections of
                            the GRE:
                          </p>

                          <ul>
                            <li>Analytical writing</li>
                            <li>Quantitative reasoning</li>
                            <li>Verbal reasoning</li>
                          </ul>
                        </div>
                      </div>
                    )}

                    {contentTab === "instructor" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "instructor" ? "active" : ""
                        } `}></div>
                    )}

                    {contentTab === "reviews" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "reviews" ? "active" : ""
                        } `}></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AboutSix />
      </Layout>
    </>
  );
};
export default GreDreamz;
