import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import ContactUsForm from "../../components/contact/ContactUsForm";

const ContactUs = () => {
  return (
    <>
      <SEO
        title="Contact Us - GlobalDreamz"
        description="Office No. 2, 2nd Floor, Aditya Centeegra, Above RBL Bank, Dnyaneshwar Paduka Chowk, F C Road, Pune – 411005"
        keywords="Contact Us"
        canonical="https://globaldreamzedu.com/contact"
      />
      <Layout>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--100"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/sh-1.jpg`}
                    alt="Event Thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mediashark-contact-us edu-contact-us-area edu-section-gap bg-color-white">
          <div className="container mediashark-animated-shape">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="pre-title">Need Help?</span>
                  <h3 className="title">Get In Touch With us</h3>
                </div>
              </div>
            </div>
            <div className="row g-5 mt--20">
              <div className="col-lg-6">
                <div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                  <div className="row g-5">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 phone">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-call"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Call Us On</h6>
                            <p>
                              <a href="tel:+919209401677">+91 92094 01677</a>
                            </p>
                            <p>
                              <a href="tel:+919209401688">+91 92094 01688</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 email">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-mail-open-line"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Email Us</h6>
                            <p>
                              <a href="mailto:info@globaldreamzedu.com">
                                info@globaldreamzedu.com
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="contact-address-card-1 location">
                        <div className="inner">
                          <div className="icon gd-icon-bg">
                            <i className="icon-map-pin-line gd-red"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Our Location</h6>
                            <p>
                              Office No. 2, 2nd Floor, Aditya Centeegra, Above
                              RBL Bank, Dnyaneshwar Paduka Chowk, F C
                              Road, Pune – 411005
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <ContactUsForm formStyle="rnt-contact-form rwt-dynamic-form row" />
              </div>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
              <div className="shape-image shape-image-1">
                <img
                  src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`}
                  alt="Shape Thumb"
                />
              </div>
              <div className="shape-image shape-image-2">
                <img
                  src={`${process.env.PUBLIC_URL}/images/shapes/shape-02-08.png`}
                  alt="Shape Thumb"
                />
              </div>
              <div className="shape-image shape-image-3">
                <img
                  src={`${process.env.PUBLIC_URL}/images/shapes/shape-15.png`}
                  alt="Shape Thumb"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="edu-contact-map-area edu-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="google-map alignwide">
                  <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d236.44127235757279!2d73.8412644691564!3d18.526174402846287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bfa70b82c5a1%3A0xcbb9c65eeb5e6516!2sAditya%20Centeegra!5e0!3m2!1sen!2sin!4v1661914170221!5m2!1sen!2sin"
                    height="500"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default ContactUs;
