import React from "react";
import { AiOutlineRight } from "react-icons/ai";

export const menuData = [
  {
    title: "Benefits for Students",
    id: "benefits-students",
    subMenu: [
      {
        id: "key-benefits-students",
        title: "Key Features for Students",
        content: (
          <p>
            <b>
              Some of the key benefits of the HDFC Credila education loan are:
            </b>
            <ol className="expense-list">
              <li>
                Approval before Admission: HDFC Credila allows the students to
                get HDFC Credila overseas education loans or loans for Indian
                institutes pre-approved even before applying to Universities.
                This factor helps students know about the potential universities
                that they have got funding for.
              </li>{" "}
              <li>
                Sources of Funds for USA Visa and I-20: HDFC Credila Education
                Loan approval letter showcase assured fund sources for getting
                I-20 from universities in the USA.
              </li>{" "}
              <li>
                Reduce your financial worry: HDFC Credila education loan covers
                the total cost of the student education along with their living
                expenses, preventing them from seeking other sources of funding
              </li>{" "}
              <li>
                Focuses on education loan solutions: HDFC Credila focuses only
                on HDFC Credila education loan and HDFC Credila overseas
                education loan, making it the first choice of students who are
                looking to avail financial help.
              </li>{" "}
              <li>
                Easy to apply: Students need to apply for HDFC Credila education
                loan online without any cost. This makes the process safe,
                secure, and easy to prosecute.
              </li>
            </ol>
          </p>
        ),
      },
    ],
  },

  {
    title: "Benefits for Parents",
    id: "benefits-parents",
    subMenu: [
      {
        id: "key-benefits-parents",
        title: "Key Features for Parents",
        content: (
          <p>
            <ol className="expense-list">
              <li>
                Tax Benefits: HDFC Credila education loan provides income tax
                rebates under Section 80E of Income Tax Act of India
              </li>{" "}
              <li>
                100% of Educational Expenses Covered with HDFC Credila education
                loan: No margin money is required.
              </li>{" "}
              <li>
                Convenience: Applicants can avail HDFC Credila education loan
                and HDFC Credila overseas education loan at their door-step and
                do not require to visit bank branches multiple times as the
                company offers an advantage of calling an HDFC Credila
                representative any time as per your convenience.
              </li>{" "}
              <li>
                Flexibility on collateral requirements: HDFC Credila education
                loan features flexible options in some scenarios like no
                collateral requirement depending on the credit history of the
                applicant.
              </li>
            </ol>
          </p>
        ),
      },
    ],
  },
  {
    title: "Documents Required",
    id: "docs-required",
    subMenu: [
      {
        id: "list",
        title: "Complete List",
        content: (
          <p>
            <b>
              Some of the documents mandatory for applying for HDFC Credila
              education loan are:
            </b>
            <ol className="expense-list">
              <li>
                {" "}
                Completely filled application form along with photographs and
                signature{" "}
              </li>
              <li>ID Proof </li>
              <li>Proof of Address </li>
              <li>
                Academic documents such as mark sheet, certificates of 12th
                exam, certificate of Subsequent Years of Education e.g. BE, B.
                Com, and BSc. Marksheet of any entrance exam taken like CAT and
                CET, GRE, GMAT, TOFEL, and IELTS.Proof of Admission
              </li>{" "}
              <li>Bank Statements </li>
              <li>Income proof of co-applicant </li>
              <li>Collateral security proof/documents</li>
            </ol>
          </p>
        ),
      },
    ],
  },

  {
    title: "Loan Eligibility",
    id: "loan-eligibility",
    subMenu: [
      {
        id: "eligibility-parameters",
        title: "Criteria",
        content: (
          <p>
            It is important for the applicant to be an Indian student to pursue
            higher studies either in India or abroad. They must also have
            successfully passed higher secondary school with satisfactory grades
            to avail HDFC Credila education loan . HDFC credila education loan
            looks at scores in Competitive exams like GRE/GMAT before
            ascertaining the eligibility of the student.
            <br />
            <br />
            HDFC Credila education loan as well looks at Co-signor profile where
            the basic interest coverage is checked by the loan provider. In case
            the parent is about to retire they ask for a second guarantor.
            <br />
            <br />
            They also look at Collateral property as an additional measure of
            security from the guarantor
          </p>
        ),
      },
    ],
  },
  {
    title: "Loan Interest Rate",
    id: "interest-rate",
    subMenu: [
      {
        id: "interest-rate-content",
        title: "Interest Rate",
        content: (
          <p>
            HDFC Credila education loan comes with attractive interest rates.
            They also depend on a host of eligibility criteria which might
            include but are not limited to the applicant’s borrowing history,
            credit worthiness, and other factors that reflect their financial
            capability.
          </p>
        ),
      },
    ],
  },
  {
    title: "Loan FAQs:",
    id: "bob-loan-faqs",
    subMenu: [
      {
        id: "bob-loan-faqs-intro",
        title: "Most Common FAQs",
        content: (
          <p>
            <div>
              <b>
                {" "}
                1. Does an applicant need a co-borrower to apply for an HDFC
                Credila education loan?
              </b>{" "}
              <br />
              It is important to have a co-borrower for both HDFC Credila
              overseas education loan and HDFC Credila education loan in India.
              It is also important that Co-borrower is an Indian citizen who
              would be able to pay interest in the moratorium period. If the
              student is unable to repay the loan, it becomes the responsibility
              of the co-borrower to repay the entire loan in installments or
              provide the collateral security.
            </div>{" "}
            <br />
            <div>
              <b>
                {" "}
                2. When is the right time to apply for an education loan -
                before admission confirmation or after?
              </b>{" "}
              <br />
              Generally, students are encouraged to apply for the HDFC Credila
              education loan earlier so that they can get the added advantage of
              having an approved education loan at the time of consideration of
              their admission application as it can improve the chances of
              getting the admission.
            </div>{" "}
            <br />
            <div>
              <b>
                {" "}
                3. Can the students apply for an HDFC Credila education loan
                before admission is guaranteed?
              </b>{" "}
              <br />
              HDFC Credila offers loan sanction even before admissions.
            </div>{" "}
            <br />
          </p>
        ),
      },
    ],
  },
];

const LeftSidebar = () => {
  return (
    <div className="left-sidebar" style={{ padding: "24px" }}>
      <div className="index-menu-bar">
        {menuData.map((item, index) => (
          <ul key={index} className="menu">
            <div className="menu-title">
              <a href={`#${item.id}`}>{item.title}</a>
            </div>
            <div className="submenu">
              {item.subMenu.map((subMenuItem, subIndex) => (
                <li key={subIndex}>
                  <a href={`#${subMenuItem.id}`}>
                    <AiOutlineRight /> {subMenuItem.title}
                  </a>
                </li>
              ))}
            </div>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default LeftSidebar;
