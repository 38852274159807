import React from "react";
import { AiOutlineRight } from "react-icons/ai";

export const menuData = [
  {
    title: "Documentation Required",
    id: "docs-required",
    subMenu: [
      {
        id: "imp-docs",
        title: "Important Documents",
        content: (
          <p>
            <b>For applicants:</b>
            <ol className="expense-list">
              <li>Proof of admission</li>
              <li>Fee structure</li>
              <li>Age proof</li>
              <li>Identity proof</li>
              <li>Address proof</li>
              <li>Signature verification</li>
              <li>Colour photos – 2 numbers </li>
            </ol>
            <b>For cp-applicants:</b>
            <ol className="expense-list">
              <li>Relationship proof with the applicant</li>
              <li>Income proof</li>
              <li>Collateral documents</li>
              <li>Bank statement – 8 months</li>
              <li>Age proof</li>
              <li>Identity proof</li>
              <li>Address proof</li>
              <li>Signature verification</li>
              <li>Colour photos – 2 numbers</li>
            </ol>
          </p>
        ),
      },
    ],
  },

  {
    title: "How to apply",
    id: "how-to-apply",
    subMenu: [
      {
        id: "procedure",
        title: "Procedure",
        content: (
          <p>
            <ol className="expense-list">
              <li> Visit Avanse Education Loan Section on the eduloans.org</li>
              <li>
                Answer a few basic questions about the place you plan to study
                and how much loan would you like to apply for
              </li>
              <li>
                Enter your name as per your PAN Card, Date of Birth, Gender,
                Email ID, and contact details
              </li>
              <li>
                Select your co-borrower and enter their employment details like
                occupation and monthly income
              </li>
              <li>
                Lastly, tick the box for terms and conditions and click on ‘See
                Offers’.
              </li>
              <br />A page for Avanse Education Loan will occur wherein you can
              get details about the tentative EMI amount as per the loan amount.
              If you are satisfied with the details of interest rates and you
              can click on ‘Apply Now’. Your application will be accepted and
              will be processed further by Eduloan’s Avanse Education Loan
              Department.
            </ol>
          </p>
        ),
      },
    ],
  },
  {
    title: "Loan Features",
    id: "loan-features",
    subMenu: [
      {
        id: "Loan-Amount",
        title: "Loan Amount",
        content: (
          <p>
            Avanse provides a secured loan up to any amount with deviation for
            preferential list universities and up to 45 lakhs for an unsecured
            loan.
          </p>
        ),
      },
      {
        id: "percentage-of-loan-against-collateral-provided",
        title: "Percentage of Loan Against Collateral Provided",
        content: (
          <p>
            The bank provides a loan amount up to 70 percent of Property’s
            Realizable Value loan up to 100 percent of FD value.
          </p>
        ),
      },
      {
        id: "collateral-property",
        title: "Collateral Property",
        content: (
          <p>
            To get secured Avanse education loan, the students need to keep
            their property (non-agriculture land) of FD and LIC for any bank as
            collateral. For an unsecured loan, Avanse requires academic details
            of the student along with the details of competitive exam
            performance and university and courses applied to. The company will
            also need details about the co-signer’s financial such as IT returns
            and CIBIL score.
          </p>
        ),
      },
      {
        id: "student-profile",
        title: "Student's Profile",
        content: (
          <p>
            To provide an Avanse education loan to any applicant, the bank will
            check the academic background of the student (no. of KTs throughout
            his last education) along with the course and country of the
            institution he is planning to study
          </p>
        ),
      },
      {
        id: "interest-rate",
        title: "Interest Rate",
        content: (
          <p>
            Avanse charges negotiable loan interest between 11 percent to 14.50
            percent for both secured and unsecured loans.
          </p>
        ),
      },
      {
        id: "interest-paying-during-moratorium-period",
        title: "Interest Paying During Moratorium Period",
        content: (
          <p>
            The bank also provides an option of paying the interest on Avanse
            overseas education loan during the moratorium period or along with
            EMIs.
          </p>
        ),
      },
    ],
  },

  {
    title: "Loan Eligibility",
    id: "loan-eligibility",
    subMenu: [
      {
        id: "eligibility-parameters",
        title: "Criteria",
        content: (
          <p>
            <ul className="expense-list">
              <li>
                An applicant should be above the age of 18 years to apply for an
                Avanse education loan
              </li>
              <li>The applicant should be an Indian citizen</li>
              <li>
                The applicant should have secured admission in a recognized
                educational institute
              </li>
              <li>
                An applicant should have an earning co-borrower to co-sign the
                loan. The co-borrower can be a parent, relative, legal guardian,
                or sibling of the student.
              </li>{" "}
              <li>
                The co-borrower should have an Indian bank account with cheque
                writing facilities.
              </li>
            </ul>
          </p>
        ),
      },
    ],
  },
  {
    title: "Loan Interest Rate",
    id: "interest-rate",
    subMenu: [
      {
        id: "interest-rate-content",
        title: "Interest Rate",
        content: (
          <p>
            HDFC Credila education loan comes with attractive interest rates.
            They also depend on a host of eligibility criteria which might
            include but are not limited to the applicant’s borrowing history,
            credit worthiness, and other factors that reflect their financial
            capability.
          </p>
        ),
      },
    ],
  },
  {
    title: "Emi Calculator",
    id: "Emi-Calculator",
    subMenu: [
      {
        id: "interest-rate-content",
        title: "Link to EMI Calculator",
        content: (
          <p>
            <a href="https://www.avanse.com/education-loan-calculators/emi-calculator">
              {" "}
              Click here to calculate your EMI
            </a>
          </p>
        ),
      },
    ],
  },
  {
    title: "Loan FAQs:",
    id: "bob-loan-faqs",
    subMenu: [
      {
        id: "bob-loan-faqs-intro",
        title: "Most Common FAQs",
        content: (
          <p>
            <div>
              <b> 1. How much loan amount can Avanse lend?</b> <br />
              Avanse does not have any cap on the Avanse education loan amount.
              It offers up to 100% coverage of the loan amount which also
              includes the living expenses during the period of study. But these
              expenses should not exceed 20% of the tuition fees. The company
              also offers other additional costs like caution deposit and
              building fund but they should not be more than 10 percent of the
              tuition fees.
            </div>{" "}
            <br />
            <div>
              <b>
                {" "}
                2.What is the holiday or moratorium period offered by Avanse?
              </b>{" "}
              <br />
              Students applying for Avanse education loan or Avanse overseas
              education loan can get relief for up to six months after the
              completion of the course or three months after getting a job
              (whichever comes first).
            </div>{" "}
            <br />
            <div>
              <b>
                {" "}
                3. What are the minimum and maximum repayment periods offered by
                Avanse?
              </b>{" "}
              <br />
              The minimum repayment period for the Avanse education loan is 12
              months and the maximum repayment period is 120 months in addition
              to the moratorium period offered.
            </div>{" "}
            <br />
            <div>
              <b>
                {" "}
                4. Will the rate of interest change during the loan tenure?
              </b>{" "}
              <br />
              Avanse offers loans at floating rates therefore the interest rate
              may change during the loan tenure.
            </div>{" "}
            <div>
              <b>
                {" "}
                5. Is it possible to reapply in case the loan application had
                been rejected in the past?
              </b>{" "}
              <br />
              Avanse allows the reapplication of a loan even after rejection but
              it is always recommended to double-check the eligibility criteria
              before reapplying for your Avanse education loan.
            </div>{" "}
          </p>
        ),
      },
    ],
  },
];

const LeftSidebar = () => {
  return (
    <div className="left-sidebar" style={{ padding: "24px" }}>
      <div className="index-menu-bar">
        {menuData.map((item, index) => (
          <ul key={index} className="menu">
            <div className="menu-title">
              <a href={`#${item.id}`}>{item.title}</a>
            </div>
            <div className="submenu">
              {item.subMenu.map((subMenuItem, subIndex) => (
                <li key={subIndex}>
                  <a href={`#${subMenuItem.id}`}>
                    <AiOutlineRight /> {subMenuItem.title}
                  </a>
                </li>
              ))}
            </div>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default LeftSidebar;
