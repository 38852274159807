import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';

const EventGalleryItem = ({ data }) => {
    const [toggler, setToggler] = useState(false);
    return (
        <div className="edu-gallery-grid-item grid-metro-item cat--1 cat--3">
            <div className="edu-gallery-grid">
                <div className="inner">
                    <div className="thumbnail">
                        <img src={`${process.env.PUBLIC_URL}/images/gallery/event-gallery-images/${ data.thumb }`} alt={
                            data?.alttag ? `${ data.alttag }` : 'Gallery Thumb'
                        } />
                    </div>
                </div>
                
                <button className="zoom-icon" onClick={ () => setToggler(!toggler) }><i className="icon-zoom-in-line"></i></button>
                <FsLightbox toggler={ toggler } sources={[`${process.env.PUBLIC_URL}/images/gallery/event-gallery-images/${ data.thumb }`]} />

                <div className="hover-action">
                    <div className="hover-content">
                        <div className="hover-text">
                            <h6 className="title">{ data.title }</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}
export default EventGalleryItem;