import React from "react";
import SEO from "../../../common/SEO";
import HeaderTwo from "../../../common/header/HeaderTwo";
import LeftSidebar, { menuData } from "./LeftSidebar";

const ICICI = () => {
  return (
    <>
      <SEO
        title="Study abroad consultants in India | GlobalDreamz"
        description="Looking for the best study abroad consultants in India? GlobalDreamz leading study abroad consultants in India."
        keywords="study abroad consultants in india, abroad study visa consultants, higher education consultant in pune, best overseas education consultant in pune, GlobalDreamz"
        canonical="https://globaldreamzedu.com/"
      />
      <HeaderTwo styles="header-transparent header-style-2" />

      {/* CONTAINER */}

      <div className="eduloans-container">
        {/* TITLE */}
        <LeftSidebar />

        <div className="content">
          <div className="title bg-primary-color">
            <h2>
              <span>ICICI Bank</span>
            </h2>
          </div>

          <div
            className="description"
            style={{
              margin: "20px 10px",
            }}>
            <p>
              ICICI Bank has international goodwill due to its aim to provide
              financial assistance to millions of students who aim to pursue
              higher studies in India and abroad. The bank also guides these
              students through the process of applying for an{" "}
              <b>ICICI Bank education loan</b>. The bank also provides the
              availability of <b>ICICI Bank overseas education loans</b> for
              students who wish to fly high across international borders.
            </p>
          </div>
          {/* O */}
          {menuData?.map((category, index) => (
            <div className="features" id={category.id} key={index}>
              <h3>{category.title}</h3>

              {category?.subMenu?.map((section) => (
                <div className="tile" key={section.id} id={section.id}>
                  <li>{section.title}</li>
                  {section.content}
                </div>
              ))}
            </div>
          ))}
          {/* <ISB />
          <PIAS /> */}
        </div>
      </div>
    </>
  );
};

export default ICICI;
