import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import EventData from "../../data/event/EventData.json";
import InstructorData from "../../data/instructor/InstructorData.json";
import CourseData from "../../data/course/CourseData.json";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import AboutSix from "../../components/about/AboutSix";
import { slugify } from "../../utils";
import CurriculumTabContent from "../../data/course/CurriculumTabContent.json";

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button">
      {children}
    </button>
  );
};

const CurriculumContent = () => {
  const [activeId, setActiveId] = useState("0");

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
      {CurriculumTabContent.map((accordion, i) => (
        <Accordion.Item
          eventKey={i.toString()}
          key={i}
          onClick={() => toggleActive(i.toString())}
          bsPrefix={`edu-accordion-item ${
            activeId === i.toString() ? "bg-active" : ""
          }`}>
          <div className="edu-accordion-header">
            <CustomToggle eventKey={i.toString()}>
              {accordion.title}
            </CustomToggle>
          </div>
          <Accordion.Body bsPrefix="edu-accordion-body">
            <ul>
              {accordion.content.map((title, index) => (
                <li key={index}>
                  <div className="text">
                    <i className="icon-draft-line"></i>
                    {title}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const StudyInCanadaDreamz = () => {
  const { id } = useParams();
  const eventId = parseInt(1, 10);
  const data = EventData.filter((event) => event.id === eventId);
  const eventItem = data[0];

  const [activeId, setActiveId] = useState("0");
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const courseId = parseInt(1, 10);
  const dataCourse = CourseData.filter((course) => course.id === courseId);
  const courseItem = dataCourse[0];

  const indexOfInstructor = InstructorData.findIndex(function (instructor) {
    return slugify(instructor.name) === slugify(courseItem.instructor);
  });
  const instructor = InstructorData[indexOfInstructor];
  const instructorExcerpt = instructor.details.substring(0, 190) + "...";

  const [contentTab, setContentTab] = useState("overview");
  const handleTab = (content) => {
    if (content === "overview") {
      setContentTab("overview");
    } else if (content === "curriculum") {
      setContentTab("curriculum");
    } else if (content === "instructor") {
      setContentTab("instructor");
    } else if (content === "reviews") {
      setContentTab("reviews");
    }
  };

  return (
    <>
      {/* <SEO title={eventItem.title} /> */}
      <SEO
        title="Study in Canada | Colleges and Eligibility - GlobalDreamz"
        description="Want to study in Canada? Seek Collegedunia's expert advice on top universities, programs, visa, scholarships, and more."
        keywords="Study in Canada, Study abroad in Canada, Canadian universities and colleges, Canadian higher education, Education in Canada, International students in Canada, Canada study programs"
        canonical="https://globaldreamzedu.com/study-in-canada"
      />
      <Layout>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_11.jpg`}
                    alt="study canada students"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h3 className="title">Why should you Study in Canada?</h3>
                  <p>
                    Canada is a popular destination for education and attracts
                    students from across the world. The globally recognised
                    degree, affordable tuition fees, and high standard of living
                    are some of the main factors that make Canada a preferred
                    destination. Also, the abundance of employment opportunities
                    is another point that plays a part here.
                  </p>
                  <p>
                    The high level of education is one of the principal reasons
                    why students choose to study in Canada. A degree from a
                    Canadian university serves as a milestone of integrity and
                    brilliance for a student. The QS World University Rankings
                    2019 list 26 Canadian universities, while The World
                    University Rankings 2019 list 27.
                  </p>
                  <p>
                    One of the prime reasons that Canadian colleges and
                    universities are distinct is their emphasis on research and
                    development. The Canadian government provides significant
                    funding for research in the fields of telecommunications,
                    medicine, environmental science, technology, and agriculture
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <h4>
                  What Can You Do to Get Into Your Dream College or University?
                </h4>

                <p>
                  Choosing the right career, obtaining the necessary visas, and
                  assembling the appropriate paperwork for the admission
                  procedure are all questions that cross students' minds when
                  selecting an overseas study program. Previously, the
                  consciousness of streams and sub-streams was confined, and
                  students had to depend on a small amount of information
                  published in a newspaper or on the decision made for them by
                  their parents.
                </p>

                <p>
                  However, with time, there is a wealth of information available
                  for international studies, thanks to the internet. So, from
                  this vast array of knowledge, you must select the most
                  appropriate one for you and that is where the need for a
                  career counsellor comes in.
                </p>
                <p>
                  Here are some of the ways in which our international education
                  consultants can assist you:
                </p>
              </div>

              <div className="col-12">
                <div className="course-details-content">
                  <h5>Helps Choose the Right Course</h5>
                  <p>
                    You must pick the right course based on your capabilities
                    and job expectations. The career counsellor assists students
                    in selecting the appropriate career choice based on their
                    profile. This makes a major contribution to the student's
                    achievement in life.
                  </p>

                  <h5>Choosing the Ideal University</h5>
                  <p>
                    Before choosing a university, it is critical to understand
                    the university's vision and focus. It should be relevant to
                    your interests and goals. Our councillors will work with you
                    understanding your ambition and career path, and will assist
                    you in selecting the best suited university for you from a
                    huge list of educational institutions in a particular
                    country.
                  </p>

                  <h5>Assistance in Visa Application</h5>
                  <p>
                    Usually, educational consultants have good relationships
                    with visa authorities. They make sure that the student's
                    student visa is approved and also prepare the students to
                    crack the visa interviews.
                  </p>

                  <h5>
                    How to Choose A Reliable Study Abroad Education Consultant?
                  </h5>
                  <p>
                    Career counselling plays a major role in your decision to
                    study abroad. It helps you find the institute and manage
                    other aspects of this process. However, before choosing one,
                    you need to keep a few pointers in mind. There are –
                  </p>
                  <ul className="list-style-1 mb--20">
                    <li>
                      <i className="icon-checkbox-circle-fill-solid"></i>
                      The authenticity of the organisation
                    </li>
                    <li>
                      <i className="icon-checkbox-circle-fill-solid"></i>
                      The reputation of the institute
                    </li>
                    <li>
                      <i className="icon-checkbox-circle-fill-solid"></i>
                      Their success rate
                    </li>
                    <li>
                      <i className="icon-checkbox-circle-fill-solid"></i> The
                      fee they are asking for
                    </li>
                    <li>
                      <i className="icon-checkbox-circle-fill-solid"></i> What
                      are their services and how they can help you
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12">
                <div className="course-details-content">
                  <h3 className="title">Why Choose Us?</h3>

                  <p>
                    At GlobalDreamz, competent and experienced counsellors are
                    available to help students identify their professional
                    goals, help them make more informed academic selections, and
                    provide one-on-one counselling while they select the courses
                    that are most appropriate for their needs.
                    <br></br>
                    <br></br>
                    We also set up in-person talks with institution personnel
                    and provide thorough, unbiased information on all available
                    study options.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <TestimonialSectionFour /> */}
        </div>
        <AboutSix />
      </Layout>
    </>
  );
};
export default StudyInCanadaDreamz;
