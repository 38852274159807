import React, { useState, useEffect } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PaginationOne from "../../components/pagination/PaginationOne";
import PostStandard from "../../components/post/PostStandard";
import SearchOne from "../../components/widgets/blog/SearchOne";
import CategoryOne from "../../components/widgets/blog/CategoryOne";
import LatestPostOne from "../../components/widgets/blog/LatestPostOne";
import AboutOne from "../../components/widgets/blog/AboutOne";
import TagOne from "../../components/widgets/blog/TagOne";
import InstagramOne from "../../components/widgets/blog/InstagramOne";
import axios from "axios";
import PostData from "../../data/blog/PostData.json";
const BlogStandard = () => {
  const baseURL =
    "https://public-api.wordpress.com/rest/v1.1/sites/globaldreamzedu.wordpress.com/posts/";
  const [data, setData] = useState({ isLoading: false, postData: [] });
  //removed placed holderdata, comment this line to get placeholder blogs
  PostData = [];

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }

  useEffect(() => {
    axios.get(`${baseURL}`).then((response) => {
      if (response) {
        let blogPosts = response.data.posts;
        // console.log(blogPosts);
        if (Array.isArray(blogPosts)) {
          PostData = [];
          blogPosts.forEach((element) => {
            let title_img;
            for (let key in element.attachments) {
              title_img = element.attachments[key].URL;
            }
            PostData.push({
              id: element.ID,
              title: element.title,
              image: title_img,
              date: formatDate(new Date(element.modified)),
              shortDate: "06 Nov",
              author: ["John Kite"],
              readingTime: "3 Min Read",
              view: "232 View",
              comment: "02 Comments",
              categories: ["Business", "Education"],
              tags: ["Design", "Course", "UI/UX Design"],
              details: element.excerpt,
              content: element.content,
            });
          });
        } else {
          PostData = [];
          PostData.push({
            id: 1,
            title: "Introduction to Javascript for the Beginners",
            image: "post-01.jpg",
            date: "06 Nov, 2022",
            shortDate: "06 Nov",
            author: ["John Kite"],
            readingTime: "3 Min Read",
            view: "232 View",
            comment: "02 Comments",
            categories: ["Business", "Education"],
            tags: ["Design", "Course", "UI/UX Design"],
            details:
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          });
        }
        setData({ isLoading: true, postData: PostData });
      } else {
        //not fetched
      }
    });
  }, []);

  return (
    <>
      <SEO title="Blogs" />
      <Layout>
        <BreadcrumbOne
          title="Blogs"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Blogs"
        />
        <div className="edu-elements-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-8 mediashark-blog-standard-wrapper">
                {data.postData.slice(0, 4).map((item) => (
                  <div
                    className="edu-blog blog-type-1 radius-small mediashark-post-standard mt--40"
                    key={item.id}
                  >
                    <PostStandard data={item} />
                  </div>
                ))}
                <div className="row">
                  <div className="col-lg-12 mt--60">
                    <PaginationOne />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <aside className="edu-blog-sidebar">
                  {/* <SearchOne /> */}
                  <CategoryOne extraClass="mt--40" />
                  <LatestPostOne data={data.postData} extraClass="mt--40" />
                  {/* <AboutOne extraClass="mt--40" /> */}
                  <TagOne extraClass="mt--40" />
                  {/* <InstagramOne extraClass="mt--40" /> */}
                </aside>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogStandard;
