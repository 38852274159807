import axios from "axios";
import React, { useState } from "react";

const Success = () => {
  return (
    <p className="success-message">
      Thanks for your query. We will contact you soon.
    </p>
  );
};

const Error = () => {
  return (
    <p className="error-message">
      Something went wrong. Please try again later. You can also contact us on
      the details below.
    </p>
  );
};

const Form = () => {
  const [result, setResult] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [form, setForm] = useState({
    page: "Forex",
    Name: "",
    Email: "",
    PhoneNo: "",
    intake: "",
    CountryOfStudy: "",
  });
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isAuthorizationChecked, setIsAuthorizationChecked] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNo) => {
    const phoneRegex = /^[0-9]{10,}$/;
    return phoneRegex.test(phoneNo);
  };

  function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    window.gtag("event", "conversion", {
      send_to: "AW-11282017587/5s-wCMyK3MoYELPa14Mq",
      event_callback: callback,
    });
    return false;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValidEmail = validateEmail(form.Email);
    const isValidPhoneNo = validatePhoneNumber(form.PhoneNo);

    if (!isValidEmail) {
      alert("Invalid email format");
      return;
    }

    if (!isValidPhoneNo) {
      alert("Invalid phone number format");
      return;
    }

    if (!isTermsChecked) {
      alert("Please agree to the Terms and Conditions");
      return;
    }

    if (!isAuthorizationChecked) {
      console.log("Please authorize us to send updates");
      return;
    }

    // All validations passed, send the form data
    axios
      .post("https://api.globaldreamzedu.com/contact", form)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setIsSuccess(true);
          setResult(true);
          setForm({
            Name: "",
            Email: "",
            PhoneNo: "",
            CountryOfStudy: "",
            intake: "",
          });
          setTimeout(() => {
            setResult(false);
          }, 5000);
        }
      })
      .catch((error) => {
        setIsSuccess(false);
        setResult(true);
        setForm({
          Name: "",
          Email: "",
          PhoneNo: "",
          CountryOfStudy: "",
          intake: "",
        });
        setTimeout(() => {
          setResult(false);
        }, 5000);
        console.log(error);
      });

    gtag_report_conversion();
  };
  return (
    <form
      className="rnt-contact-form rwt-dynamic-form row"
      onSubmit={handleSubmit}>
      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            name="fullname"
            onChange={(e) => {
              setForm({ ...form, Name: e.target.value });
            }}
            style={{
              height: "30px",
            }}
            value={form?.Name}
            placeholder="Name*"
            required
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="email"
            className="form-control form-control-lg"
            name="email"
            onChange={(e) => {
              setForm({ ...form, Email: e.target.value });
            }}
            style={{
              height: "30px",
            }}
            value={form?.Email}
            placeholder="Email*"
            required
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            name="phone"
            placeholder="Phone*"
            onChange={(e) => {
              setForm({ ...form, PhoneNo: e.target.value });
            }}
            style={{
              height: "30px",
            }}
            value={form?.PhoneNo}
            required
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            name="intake"
            placeholder="Intake *"
            onChange={(e) => {
              setForm({ ...form, intake: e.target.value });
            }}
            style={{
              height: "30px",
            }}
            value={form?.intake}
            required
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            name="CountryOfStudy"
            placeholder="Country of Study *"
            onChange={(e) => {
              setForm({ ...form, CountryOfStudy: e.target.value });
            }}
            style={{
              height: "30px",
            }}
            value={form?.CountryOfStudy}
            required
          />
        </div>
      </div>

      {/* terms and conditions */}

      <div className="col-lg-12">
        <div className="form-group">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="termsCheck"
              value=""
              checked={isTermsChecked}
              onChange={(e) => setIsTermsChecked(e.target.checked)}
              required
            />
            <label className="form-check-label" htmlFor="termsCheck">
              I agree to the{" "}
              <a
                href="https://globaldreamzedu.com/privacy-policy/"
                target="_blank"
                style={{
                  color: "#5c30fc",
                }}
                rel="noreferrer">
                Terms and Conditions
              </a>
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="authorizationCheck"
              value=""
              checked={isAuthorizationChecked}
              onChange={(e) => setIsAuthorizationChecked(e.target.checked)}
              required
            />
            <label className="form-check-label" htmlFor="authorizationCheck">
              I authorize GlobalDreamz Edutech Pvt Ltd. & Entities to send me
              regular updates via Phone Calls, WhatsApp, SMS, Email, or on
              Postal addresses
            </label>
          </div>
        </div>
      </div>

      <div className="col-lg-12">
        <button className="rn-btn edu-btn w-100" type="submit">
          <span>Submit Now</span>
          <i className="icon-arrow-right-line-right"></i>
        </button>
      </div>
      {result ? (
        <div className="form-group">{isSuccess ? <Success /> : <Error />}</div>
      ) : null}
    </form>
  );
};

export default Form;
