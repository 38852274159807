import React from "react";

const FAQs = () => {
  return (
    <div className="features" id="FAQs">
      <h3>SBI Overseas Education Loan FAQs</h3>
      <div
        className="tile"
        id="emi-calculator"
        style={{
          margin: 0,
        }}>
        {/* #1 */}

        <li>
          {" "}
          What documents should be submitted to avail the{" "}
          <b
            style={{
              textDecoration: "underline",
            }}>
            SBI overseas education loan
          </b>{" "}
          subsidy ?
        </li>
        <ul
          className="document-list"
          style={{
            listStyleType: "none",
          }}>
          <li>
            Along with all the necessary loan documents, the applicant needs to
            submit an income certificate issued by the Income Certifying
            Authority designated by the State Government.
          </li>
        </ul>

        {/* #2 */}
        <li>
          {" "}
          Can the applicant’s spouse sign up as a co-applicant for{" "}
          <b
            style={{
              textDecoration: "underline",
            }}>
            State Bank of India education loan
          </b>
          ?
        </li>
        <ul
          className="document-list"
          style={{
            listStyleType: "none",
          }}>
          <li>
            Married applicants can designate their spouse as co-applicant.
          </li>
        </ul>

        {/* #3 */}
        <li>
          {" "}
          How the{" "}
          <b
            style={{
              textDecoration: "underline",
            }}>
            State Bank of India education loan
          </b>{" "}
          is disbursed?
        </li>
        <ul
          className="document-list"
          style={{
            listStyleType: "none",
          }}>
          <li>
            The loan amount is disbursed directly to the educational institution
            or the vendor whenever required.
          </li>
        </ul>

        {/* #4 */}
        <li>
          {" "}
          What educational documents need to be submitted during an{" "}
          <b
            style={{
              textDecoration: "underline",
            }}>
            SBI overseas education loan
          </b>{" "}
          application?
        </li>
        <ul
          className="document-list"
          style={{
            listStyleType: "none",
          }}>
          <li>
            The applicant is asked to submit the HSC and SSLC certificates along
            with the degree mark sheet and toefl and gre score, whichever falls
            in the eligibility criteria. In case the student has given GRE/
            TOEFL we can look to provide the same as well.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FAQs;
