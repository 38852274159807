import React from "react";

const EligibleCourse = () => {
  return (
    <div className="tile" id="eligible-courses">
      {/* create a dot */}
      <li> Eligible Courses</li>
      <ul className="expense-list">
        <li>SBI Student Education Loan</li>
        <li>
          For studies in India, undergraduate and post-graduate technical
          courses (degree and diploma) conducted by colleges that are approved
          by UGC, IMC, AICTE, and the government of India are covered. Also,
          teacher training and other diploma courses like pilot training and
          shipping as approved by the concerned regulatory authority are covered
          under the loan. For studies abroad, job-oriented professional graduate
          and post-graduate courses like MCA, MBA, MS, courses conducted by CIMA
          – London, CPA – USA are approved for the loan.
        </li>
        <li>
          Regular full-time degree and courses for which the student is granted
          admission through merit-based admission process or entrance test are
          covered under the loan
        </li>
        <li>
          Full-time executive management courses like PGPX are approved for the
          loan.
        </li>
        <li>
          Skill development courses and vocational courses of duration between
          two months to 3 years run by a government department or any
          organization supported by National Skill Development Corporation
          leading to a recognized Government organization are approved for the
          loan
        </li>
      </ul>
    </div>
  );
};

export default EligibleCourse;
