import React from "react";

const AccountOpening = () => {
  return (
    <div className="card" id="account-opening">
      <h1 className="title">
        Benefits of{" "}
        <span
          className=""
          style={{
            color: "#13589D",
            textDecoration: "underline",
          }}>
          Opening Foreign Bank Account & Credit Cards
        </span>{" "}
        through Globaldreamz
      </h1>
      <div className="">
        <ul
          className="list-style-1 mb--20"
          style={{
            padding: "0",
          }}>
          {" "}
          <p
            style={{
              textDecoration: "underline",
              fontWeight: "bold",
              position: "relative",
              top: "20px",
            }}>
            Bank Account
          </p>
          <li
            style={{
              margin: "0px",
            }}>
            <i className="icon-checkbox-circle-fill-solid"></i>Apply for an Bank
            Account while you are here in India
          </li>
          <li>
            <i className="icon-checkbox-circle-fill-solid"></i>Zero Balance
            Account
          </li>
        </ul>
        <ul
          className="list-style-1 mb--20"
          style={{
            padding: "0",
          }}>
          {" "}
          <p
            style={{
              textDecoration: "underline",
              fontWeight: "bold",
              position: "relative",
              top: "20px",
            }}>
            Credit Card
          </p>
          <li>
            <i className="icon-checkbox-circle-fill-solid"></i>Limit Up to $5000{" "}
          </li>
          <li>
            <i className="icon-checkbox-circle-fill-solid"></i>Exclusive Deals &
            Offers - Round the Year{" "}
          </li>
          <li>
            <i className="icon-checkbox-circle-fill-solid"></i>Spend Analytics{" "}
          </li>
          <li>
            <i className="icon-checkbox-circle-fill-solid"></i>Travel Benefits{" "}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AccountOpening;
