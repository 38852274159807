import React, { useState, useContext } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";

const CareerDreamz = () => {

  return (
    <>
      <SEO title="Careers - GlobalDreamz" description="Join the GlobalDreamz team and help shape the future of technology. We offer a dynamic, fast-paced environment where you can use your skills to make a difference. Explore our current job openings and find out how you can be part of our team." keywords="Jobs at GlobalDreamz" canonical="https://globaldreamzedu.com/careers" />
      <Layout>
      <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
src={`${process.env.PUBLIC_URL}/images/event/event-details/sh-1.jpg`}
                    alt="Event Thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <p>No openings currently active.</p>
              </div>
            </div>
            
          </div>
        </div>
      </Layout>
    </>
  );
};
export default CareerDreamz;
