import * as React from "react";
import "./Forex.css";
import logo1 from "./images/logo1.png";
import logo2 from "./images/logo2.png";
import girl from "./images/girl.png";
import airoplane from "./images/airoplane.png";
import laptop from "./images/laptop.png";
import studentId from "./images/studentId.png";
import gift from "./images/gift.png";
import visa from "./images/visa.png";
import earth from "./images/earth.png";
import phoneIcon from "./images/phoneIcon.png";
import mailIcon from "./images/mailIcon.png";
import homeIcon from "./images/homeIcon.png";

import axios from "axios";
import Form from "./Form";

const Success = () => {
  return (
    <p className="success-message">
      Thanks for your query. We will contact with you soon.
    </p>
  );
};

const Error = () => {
  return (
    <p className="error-message">
      Something went wrong. Please try again later. You can also contact us on
      below details.
    </p>
  );
};

const Forex = () => {
  const [result, setResult] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [form, setForm] = React.useState({
    page: "Forex",
    Name: "",
    Email: "",
    PhoneNo: "",
    intake: "",
    CountryOfStudy: "",
  });

  const sendEmail = (e) => {
    e.preventDefault();
    axios.post("https://api.globaldreamzedu.com/contact", form).then(
      (response) => {
        console.log(response);
        if (response.status === 200) {
          setIsSuccess(true);
          setResult(true);
          setForm({
            Name: "",
            Email: "",
            PhoneNo: "",
            CountryOfStudy: "",
            AdmissionCounselling: "",
            ServiceType: "",
          });
          setTimeout(() => {
            setResult(false);
          }, 5000);
        }
      },
      (error) => {
        setIsSuccess(false);
        setResult(true);
        setForm({
          Name: "",
          Email: "",
          PhoneNo: "",
          CountryOfStudy: "",
          AdmissionCounselling: "",
          ServiceType: "",
        });
        setTimeout(() => {
          setResult(false);
        }, 5000);
        console.log(error);
      }
    );
  };
  return (
    <div className="forex">
      {/* FIRST SECTION */}
      <section className="first">
        <div className="logo">
          {/* <img src={logo1} alt="" /> */}
          <img src={logo2} alt="" />
        </div>
      </section>

      {/* SECOND SECTION */}
      <section className="second">
        <div className="form">
          <Form />
        </div>
      </section>

      {/* THIRD SECTION */}

      {/* FOURTH SECTION */}

      <section className="fourth">
        <div className="features">
          <h3>Connect to know more</h3>
        </div>
        <div className="contact">
          <div className="contact1">
            <img src={earth} alt="" />
            <p>www.globaldreamzedu.com</p>
          </div>
          <div className="contact1">
            <img src={phoneIcon} alt="" />
            <p>9623448480</p>
          </div>
          <div className="contact1">
            <img src={mailIcon} alt="" />
            <p>help@globaldreamzedu.com</p>
          </div>
        </div>
        <div className="address">
          <img src={homeIcon} alt="" />
          <span></span>
          <p>
            Office No. 2, 2nd Floor, Aditya Centeegra, Above RBL Bank,
            Dyaneshwar Paduka Chowk, F C Road, Pune- 411005
          </p>
        </div>
      </section>
    </div>
  );
};

export default Forex;
