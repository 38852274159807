import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const items = [
  {
    title: "Find the Right University for You!",
    info: "You can customize your profile, your preferences, and your score. So there’s no further need for IT. ",
    icon: "icon-book-line",
    shape: "shape-bg-1",
    link: "#",
  },
  {
    title: "Easy Admission Process",
    info: "We know navigating the admission process can be daunting. We assist with all the required paperwork and mailing in your application. ",
    icon: "icon-shopping-basket-2-line",
    shape: "shape-bg-2",
    link: "#",
  },
  {
    title: "Great! Track your Goals",
    info: "This is where you get some space! Apply to universities, track your applications, add reminders, upload your necessary documents, as well as plan your budget for overseas schooling. ",
    icon: "icon-trophy-line",
    shape: "shape-bg-4",
    link: "#",
  },
];

const HowItWorksOne = ({ wrapperClass, itemClass }) => {
  return (
    <div className={wrapperClass || "row g-5 mt--20"}>
      {items.map((data, i) => (
        <ScrollAnimation
          animateIn="fadeIn"
          animateOut="fadeInOut"
          className={
            itemClass || "service-card-single col-lg-4 col-md-6 col-12"
          }
          animateOnce={true}
          key={i}
        >
          <div className={`service-card service-card-7 ${data.shape}`}>
            <div className="inner">
              <div className="icon">
                <a href={data.link}>
                  <i className={data.icon}></i>
                </a>
              </div>
              <div className="content">
                <h6 className="title">
                  <a href={data.link}>{data.title}</a>
                </h6>
                <p className="description">{data.info}</p>
              </div>
            </div>
            <div className="arrow-icon">
              <img
                src={`${process.env.PUBLIC_URL}/images/how-it-works/arrow-down.png`}
                alt="Arrow Images"
              />
            </div>
          </div>
        </ScrollAnimation>
      ))}
    </div>
  );
};

export default HowItWorksOne;
