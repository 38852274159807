import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { FaPen, FaPhone, FaPlay, FaUniversity } from "react-icons/fa";
import { FcAssistant, FcProcess, FcServices } from "react-icons/fc";
import Layout from "../../common/Layout";
import SEO from "../../common/SEO";
import HeaderTwo from "../../common/header/HeaderTwo";
import AboutOne from "../../components/about/AboutOne";
import CounterUpOne from "../../components/counterup/CounterUpOne";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import "../../assets/css/about.css";

const items = [
  {
    title: "Test Preparation",
    info: "We offer comprehensive test preparation courses for GRE, GMAT, SAT, TOEFL, IELTS, PTE and more.",
    icon: (
      <FaPen
        style={{
          width: "30px",
          height: "30px",
        }}
      />
    ),
  },
  {
    title: "Find the Right University for You!",
    info: "We'll find the best match for your profile considering your preferred specialization, academic record and test scores.",
    icon: (
      <FaUniversity
        style={{
          width: "30px",
          height: "30px",
        }}
      />
    ),
  },
  {
    title: "Easy Admission Process",
    info: "We'll guide you through every stage of this complex year-long process",
    icon: (
      <FcProcess
        style={{
          width: "30px",
          height: "30px",
        }}
      />
    ),
  },
  {
    title: "Education Loan Assistance",
    info: "We'll help you get the best education loan for your needs.",
    icon: (
      <FcAssistant
        style={{
          width: "30px",
          height: "30px",
        }}
      />
    ),
  },
  {
    title: "Allied Services",
    info: "We'll help you with your forex, travel and accomodation arrangements, and more.",
    icon: (
      <FcServices
        style={{
          width: "30px",
          height: "30px",
        }}
      />
    ),
  },
  {
    title: "Get in Touch with other Aspirants!",
    info: "Build your network of contacts - make joint travel and accomodation-sharing plans.",
    icon: (
      <FaPhone
        style={{
          width: "30px",
          height: "30px",
        }}
      />
    ),
  },
  // {
  //   title: "Track your Goals",
  //   info: "Monitor your progress in applying for every university",
  //   icon: <MdOutlineTrackChanges />,
  // },
];

const AboutUsThree = () => {
  const [toggler, setToggler] = useState(false);
  const videoLink = ["https://www.youtube.com/embed/6m_MMBG9ufQ"];
  return (
    <>
      <SEO
        title="About Us - GlobalDreamz"
        description="GlobalDreamz is a leading overseas education consultancy that helps students find the best programs for their future."
        keywords="GlobalDreamz, Exam preparation, career counselling"
        canonical="https://globaldreamzedu.com/about"
      />
      <HeaderTwo styles="header-transparent header-style-2" />
      <Layout>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_13.jpg`}
                    alt="student"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="edu-feature-area mediashark-home-one-video edu-section-gap bg-color-white">
          <div className="container mediashark-animated-shape">
            <div className="row row--35">
              <div className="col-lg-7 col-12 order-2 order-lg-1">
                <div className=" mt_md--40 mt_sm--40">
                  <h1
                    style={{
                      fontSize: "2.5rem",
                    }}>
                    <span className="color--theme">What makes us unique?</span>
                  </h1>
                  <div className="mt--10 unique-container">
                    {items.map((data, i) => (
                      <div className="unique-item" key={i}>
                        <div
                          className="icon"
                          style={{
                            marginBottom: "10px",
                          }}>
                          {data.icon}
                        </div>
                        <div className="content">
                          <h6
                            className=""
                            style={{
                              color: "red",
                            }}>
                            {data.title}
                          </h6>
                          <p>{data.info}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-12 order-1 order-lg-2 pt-5 mt-5">
                <div className="feature-thumbnail pt-5">
                  <div className="main-image video-popup-wrapper video-popup-two">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/videopopup/choose-us-image-01.jpg`}
                      alt="student"
                    />

                    <button
                      className="video-play-btn with-animation position-to-top btn-large video-popup-activation mediashark-video-play-icon color-secondary d-none"
                      onClick={() => setToggler(!toggler)}>
                      <span>
                        <FaPlay className="play-icon" />
                      </span>
                    </button>
                  </div>

                  <div className="circle-image">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <HomeFiveProgress /> */}
        <AboutOne />
        <CounterUpOne />
      </Layout>
    </>
  );
};

export default AboutUsThree;
