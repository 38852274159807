import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import EventData from "../../data/event/EventData.json";
import InstructorData from "../../data/instructor/InstructorData.json";
import CourseData from "../../data/course/CourseData.json";
import CategoryMba from "../../components/category/CategoryMba";
import ScrollAnimation from "react-animate-on-scroll";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import TestimonialSectionFour from "../../components/testimonial-section/TestimonialSectionFour";
import AboutSix from "../../components/about/AboutSix";
import { slugify } from "../../utils";
import CurriculumTabContent from "../../data/course/CurriculumTabContent.json";

const accordionItems = [
  {
    id: 1,
    title: "What are the benefits of preparing for the TOEFL?",
    body: "TOEFL helps students to improve their English language skills and be at par with native speakers. At GlobalDreamz, you get the appropriate guidance to ace the exam. ",
  },
  {
    id: 2,
    title: "Which country/countries require the TOEFL score?",
    body: "The US, UK, Australia, New Zealand, and Canada accept the TOEFL score. ",
  },
  {
    id: 3,
    title: "What is the eligibility for attempting the TOEFL?",
    body: "You need to have passed 10+2 from a recognized institution. That means you should have passed high school or an equivalent exam. ",
  },
  {
    id: 4,
    title: "Is the TOEFL compulsory?",
    body: "Although the TOEFL is not compulsory, many institutes have made this test a prerequisite for securing admission. ",
  },
];

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button">
      {children}
    </button>
  );
};

const CurriculumContent = () => {
  const [activeId, setActiveId] = useState("0");

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
      {CurriculumTabContent.map((accordion, i) => (
        <Accordion.Item
          eventKey={i.toString()}
          key={i}
          onClick={() => toggleActive(i.toString())}
          bsPrefix={`edu-accordion-item ${
            activeId === i.toString() ? "bg-active" : ""
          }`}>
          <div className="edu-accordion-header">
            <CustomToggle eventKey={i.toString()}>
              {accordion.title}
            </CustomToggle>
          </div>
          <Accordion.Body bsPrefix="edu-accordion-body">
            <ul>
              {accordion.content.map((title, index) => (
                <li key={index}>
                  <div className="text">
                    <i className="icon-draft-line"></i>
                    {title}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const ToeflDreamz = () => {
  const { id } = useParams();
  const eventId = parseInt(1, 10);
  const data = EventData.filter((event) => event.id === eventId);
  const eventItem = data[0];

  const [activeId, setActiveId] = useState("0");
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const courseId = parseInt(1, 10);
  const dataCourse = CourseData.filter((course) => course.id === courseId);
  const courseItem = dataCourse[0];

  const indexOfInstructor = InstructorData.findIndex(function (instructor) {
    return slugify(instructor.name) === slugify(courseItem.instructor);
  });
  const instructor = InstructorData[indexOfInstructor];
  const instructorExcerpt = instructor.details.substring(0, 190) + "...";

  const [contentTab, setContentTab] = useState("overview");
  const handleTab = (content) => {
    if (content === "overview") {
      setContentTab("overview");
    } else if (content === "curriculum") {
      setContentTab("curriculum");
    } else if (content === "instructor") {
      setContentTab("instructor");
    } else if (content === "reviews") {
      setContentTab("reviews");
    }
  };

  return (
    <>
      {/* <SEO title={eventItem.title} /> */}
      <SEO
        title="TOEFL Online Course | Best TOEFL Classes in Pune - GlobalDreamz"
        description="Get the TOEFL online course and achieve your desired score. Ace the TOEFL exam and get one step closer with the best expert tips, and study materials."
        keywords="best toefl classes in pune, toefl online course, gre and toefl classes in pune, toefl classes in pune"
        canonical="https://globaldreamzedu.com/toefl"
      />
      <Layout>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_6.jpg`}
                    alt="toefl exam prep students"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="content">
                  <h1 className="title">Why should you prepare for TOEFL?</h1>
                  <p>
                    The test of English as a foreign language (TOEFL) is a test
                    of the English language. If you want to work, study or
                    immigrate to the UK, USA, Australia, New Zealand, and
                    Canada, then TOEFL is one of the prerequisites. The test is
                    accepted in 11, 500 universities and 160 countries. It is
                    the triad of standardised exams in the English language
                    -TOEFL, IELTS, and PTE.
                  </p>
                  <p>
                    TOEFL is conducted by Educational Testing services. It is
                    both a computer-based test and a written test. Now it has
                    evolved into TOEFL iBT or TOEFL internet-based test. The
                    TOEFL test is further divided into four sections – Speaking
                    and Writing, Listening and Reading. The scores of the TOEFL
                    are used for entry into academics and employment.
                  </p>
                  <p>
                    But why TOEFL when you already have IELTS and PTE in place?
                    TOEFL is the oldest language exam which was incepted in 1964
                    and is still in existence. It tests all the areas of
                    language - the content, fluency, and accuracy of the student
                    in the English language.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <p>
                  Contact our TOEFL counsellors at GlobalDreamz to fulfilling
                  your TOEFL aspirations!
                </p>
                <h4>Five major elements that we offer:</h4>
                <ul className="list-style-1 mb--20">
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Tailor-made classes according to the student’s level of
                    preparation
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Best TOEFL coaching facility
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    1:1 studying facility with specialised faculty
                  </li>
                </ul>
                <p>
                  TOEFL PBT or TOEFL iBT preparation helps the student to gain
                  expertise in all the sections of the TOEFL – Speaking,
                  Writing, Listening, and Reading. At GlobalDreamz, we help you
                  in the preparation of the specialised test with strategized
                  preparation for all the sections of the test.
                </p>

                <CategoryMba />
              </div>

              <div className="col-12">
                <div className="edu-accordion-area mediashark-home-three-accordion accordion-shape-1 edu-section-gap bg-color-white">
                  <div className="container mediashark-animated-shape">
                    <div className="row g-5">
                      <div className="col-lg-6 gallery-wrapper">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="thumbnail">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-01.jpg`}
                                alt="About Thumb 1"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 mt--35 mt_sm--15">
                            <div className="thumbnail">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-02.jpg`}
                                alt="students discussion"
                              />
                            </div>
                            <div className="thumbnail mt--15">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-03.jpg`}
                                alt="students discussion"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <SectionTitle
                          classes="text-start mb--40"
                          slogan="FAQs"
                          title="Get Every General Answers From Here"
                        />
                        <ScrollAnimation
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          className="accordion-style-1"
                          animateOnce={true}>
                          <Accordion
                            bsPrefix="edu-accordion"
                            defaultActiveKey="0"
                            flush>
                            {accordionItems.map((accordion, i) => (
                              <Accordion.Item
                                eventKey={i.toString()}
                                key={i}
                                onClick={() => toggleActive(i.toString())}
                                bsPrefix={`edu-accordion-item ${
                                  activeId === i.toString() ? "active-item" : ""
                                }`}>
                                <div className="edu-accordion-header">
                                  <CustomToggle eventKey={i.toString()}>
                                    {accordion.title}
                                  </CustomToggle>
                                </div>
                                <Accordion.Body bsPrefix="edu-accordion-body">
                                  {accordion.body}
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </ScrollAnimation>
                      </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                      <div className="shape shape-1">
                        <span className="shape-dot"></span>
                      </div>
                      <div className="shape-image shape-image-2">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/shapes/shape-11-04.png`}
                          alt="Shape Thumb"
                        />
                      </div>
                      <div className="shape-image shape-image-3">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/shapes/shape-25-01.png`}
                          alt="Shape Thumb"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="course-details-content">
                  <h3 className="title">Our Services</h3>
                  <ul className="edu-course-tab nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "overview"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Overview"
                        onClick={() => handleTab("overview")}>
                        Course Description
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "curriculum"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Curriculum"
                        onClick={() => handleTab("curriculum")}>
                        Curriculum
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "instructor"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Instructor"
                        onClick={() => handleTab("instructor")}>
                        Instructor
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "reviews"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Reviews"
                        onClick={() => handleTab("reviews")}>
                        Reviews
                      </button>
                    </li>
                  </ul>

                  <div className="tab-content">
                    {contentTab === "overview" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "overview" ? "active" : ""
                        } `}>
                        {/* <div
                          className="course-tab-content"
                          dangerouslySetInnerHTML={{
                            __html: courseItem.details,
                          }}
                        /> */}
                        <div className="course-tab-content">
                          <h6>Overview</h6>
                          <p>
                            The TOEFL is a standardized English Language test
                            that is a pre-requisite for studying, working, or
                            staying in English-speaking countries. The Online
                            TOEFL Coaching exam takes around 4 hours for the
                            internet-based test and the paper test takes around
                            3 hours.
                          </p>

                          <h6>
                            What is the benefit of the TOEFL prep course by
                            GlobalDreamz?
                          </h6>
                          <p>
                            {" "}
                            Preparation for the TOEFL exam means mastering the
                            syllabus along with mock tests at regular intervals.
                            At GlobalDreamz, we provide all the necessary
                            resources required for this exam.
                          </p>

                          <h6>Curriculum</h6>
                          <p>
                            The primary goal of the TOEFL is to provide the
                            necessary language skills to the student. The
                            students will be provided extensive training in the
                            four sections of the TOEFL:
                          </p>
                          <ul>
                            <li>Listening</li>
                            <li>Reading</li>
                            <li>Speaking</li>
                            <li>Writing</li>
                          </ul>
                        </div>
                        <br></br>
                      </div>
                    )}

                    {contentTab === "curriculum" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "curriculum" ? "active" : ""
                        } `}>
                        <div className="course-tab-content">
                          {/* <CurriculumContent /> */}
                          <p>
                            The primary goal of the TOEFL is to provide the
                            necessary language skills to the student. The
                            students will be provided extensive training in the
                            four sections of the TOEFL:
                          </p>

                          <ul>
                            <li>Listening</li>
                            <li>Reading</li>
                            <li>Speaking</li>
                            <li>Writing</li>
                          </ul>
                        </div>
                      </div>
                    )}

                    {contentTab === "instructor" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "instructor" ? "active" : ""
                        } `}></div>
                    )}

                    {contentTab === "reviews" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "reviews" ? "active" : ""
                        } `}></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <TestimonialSectionFour /> */}
        </div>
        <AboutSix />
      </Layout>
    </>
  );
};
export default ToeflDreamz;
