import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../sectionTitle/SectionTitle";

const AboutEight = () => {
  return (
    <div className="mediashark-home-five-about edu-about-area about-style-6 edu-section-gap bg-color-white">
      <div className="container mediashark-animated-shape mb-5">
        <div className="row g-5 align-items-center">
          <ScrollAnimation
            animateIn="fadeIn"
            animateOut="fadeInOut"
            className="col-xl-5 col-lg-6"
            animateOnce={true}>
            <div className="about-image-gallery">
              <img
                className="image-1 w-100"
                src={`${process.env.PUBLIC_URL}/images/about/about-08/about-image-09.jpg`}
                alt="graduate students"
              />
            </div>
          </ScrollAnimation>
          <div className="col-xl-6 col-lg-6 offset-xl-1">
            <div className="inner mt_md--40 mt_sm--20">
              <SectionTitle title="GlobalDreamz “Point of Difference “  " />
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}>
                <p className="description line-before mt--40 mb--40">
                  <b>Rich Experience</b> – Learn from A Team with A Collective
                  Experience of Over 100+ Years
                  <br /> <br />
                  <b>Customised Flexibility</b> – Rolling Batches with Timings
                  Designed to Suit the Students
                  <br /> <br />
                  <b>Personal Attention </b>– Get Your Own Counsellor to Help
                  You Deal with All Your Queries and Difficulties
                  <br /> <br />
                  <b>Comprehensive Solutions </b>– From Test Prep to Financial
                  Help to Arrival in Your Study Destination
                  <br /> <br />
                  <b>Online Services </b>- Learn Online with A State of Art
                  System from The Comfort of Your Own Home
                </p>
              </ScrollAnimation>
              <div className="feature-list-wrapper">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="feature-list"
                  animateOnce={true}>
                  <i className="icon-checkbox-circle-fill"></i>Exam Prep
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="feature-list"
                  animateOnce={true}>
                  <i className="icon-checkbox-circle-fill"></i>University
                  Selection
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="feature-list"
                  animateOnce={true}>
                  <i className="icon-checkbox-circle-fill"></i>Visa Counseling
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="feature-list"
                  animateOnce={true}>
                  <i className="icon-checkbox-circle-fill"></i>Student Loans
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="feature-list"
                  animateOnce={true}>
                  <i className="icon-checkbox-circle-fill"></i>Accomodation
                </ScrollAnimation>
              </div>
              <div className="read-more-btn mt--75 mt_lg--30 mt_md--40 mt_sm--40">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}>
                  <Link className="edu-btn" to="/about">
                    Know About Us
                  </Link>
                  <a className="info-btn" href="tel:+919209401677">
                    <i className="icon-call"></i>+91 92094 01677
                  </a>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image shape-image-1">
            <img
              src={`${process.env.PUBLIC_URL}/images/shapes/shape-05-04.png`}
              alt="Shape Thumb"
            />
          </div>
          <div className="shape-image shape-image-2">
            <img
              src={`${process.env.PUBLIC_URL}/images/shapes/shape-33.png`}
              alt="Shape Thumb"
            />
          </div>
          <div className="shape-image shape-image-3">
            <img
              src={`${process.env.PUBLIC_URL}/images/shapes/shape-13-05.png`}
              alt="Shape Thumb"
            />
          </div>
          <div className="shape-image shape-image-4">
            <img
              src={`${process.env.PUBLIC_URL}/images/shapes/shape-01-03.png`}
              alt="Shape Thumb"
            />
          </div>
          <div className="shape-image shape-image-5">
            <img
              src={`${process.env.PUBLIC_URL}/images/shapes/shape-06.png`}
              alt="Shape Thumb"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutEight;
