import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../sectionTitle/SectionTitle";

const FeatureItems = [
  {
    title: "Learners & counting",
    number: 449,
    image: "about-image-01.png",
  },
  {
    title: "Courses & Video",
    number: 330,
    image: "about-image-02.png",
  },
  {
    title: "Certified Students",
    number: 275,
    image: "about-image-03.png",
  },
  {
    title: "Winning Award",
    number: 378,
    image: "about-image-04.png",
  },
];

const AboutOne = ({ wrapperClass }) => {
  return (
    <div
      className={`mediashark-home-two-counter bg-color-white ${
        wrapperClass || "edu-section-gap"
      }`}>
      <div className="container mediashark-animated-shape">
        <div className="row align-items-center gy-5">
          <div className="col-lg-12">
            <div className="choose-us-2">
              <div className="inner">
                <h1 style={{ fontSize: "2.5rem" }}>
                  <span className="color--theme">Why Choose Us?</span>
                </h1>
                <h3
                  style={{
                    fontSize: "1.8rem",
                    color: "red",
                  }}>
                  Creating A Community Of Life Long Learners
                </h3>

                {/* <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}>
                  <p className="description mt--40 mb--30"></p>
                </ScrollAnimation> */}

                <div className="feature-style-4">
                  <div className="edu-feature-list">
                    <div className="icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/about/about-01/smile.png`}
                        alt="Icon Thumb"
                      />
                    </div>
                    <div className="content">
                      <h6 className="">Trusted By Thousands</h6>
                      <p></p>
                    </div>
                  </div>

                  <div className="edu-feature-list color-var-2">
                    <div className="icon">
                      <i className="icon-Support"></i>
                    </div>
                    <div className="content">
                      <h6 className="">Strong Support</h6>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image shape-image-1">
            <img
              src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`}
              alt="Shape Thumb"
            />
          </div>
          <div className="shape-image shape-image-2">
            <img
              src={`${process.env.PUBLIC_URL}/images/shapes/shape-11.png`}
              alt="Shape Thumb"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
