import { useState } from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import HeaderSticky from "./HeaderSticky";
import ResponsiveMenu from "./ResponsiveMenu";

const HeaderTwo = ({ styles, disableSticky, searchDisable, buttonStyle }) => {
  const [offcanvasShow, setOffcanvasShow] = useState(false);
  const [searchPopup, setSearchPopup] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prevState) => !prevState);
  };

  const onSearchHandler = () => {
    setSearchPopup((prevState) => !prevState);
  };

  if (searchPopup) {
    document.body.classList.add("search-popup-active");
  } else {
    document.body.classList.remove("search-popup-active");
  }

  const sticky = HeaderSticky(-10);
  const classes = `header-default sticky`;
  const stickyStatus = disableSticky ? "" : " header-sticky";
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="gd-infoheader">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="gd-infoheader-shift">
                      <p>
                        Have any questions? &emsp;{" "}
                        <i className="icon-phone-fill"></i>{" "}
                        <a href="tel:+919209401677">+91 92094 01677 &nbsp;</a>,{" "}
                        <a href="tel:+919209401688">&nbsp;+91 92094 01688</a>{" "}
                        |&emsp;<i className="icon-mail-line-2"></i>{" "}
                        <a href="mailto:info@globaldreamzedu.com">
                          info@globaldreamzedu.com
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-6 text-end">
                    <div>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header
        className={`edu-header ${stickyStatus} ${styles || ""} ${
          classes || ""
        }`}>
        <div className="row align-items-center">
          <div className="col-lg-4 col-xl-2 col-md-6 col-6 text-end">
            <div className="logo">
              <Link to={"/"}>
                <embed
                  href="{`${process.env.PUBLIC_URL}/home`}"
                  className="logo-light"
                  src={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                  alt="logo"
                />
              </Link>
            </div>
          </div>

          <div className="col-lg-6 d-none d-xl-block col-xl-8">
            <nav className="mainmenu-nav d-none d-lg-block">
              <Nav />
            </nav>
          </div>

          <div className="col-lg-8 col-xl-2 col-md-6 col-6">
            <div className="header-right d-flex justify-content-end">
              <div className="header-menu-bar">
                <div
                  className="quote-icon quote-user d-none d-md-block ml--15 ml_sm--5"
                  onClick={() => {
                    //redirect to
                    window.location.href =
                      "https://crm.globaldreamzedu.com/login";
                  }}>
                  <Link
                    className={`edu-btn btn-medium left-icon header-button ${
                      buttonStyle || ""
                    }`}
                    to={"#"}>
                    <i className="ri-user-line"></i>Student Portal
                  </Link>
                </div>
                <div className="quote-icon quote-user d-block d-md-none ml--15 ml_sm--5">
                  <Link
                    to={"/global-dreamz-enquiry"}
                    className="white-box-icon"
                    href="#">
                    <i className="ri-user-line"></i>
                  </Link>
                </div>
              </div>
              <div className="mobile-menu-bar ml--15 ml_sm--5 d-block d-xl-none">
                <div className="hamberger">
                  <button
                    className="white-box-icon hamberger-button header-menu"
                    onClick={onCanvasHandler}>
                    <i className="ri-menu-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <ResponsiveMenu
        show={offcanvasShow}
        onClose={onCanvasHandler}
        showSearch={searchPopup}
        onSearch={onSearchHandler}
      />
    </>
  );
};

export default HeaderTwo;
