import React from "react";
import SBIEmiCal from "./SBIEmiCal";

const EmiCalculator = () => {
  return (
    <div className="features" id="emi-calculator">
      <h3>Emi Calculator</h3>
      <SBIEmiCal />
    </div>
  );
};

export default EmiCalculator;
