import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <div>
      <ul className="mainmenu">
        <li>
          <Link to="/home">Home</Link>
        </li>

        <li className="has-dropdown">
          <Link to="#">About</Link>
          <ul className="submenu">
            <li>
              <Link to="/about">Our Misson</Link>
            </li>
            <li>
              <Link to="/about-team">Team</Link>
            </li>

            <li className="has-dropdown">
              <Link to="#">Event</Link>
              <ul className="submenu">
                <li className="has-dropdown">
                  <Link to="/events-gallery">Corporate Events</Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/events-gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/events">Events</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/activity-gallery">Activity</Link>
                </li>
                <li>
                  <Link to="/office-gallery">Office</Link>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li className="has-dropdown">
          <Link to="#">Services</Link>
          <ul className="submenu">
            {/* EXAM PREP */}
            <li className="has-dropdown">
              <Link to="#">Exam Prep</Link>
              <ul className="submenu">
                <li>
                  <Link to="/gre">GRE</Link>
                </li>
                <li>
                  <Link to="/toefl">TOEFL</Link>
                </li>
                <li>
                  <Link to="/ielts">IELTS</Link>
                </li>
                <li>
                  <Link to="/pte">PTE</Link>
                </li>
              </ul>
            </li>

            {/* ADMISSION COUNSELLING */}
            <li className="has-dropdown">
              <Link to="#">Admission & Visa Counselling</Link>
              <ul className="submenu">
                <li>
                  <Link to="/ms">MS</Link>
                </li>
                <li>
                  <Link to="/mba">MBA</Link>
                </li>
                <li>
                  <Link to="/undergrad">Undergrad</Link>
                </li>
                <li>
                  <Link to="/phd">Ph.D.</Link>
                </li>
              </ul>
            </li>

            {/* EDU LOANS */}
            <li className="has-dropdown">
              <Link to="#">Edu Loans</Link>
              <ul className="submenu">
                {/* ------ NATIONALIZED & PVT BANKS ---------- */}
                <li className="has-dropdown">
                  <Link to="#">Nationalized & Private Banks</Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/sbi">SBI</Link>
                    </li>
                    <li>
                      <Link to="/ubi">UBI</Link>
                    </li>
                    <li>
                      <Link to="/bob">BOB</Link>
                    </li>
                    <li>
                      <Link to="/icici">ICICI</Link>
                    </li>
                    <li>
                      <Link to="/axis">Axis</Link>
                    </li>
                    <li>
                      <Link to="/idfc">IDFC</Link>
                    </li>
                    <li>
                      <Link to="/cosmos">Cosmos</Link>
                    </li>
                    <li>
                      <Link to="/yes">Yes Bank</Link>
                    </li>
                  </ul>
                </li>

                {/* ------ NATIONALIZED & PVT BANKS ---------- */}
                <li className="has-dropdown">
                  <Link to="#">Non Banking Financial Companies</Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/hdfc">HDFC Credilla</Link>
                    </li>
                    <li>
                      <Link to="/avanse">Avanse</Link>
                    </li>
                    <li>
                      <Link to="/incred">Incred</Link>
                    </li>
                    <li>
                      <Link to="/auxilo">Auxilo</Link>
                    </li>
                  </ul>
                </li>

                {/* ------ NATIONALIZED & PVT BANKS ---------- */}
                <li className="has-dropdown">
                  <Link to="#">International Loans</Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/prodigy">Prodigy Finance</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            {/* ALLIED SERVICES */}
            <li className="has-dropdown">
              <Link to="#">Allied Services</Link>
              <ul className="submenu">
                <li>
                  <Link to="/forex">Forex</Link>
                </li>

                <li>
                  <Link to="/allied-services#accomodation">Accomodation</Link>
                </li>
                <li>
                  <Link to="/allied-services#insurance">Insurance</Link>
                </li>
                <li>
                  <Link to="/allied-services#air-ticketing">Air Ticketing</Link>
                </li>
                <li>
                  <Link to="/allied-services#account-opening">
                    Account Opening
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li className="has-dropdown">
          <Link to="#">Countries</Link>
          <ul className="submenu">
            <li>
              <Link to="/study-in-usa">Study In USA</Link>
            </li>
            <li>
              <Link to="/study-in-canada">Study In Canada</Link>
            </li>
            <li>
              <Link to="/study-in-germany">Study In Germany</Link>
            </li>
            <li>
              <Link to="/study-in-uk">Study In UK</Link>
            </li>
            <li>
              <Link to="/study-in-australia">Study In Australia</Link>
            </li>
            <li>
              <Link to="/study-in-row">Study In RoW</Link>
            </li>
          </ul>
        </li>

        <li>
          <Link to="/global-dreamz-enquiry">Contact</Link>
        </li>
        <li>
          <Link to="/wall-of-fame">Wall of Fame</Link>
        </li>
      </ul>
    </div>
  );
};
export default Nav;
