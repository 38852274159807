import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import EventData from "../../data/event/EventData.json";
import InstructorData from "../../data/instructor/InstructorData.json";

import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import AboutSix from "../../components/about/AboutSix";
import { slugify } from "../../utils";
import CurriculumTabContent from "../../data/course/CurriculumTabContent.json";

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button">
      {children}
    </button>
  );
};

const CurriculumContent = () => {
  const [activeId, setActiveId] = useState("0");

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
      {CurriculumTabContent.map((accordion, i) => (
        <Accordion.Item
          eventKey={i.toString()}
          key={i}
          onClick={() => toggleActive(i.toString())}
          bsPrefix={`edu-accordion-item ${
            activeId === i.toString() ? "bg-active" : ""
          }`}>
          <div className="edu-accordion-header">
            <CustomToggle eventKey={i.toString()}>
              {accordion.title}
            </CustomToggle>
          </div>
          <Accordion.Body bsPrefix="edu-accordion-body">
            <ul>
              {accordion.content.map((title, index) => (
                <li key={index}>
                  <div className="text">
                    <i className="icon-draft-line"></i>
                    {title}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const StudyInAustraliaDreamz = () => {
  const { id } = useParams();
  const eventId = parseInt(1, 10);
  const data = EventData.filter((event) => event.id === eventId);
  const eventItem = data[0];

  const [activeId, setActiveId] = useState("0");
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const courseId = parseInt(1, 10);

  const [contentTab, setContentTab] = useState("overview");
  const handleTab = (content) => {
    if (content === "overview") {
      setContentTab("overview");
    } else if (content === "curriculum") {
      setContentTab("curriculum");
    } else if (content === "instructor") {
      setContentTab("instructor");
    } else if (content === "reviews") {
      setContentTab("reviews");
    }
  };

  return (
    <>
      <SEO
        title="Study in the Australia Guide for International Students"
        description="A comprehensive guide for students aspiring to study in Australia. Read this guide to know about the student visa, and more."
        keywords="Study in Australia, education in Australia, study abroad in Australia, Australian universities, scholarships in Australia, international students in Australia, Australian education system, study opportunities in Australia."
        canonical="https://globaldreamzedu.com/study-in-australia"
      />
      <Layout>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_10.jpg`}
                    alt="study australia students"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h3 className="title">Why should you Study in Australia?</h3>
                  <p>
                    Kangaroos, koalas, vast countryside farms, pristine air, and
                    clear water come to mind when we think of Australia. But
                    they are only a small portion of what Australia has to
                    offer. Australia is popular worldwide for its high standard
                    of living. It is a country with a population of 10 million
                    that speak English. Australia is alluring to those who want
                    to study there because it is home to many different
                    cultures.
                  </p>
                  <p>
                    The opportunity to live, learn, and develop according to
                    one's schedule is among the top benefits of studying in
                    Australia. If you want a wonderful lifestyle and a top-notch
                    education, travel to Australia. For overseas students,
                    pursuing higher studies in Australia has the extra benefit
                    of providing exposure and the potential for 15% yearly
                    career advancement.
                  </p>
                  <p>
                    The reality that there are more than 21,000 degrees offered
                    at 100+ Australian universities is a significant factor in
                    bringing students to Australia. At the undergraduate,
                    postgraduate, and doctorate levels, a wide spectrum of
                    courses—including certificate and PG Diploma programs—are
                    provided in more than ten different fields of study.
                    Engineering & Technology, Arts & Humanities, Physical & Life
                    Sciences, Clinical, Pre-Clinical & Health, and Social
                    Sciences are among the prominent fields of study at
                    Australian universities that have been ranked by THE.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row g-5">
              <div className="col-12">
                <div className="course-details-content">
                  <h3 className="title">How Can We Help You?</h3>

                  <h5>University Selection</h5>
                  <p>
                    It is known that students use the flocking effect to
                    determine their strengths and limitations. In contrast to
                    other traditional international educational counsellors,
                    GlobalDreamz offers relevant employment opportunities to
                    help aspirants discover their talents, objectives, and
                    intended occupations.
                    <br></br>
                    <br></br>
                    Students have received assistance choosing the best programs
                    from our education counsellors.
                  </p>

                  <h5>Financial Planning</h5>
                  <p>
                    Studying abroad is an expensive endeavour, and at
                    GlobalDreamz, we believe in assisting students in handling
                    money wisely and effectively. For the parents and students,
                    GlobalDreamz occasionally offers free in-house sessions to
                    assist in relieving their financial concerns. We not only
                    review your documents at each level, but we also make the
                    process simpler for you by giving you the best connections
                    to financial institutions. Because scholarships
                    significantly reduce financial hardship, we also assist
                    students with their scholarship essays and processes.
                    <br></br>
                    <br></br>
                    Our GlobalDreamz Services team will work with you one-on-one
                    to find student loans at the best possible rates.
                  </p>

                  <h5>Course Selection</h5>
                  <p>
                    Not sure which course to take? The course choices require
                    consideration of numerous factors, including deadlines,
                    finances, employability, career ambitions, and many others.
                    Come to us; we'll assist you in determining your skills and
                    the best course for you. Our professionally certified
                    advisors are experienced in working with overseas students.
                    Your designated counsellor will go through all the specifics
                    and procedures with you to ensure you find your best fit in
                    terms of the university and courses.
                  </p>
                </div>
              </div>

              <div className="col-12">
                <div className="course-details-content">
                  <h3 className="title">
                    Why GlobalDreamz Is the Best Fit for You?
                  </h3>

                  <p>
                    We offer complimentary counselling sessions, and work to
                    make the application process as simple as it could be. Over
                    30,000 students have benefited from our assistance in
                    finding the best course to take in their dream universities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AboutSix />
      </Layout>
    </>
  );
};
export default StudyInAustraliaDreamz;
