import React from "react";
import { Link } from "react-router-dom";
import ScrollTopButton from "./ScrollTopButton";

const FooterOne = () => {
  return (
    <>
      <footer className="mediashark-footer-one edu-footer footer-style-default">
        <div className="footer-top">
          <div className="container mediashark-animated-shape">
            <div className="row g-5">
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="edu-footer-widget">
                  <div className="logo">
                    <Link to={"/"}>
                      <img
                        className="logo-light"
                        src={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <p className="description">
                    GlobalDreamz specialises in exam preparation, online and
                    offline counselling, admissions, and career counselling. Our
                    primary goal is to ensure the achievement of our students.
                    Our key strength is in determining the best path for
                    assisting them in realising their career aspirations.
                  </p>
                  <ul className="social-share">
                    <li>
                      <a href="https://www.facebook.com/GlobalDreamz-Edutech-103509175828897/">
                        <i className="icon-Fb"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/globaldreamz-edutech/">
                        <i className="icon-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/globaldreamzedu/">
                        <img
                          className="gd-icon-instagram"
                          src={`${process.env.PUBLIC_URL}/images/icons/instagram.png`}
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCl8hm5R3ja2Bi6UG_D1OL_w">
                        <i className="icon-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/globaldreamzedu">
                        <i className="icon-Twitter"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget explore-widget">
                  <h5 className="widget-title">Explore</h5>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      <li>
                        <Link to="/about">
                          <i className="icon-Double-arrow"></i>About Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">
                          <i className="icon-Double-arrow"></i>Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact">
                          <i className="icon-Double-arrow"></i>Contact us
                        </Link>
                      </li>

                      <li>
                        <Link to="/wall-of-fame">
                          <i className="icon-Double-arrow"></i>Wall of Fame
                        </Link>
                      </li>
                      <li>
                        <Link to="/careers">
                          <i className="icon-Double-arrow"></i>Careers
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog-standard">
                          <i className="icon-Double-arrow"></i>Blogs
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget quick-link-widget">
                  <h5 className="widget-title">Countries</h5>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      <li>
                        <Link to="/study-in-usa">
                          <i className="icon-Double-arrow"></i> Study in USA
                        </Link>
                      </li>

                      <li>
                        <Link to="/study-in-canada">
                          <i className="icon-Double-arrow"></i> Study in Canada
                        </Link>
                      </li>
                      <li>
                        <Link to="/study-in-germany">
                          <i className="icon-Double-arrow"></i> Study in Germany
                        </Link>
                      </li>
                      <li>
                        <Link to="/study-in-uk">
                          <i className="icon-Double-arrow"></i> Study in UK
                        </Link>
                      </li>
                      <li>
                        <Link to="/study-in-australia">
                          <i className="icon-Double-arrow"></i> Study in
                          Australia
                        </Link>
                      </li>
                      <li>
                        <Link to="/study-in-row">
                          <i className="icon-Double-arrow"></i> Study in RoW
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget">
                  <h5 className="widget-title">Contact Info</h5>
                  <div className="inner">
                    <div className="widget-information">
                      <ul className="information-list">
                        <li>
                          <i className="icon-map-pin-line"></i>
                          Office No. 2, 2nd Floor, Aditya Centeegra, Above RBL
                          Bank, Dnyaneshwar Paduka Chowk, F C
                          Road, Pune – 411005
                        </li>
                        <li>
                          <i className="icon-phone-fill"></i>
                          <a href="tel:+919209401677">+91 92094 01677</a>
                        </li>
                        <li>
                          <i className="icon-phone-fill"></i>
                          <a href="tel:+919209401688">+91 92094 01688</a>
                        </li>
                        <li>
                          <i className="icon-mail-line-2"></i>
                          <a
                            target="_blank"
                            href="mailto:info@globaldreamzedu.com">
                            info@globaldreamzedu.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
              <div className="shape-image shape-image-1">
                <img
                  src={`${process.env.PUBLIC_URL}/images/shapes/shape-21-01.png`}
                  alt="Shape Thumb"
                />
              </div>
              <div className="shape-image shape-image-2">
                <img
                  src={`${process.env.PUBLIC_URL}/images/shapes/shape-35.png`}
                  alt="Shape Thumb"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area copyright-default">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <p>
                    Copyright 2022 <a href="#">GlobalDreamz</a> Designed By{" "}
                    <a href="https://www.mediashark.in">MediaShark</a>. All
                    Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollTopButton />
    </>
  );
};

export default FooterOne;
