import React from "react";

const SBIEmiCal = () => {
  return (
    <div
      className="tile"
      id="emi-calculator"
      style={{
        margin: 0,
      }}>
      {/* create a dot */}
      <li> State Bank Of India Education Loan EMI Calculator : </li>
      <ul className="document-list" style={{}}>
        <li>
          <b>State Bank of India Education Loan EMI</b> which can also be said
          as equated monthly installment is a fixed amount of money paid by the
          applicant to the bank against the State Bank of India Education Loan
          taken. This is towards repayment of the student’s loan to the bank
          which every month, is due on a fixed day till the loan is fully paid
          back.
        </li>
        <li>
          <b>State Bank of India Education Loan repayment calculator </b>
          calculates the EMI based on the loan amount applied for along with the
          interest charged on loan and the tenure for which the applicant
          applied for the loan. State Bank of India offers convenient and
          affordable education loans with loan EMIs as low as Rs. 8,738 per lakh
          for a 12 years tenure and lowest rate of interest of 8.85%.
        </li>
      </ul>
    </div>
  );
};

export default SBIEmiCal;
