import React from "react";

const SBILoanEligibility = () => {
  return (
    <div className="tile" id="loan-eligibility">
      {/* create a dot */}
      <li> State Bank Of India Education Loan Eligibility : </li>
      <ul
        className="expense-list"
        style={{
          marginLeft: "0px",
        }}>
        <table
          className="table table-bordered"
          style={{
            margin: "10px 0px",
          }}>
          <thead>
            <tr>
              <th scope="col">Parameters of SBI education loan</th>
              <th scope="col">SBI Education Loan Eligibility Criteria</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Age of the Applicant fot SBI education loan</th>
              <td>
                <ul>
                  <li>Up to 35 years for non-employed people</li>
                  <li>
                    Up to 45 years in the case of working employees and
                    executives
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th scope="row">Who can apply for SBI education loan</th>
              <td>
                <ul>
                  <li>
                    Indian Nationals with secured admission to any professional
                    or technical course in a recognized institution.
                  </li>
                  <li>
                    Permanent employees who are employed at least for three
                    years with any organization and wish to gain higher
                    professional qualifications. These professionals should also
                    have secured admissions in a reputed institute.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th scope="row">Maximum Loan Amount for SBI education loan</th>
              <td>
                <ul>
                  <li>Upto Rs. 1,000,000 for studies in India</li>
                  <li>
                    Upto Rs. 1,50,00,000 under <b>SBI Edvantage Scheme</b>.
                  </li>
                  <li>
                    Upto Rs. 4,000,000 for studies at IIT's, ISB's, and IIM's.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th scope="row">Repayment Frequency forSBI education loan</th>
              <td>
                <ul>
                  <li>Quarterly or monthly rests.</li>
                  <li>
                    Repayment of <b>State Bank of India education Loan</b>{" "}
                    typically starts one year after completing the course or six
                    months after getting a job, whichever is earlier.
                  </li>
                  <li>
                    The repayment of loan interest will start immediately after
                    the disbursement of the first installment of the loan.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th scope="row">Repayment Period for SBI education loan</th>
              <td>
                <ul>
                  <li>
                    The entire tenure of a{" "}
                    <b>State Bank of India education loan</b>
                    includes Duration of Study, Grace Period after the Study,
                    and Repayment Period.
                  </li>
                  <li>
                    For Indian courses, repayment tenure is maximum of 12 years
                    after the commencement of repayment.
                  </li>
                  <li>
                    Applicants applying for <li>SBI overseas education loan</li>{" "}
                    have repayment tenure for a maximum of 15 years after the
                    commencement of repayment.
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </ul>
    </div>
  );
};

export default SBILoanEligibility;
