import React from "react";

const RepaymentTenure = () => {
  return (
    <div className="tile" id="repayment-tenure">
      {/* create a dot */}
      <li> Repayment Tenure</li>
      <ul className="expense-list">
        <li>SBI Student Loans :</li>

        <table
          className="table table-bordered"
          style={{
            margin: "10px 0px",
          }}>
          <thead>
            <tr>
              <th scope="col">
                Maximum Loan Amount for State Bank of India education loan
              </th>
              <th scope="col">
                Repayment Period for State Bank of India education loan
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Up to Rs.4 lakhs</th>
              <td> Up to 10 years</td>
            </tr>
            <tr>
              <th scope="row">Above Rs.4 lakhs to up to Rs.7.5 lakhs</th>
              <td>Up to 10 years</td>
            </tr>
            <tr>
              <th scope="row">Above Rs.7.5 lakhs</th>
              <td>Up to 12 years</td>
            </tr>
          </tbody>
        </table>

        <li>SBI Scholar Education Loan </li>
        <li>The maximum repayment tenure is 12 years.</li>
        <li>
          The repayment period starts after 6 months of course completion.
        </li>
        <li>
          In the case of SBI Loan for Vocational Education and Training, the
          repayment period for courses of duration up to one year can be up to
          three years. For courses of duration above one year, the repayment
          period can extend up to five years.
        </li>
      </ul>

      {/* CREATE A table with three columns and 2 rows */}
    </div>
  );
};

export default RepaymentTenure;
