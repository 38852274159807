import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import AboutOne from "../../components/about/AboutOne";
import HomeFiveProgress from "../../components/home-five/HomeFiveProgress";
import CounterUpOne from "../../components/counterup/CounterUpOne";
import HeaderTwo from "../../common/header/HeaderTwo";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/sectionTitle/SectionTitle";

const AboutUsThree = () => {
  return (
    <>
      <SEO
        title="About Team - GlobalDreamz"
        description="GlobalDreamz is a team of helping students and professionals achieve their dreams of studying abroad"
        keywords="Team - GlobalDreamz,globaldreamzedu"
        canonical="https://globaldreamzedu.com/about-team"
      />
      <HeaderTwo styles="header-transparent header-style-2" />
      <Layout>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_team.jpg`}
                    alt="Event Thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-feature-area bg-color-white mt--100">
          <div className="container mediashark-animated-shape">
            <div className="row mt-5">
              <div className="col-lg-7 col-12">
                <h3>The GlobalDreamz Team </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="edu-feature-area bg-color-white">
          <div className="container mediashark-animated-shape">
            <div className="row">
              <div className="col-lg-7 col-12">
                <h4>The Showrunners</h4>
              </div>
              <div>
                <p>
                  The founders of GlobalDreamz share a common vision – that of
                  unlocking opportunities for Indian students in the field of
                  higher education abroad. They want to enable deserving
                  students to reap the rewards that come with studying abroad.
                  Respectively Mahesh, Amar and Sahebrao have headed up the West
                  Zone, the Bank Channel and the Mumbai Cluster for Education
                  Loans at HDFC Credila, where their vision was born.
                  <br />
                  <br />
                  Together, they bring to the table a collective expertise of
                  50+ years in the area of financial services out of which
                  mainly for higher education abroad, a critical component that
                  enables even middle-class students to fulfill their dreams of
                  studying abroad without overburdening their parents. Through
                  GlobalDreamz, their aim is to build up a world-class team that
                  will help them expand their capacities beyond just providing
                  financing for students, to fulfilling every need of students
                  aspiring to higher education abroad. They share a pan-India
                  vision, desiring to take their mission of higher education for
                  all to reach students further and more widely across India
                  than it has ever been done so far.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="mediashark-home-five-about edu-about-area about-style-6 bg-color-white mb--80">
          <div className="container mediashark-animated-shape">
            <div className="row g-5 align-items-center">
              <div className="col-xl-4 col-lg-6">
                <div className="about-image-gallery og-hide">
                  <img
                    className="image-1 w-100"
                    src={`${process.env.PUBLIC_URL}/images/about/team/1.jpg`}
                    alt="co - founder"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 offset-xl-1">
                <div className="inner mt_md--40 mt_sm--20">
                  <SectionTitle title="Mahesh Maku " />
                  <h3 className="og-font-size">Co Founder</h3>
                  <div className="feature-list-wrapper">
                    <p className="description line-before mt--40 mb--40">
                      Former Head - West Zone, Education Loans for HDFC Credila.
                      He has 20 years of experience in Team Management and
                      Education Loans. He has been highly successful in Managing
                      Sales and Distribution along with Credit Appraisal and
                      Product Development, while working for companies like
                      IndiaBulls, Emkay Global Financial Services and HDFC
                      Credila. <br />
                      <br />
                      Mahesh is a Computer Engineer with a Master's degree in
                      management from JBIMS, Mumbai
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mediashark-home-five-about edu-about-area about-style-6  bg-color-white mb--80">
          <div className="container mediashark-animated-shape">
            <div className="row g-5 align-items-center">
              <div className="col-xl-4 col-lg-6">
                <div className="about-image-gallery og-hide">
                  <img
                    className="image-1 w-100"
                    src={`${process.env.PUBLIC_URL}/images/about/team/2.jpg`}
                    alt="co - founder"
                  />
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 offset-xl-1">
                <div className="inner mt_md--40 mt_sm--20">
                  <SectionTitle title="Amar Mudbidri " />
                  <h3 className="og-font-size">Co Founder</h3>
                  <div className="feature-list-wrapper">
                    <p className="description line-before mt--40 mb--40">
                      Former Head - Bank Channel, Education Loans for HDFC
                      Credila. He has 20 years of experience in Channel
                      Management and Education Loans. During his career, he
                      managed National-Level Relationships with top banks like
                      HDFC Bank, SBI, ICICI Bank, Axis Bank, Citi Bank & HSBC
                      Bank etc. for various financial products while working
                      with companies like Brinks India Pvt Ltd and HDFC Credila
                      Financial SVC Ltd.
                      <br />
                      <br />
                      Amar has completed Advanced Strategic Management from IIM
                      Kozhikode.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mediashark-home-five-about edu-about-area about-style-6  bg-color-white mb--80">
          <div className="container mediashark-animated-shape">
            <div className="row g-5 align-items-center">
              <div className="col-xl-4 col-lg-6">
                <div className="about-image-gallery og-hide">
                  <img
                    className="image-1 w-100"
                    src={`${process.env.PUBLIC_URL}/images/about/team/3.jpg`}
                    alt="co - founder"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 offset-xl-1">
                <div className="inner mt_md--40 mt_sm--20">
                  <SectionTitle title="Sahebrao Patil" />
                  <h3 className="og-font-size">Co Founder</h3>
                  <div className="feature-list-wrapper">
                    <p className="description line-before mt--40 mb--40">
                      Former Head - Mumbai Cluster, Education Loans for HDFC
                      Credila. He has 20 years of experience in Partner
                      Management and Education Loans. While at companies like
                      Citi Financial, GE Money and HDFC Credila, he had great
                      success in Creating and Managing Partners.
                      <br />
                      <br />
                      Saheb has completed his BPharm from Mumbai University.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="edu-feature-area bg-color-white edu-section-gap">
          <div className="container mediashark-animated-shape">
            <div className="row row--35">
              <div className="col-lg-7 col-12 order-2 order-lg-1">
                <div className="">
                  <SectionTitle
                    classes="text-start"
                    title="Our Advisory Board"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mediashark-home-five-about edu-about-area about-style-6  bg-color-white mb--100">
          <div className="container mediashark-animated-shape">
            <div className="row g-5 align-items-center">
              <div className="col-xl-4 col-lg-6">
                <div className="about-image-gallery og-hide">
                  <img
                    className="image-1 w-100"
                    src={`${process.env.PUBLIC_URL}/images/about/team/6.jpg`}
                    alt="advisory"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 offset-xl-1">
                <div className="inner mt_md--40 mt_sm--20">
                  <SectionTitle title="Atul Londhe" />
                  <div className="feature-list-wrapper">
                    <p className="description line-before mt--40 mb--40">
                      A professional with 30 years of experience in the field of
                      standardized tests such as the GRE, and GMAT at Dilip Oak
                      Academy, one of Pune’s leading institutions for Higher
                      Education abroad, he played a key role in the Academy’s
                      rise to prominence in its early years. In the process, he
                      counseled 850+ students for SOP, LOR and resume writing
                      and his students were accepted to institutions such as
                      MIT, Stanford, Duke, and Cornell. He also played a key
                      role in the development of the Academy’s GRE
                      Computer-Based Test, its 4-star vocabulary app, and its
                      website.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mediashark-home-five-about edu-about-area about-style-6  bg-color-white mb--80">
          <div className="container mediashark-animated-shape">
            <div className="row g-5 align-items-center">
              <div className="col-xl-4 col-lg-6">
                <div className="about-image-gallery og-hide">
                  <img
                    className="image-1 w-100"
                    src={`${process.env.PUBLIC_URL}/images/about/team/5.jpg`}
                    alt="advisory"
                  />
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 offset-xl-1">
                <div className="inner mt_md--40 mt_sm--20">
                  <SectionTitle title="Uday Oak" />
                  <div className="feature-list-wrapper">
                    <p className="description line-before mt--40 mb--40">
                      His 32-year career includes decades of experience in the
                      area of placement and personality development training
                      with 5 years as GMAT, GRE, SAT, CAT Instructor and
                      Computer Section In-Charge at one of Pune’s leading
                      institutions for Higher Education abroad. In addition, his
                      profile also includes wide and varied experience,
                      including stints as Founder Director, Acuminate Consulting
                      & Technologies Pvt. Ltd.; COO, FinIQ Consulting (I)
                      Private Limited, Pune; and Head, Competency Management
                      Center, Rapidigm India Ltd, a Fujitsu Consulting Company.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HomeFiveProgress />
        <AboutOne />
        <CounterUpOne />
      </Layout>
    </>
  );
};

export default AboutUsThree;
