import React from "react";
import SBILoanEligibility from "./SBILoanEligibility";

const LoanEligibility = () => {
  return (
    <div className="features" id="loan-eligibility">
      <h3>Loan Eligibility</h3>
      <SBILoanEligibility />
    </div>
  );
};

export default LoanEligibility;
