import React from "react";
import HeaderTwo from "../../common/header/HeaderTwo";
import Forex from "./Forex";
import Insurance from "./Insurance";
import Accomodation from "./Accomodation";
import AirTicketing from "./AirTicketing";
import Layout from "../../common/Layout";
import AccountOpening from "./AccountOpening";
import "../../assets/css/alliedservices.css";

const AlliedServices = () => {
  return (
    <>
      <HeaderTwo styles="header-transparent header-style-2" />

      <Layout>
        <div className="allied-services">
          <Forex />
          <Insurance />
          <Accomodation />
          <AirTicketing />
          <AccountOpening />
        </div>
        <p
          style={{
            margin: "10px",
          }}>
          T&C Apply*
        </p>
      </Layout>
    </>
  );
};

export default AlliedServices;
