import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import EventData from "../../data/event/EventData.json";
import InstructorData from "../../data/instructor/InstructorData.json";
import CourseData from "../../data/course/CourseData.json";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import AboutSix from "../../components/about/AboutSix";
import { slugify } from "../../utils";
import CurriculumTabContent from "../../data/course/CurriculumTabContent.json";

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button">
      {children}
    </button>
  );
};

const CurriculumContent = () => {
  const [activeId, setActiveId] = useState("0");

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
      {CurriculumTabContent.map((accordion, i) => (
        <Accordion.Item
          eventKey={i.toString()}
          key={i}
          onClick={() => toggleActive(i.toString())}
          bsPrefix={`edu-accordion-item ${
            activeId === i.toString() ? "bg-active" : ""
          }`}>
          <div className="edu-accordion-header">
            <CustomToggle eventKey={i.toString()}>
              {accordion.title}
            </CustomToggle>
          </div>
          <Accordion.Body bsPrefix="edu-accordion-body">
            <ul>
              {accordion.content.map((title, index) => (
                <li key={index}>
                  <div className="text">
                    <i className="icon-draft-line"></i>
                    {title}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const StudyInGremanyDreamz = () => {
  const { id } = useParams();
  const eventId = parseInt(1, 10);
  const data = EventData.filter((event) => event.id === eventId);
  const eventItem = data[0];

  const [activeId, setActiveId] = useState("0");
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const courseId = parseInt(1, 10);
  const dataCourse = CourseData.filter((course) => course.id === courseId);
  const courseItem = dataCourse[0];

  const indexOfInstructor = InstructorData.findIndex(function (instructor) {
    return slugify(instructor.name) === slugify(courseItem.instructor);
  });
  const instructor = InstructorData[indexOfInstructor];
  const instructorExcerpt = instructor.details.substring(0, 190) + "...";

  const [contentTab, setContentTab] = useState("overview");
  const handleTab = (content) => {
    if (content === "overview") {
      setContentTab("overview");
    } else if (content === "curriculum") {
      setContentTab("curriculum");
    } else if (content === "instructor") {
      setContentTab("instructor");
    } else if (content === "reviews") {
      setContentTab("reviews");
    }
  };

  return (
    <>
      {/* <SEO title={eventItem.title} /> */}
      <SEO
        title="Study in Germany - Globaldreamz"
        description="A comprehensive guide for students aspiring to study in Germany. Read this guide to know about the student visa, and more."
        keywords="Study in Germany, Germany Education, German Universities, Higher Education in Germany, Study Abroad Germany, German Language Courses, Scholarships in Germany, German Education System, Study Programs in Germany, Study Opportunities in Germany, German Culture and Education."
        canonical="https://globaldreamzedu.com/study-in-germany"
      />
      <Layout>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_12.jpg`}
                    alt="study germany students"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h3 className="title">Why should you Study in Germany?</h3>
                  <p>
                    Germany is one of the best destinations for international
                    education. Every year many international students, for both
                    graduate and undergraduate studies flock to this European
                    nation to get a world-class education. Apart from being a
                    wonderful place to study, Germany is also one of the best
                    European nations which have undergone rapid
                    industrialisation and you can see many technological marvels
                    here. The German auto industry is also one of the reputed
                    industries all around the world. The country also boasts one
                    of the highest employability ratios in the European
                    continent, which is why Germany is a highly sought-after
                    country for the ones looking for aboard education.
                  </p>
                  <p>
                    From the perspective of education also, Germany has some
                    unique traits that serve as the USP for being the most
                    preferred foreign destination. There are Technische
                    Universität München, Universität Hamburg and
                    Ludwig-Maximilians - Universität München which rank high in
                    the QS rankings. These universities are also among the top
                    50 universities all across the world.
                  </p>
                  <p>
                    The education sector of Germany is highly advanced in
                    healthcare, information technology, automobile sciences,
                    biotechnology, energy sciences, and chemical engineering.
                    German architecture and civil engineering have worldwide
                    popularity. Artificial intelligence and Machine Learning are
                    also making a big-time impact in the country. No wonder the
                    flow of students is being directed toward Europe,
                    specifically Germany these days.
                  </p>
                  <p>
                    The culture of Germany is also friendly towards foreigners.
                    To know more about Germany as an educational destination,
                    contact our counsellors at GlobalDreamz.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <h4>Why Opt for Career Counselling?</h4>

                <p>
                  Staying in contact with foreign education consultants is
                  extremely necessary because they can assist you in multiple
                  ways for helping you achieve your overseas education goals.
                </p>
              </div>

              <div className="col-lg-12">
                <h4>Career Guidance</h4>
                <p>
                  Based on your academic profile and what you wish to achieve
                  out of your overseas education, our global study counsellors
                  can guide you in picking up the right programmes and preferred
                  universities/colleges. They update you with all the required
                  information.
                </p>

                <h4>Test Preparation</h4>
                <p>
                  If you wish to study in Germany, then you need to prove that
                  you are proficient in the German language as many programmes
                  and courses have German as their main medium of instruction.
                  So you need to give the Deutsche Sprachprüfung für den
                  Hochschulzugang or DSH, which is a German language examination
                  for entrance to universities. Then there is the TestDaF or
                  test in Deutsche as a foreign language. For courses in
                  English, you could give IELTS or TOEFL. There are also the
                  Hochschulzugangsberechtigung (HZB) and the
                  Feststellungsprüfung exam after the Studienkolleg preparation.
                  You get a course to prepare for all these.
                </p>

                <h4>University and Course Selection</h4>
                <p>
                  GlobalDreamz offers guidance to students to choose the
                  appropriate universities and courses so that it increases
                  their chances of employment on an international platform. Our
                  entire study abroad counselling staff has the accreditation
                  and experience dealing with international students and has
                  helped many students to make the best programme choices in
                  Germany.
                </p>

                <h4>Visa and Financial Help</h4>
                <p>
                  Procuring a German study visa is a tough nut to crack. It
                  requires a lot of paperwork and formalities to be completed.
                  Surely, you would love to have additional help. You need
                  consultation services that walk you through the entire
                  process. They will also help to compose the necessary
                  paperwork and help in the timely submission of the visa
                  application. You are also trained for visa interviews in
                  Germany. The best and the most experienced study abroad
                  advisors also assist you in getting the required scholarship
                  and financial assistance.
                  <br></br>
                  <br></br>
                  Would you like to study in Germany at one of the best German
                  universities? GlobalDreamz Study Abroad Counselling can help
                  you in fulfilling your international education dreams.
                </p>
              </div>
            </div>
          </div>

          {/* <TestimonialSectionFour /> */}
        </div>
        <AboutSix />
      </Layout>
    </>
  );
};
export default StudyInGremanyDreamz;
