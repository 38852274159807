import React from "react";

const Expenses = () => {
  return (
    <div className="tile" id="expenses">
      {/* create a dot */}
      <li> Expenses Considered for SBI Education Loan:</li>
      <ol className="expense-list">
        <li>Fee payable to college/school/hostel</li>
        <li>Tuition and course fee</li>
        <li>Examination, laboratory, and library fees</li>
        <li>Caution deposit</li>
        <li>Cost of books, equipment, and other instruments.</li>
        <p
          style={{
            position: "relative",
            right: "20px",
            margin: "0px 0px 0px 0px",
            fontWeight: "bold",
          }}>
          Some expenses that are considered only for the student loan scheme and
          not for the vocational training loan.
        </p>

        <li>Cost of a two-wheeler for traveling up to Rs.50,000</li>
        <li>Travel expenses and passage money for studies in abroad</li>
        <li>
          Caution deposit, other refundable deposit, and building fund which
          amounts up to 10 percent of the tuition fees for the entire semester
        </li>
        <li>
          Any other expenses required to complete the coursework such as study
          tours, project, and thesis
        </li>
      </ol>
    </div>
  );
};

export default Expenses;
