import React from "react";
import { AiOutlineRight } from "react-icons/ai";

export const menuData = [
  {
    title: "Features",
    id: "Vision",
    subMenu: [
      {
        id: "features-benefits-baroda-vidya",
        title: "Pan India Reach ",
        content: (
          <p>
            To be the preferred financial solutions provider excelling in
            customer delivery through insight, empowered employees & smart use
            of technology.
          </p>
        ),
      },
      {
        id: "attractive-interest-rate",
        title: "Attractive Interest Rate",
        content: (
          <p>
            Axis Bank provides a very competitive interest rate in the Private
            Banking Sector for both Collateral & Non Collateral Loans. Presently
            the ROI (Rate Of Interest) range is between 11.00 % to 13.00 % for
            Secured Loans (Negotiable) & between 11.50 % to 13.00 % for
            Unsecured Loans (Negotiable).
          </p>
        ),
      },
      {
        id: "flexible-loan-amount",
        title: "High Loan Amount",
        content: (
          <p>
            Axis Bank is one of the private banks that can give maximum loan
            amount <b>(No Upper Limit for Secured)</b> & up to Rs.40 Lakhs
            <b>(Unsecured)</b>. They fund for Undergraduate Courses, which has a
            higher requirement, as not all Financial Institutions do the same.
          </p>
        ),
      },
      {
        id: "security-of-loan-secured-loan",
        title: "Consideration On Basis Of (Secured Loan) ",
        content: (
          <p>
            Property (Not Agricultural Land) , FD's, Traditional LIC policy,
            Mutual Funds, Shares & Government Bonds.
          </p>
        ),
      },
      {
        id: "security-of-loan-unsecured-loan",
        title: "Consideration On Basis Of (Unsecured Loan)",
        content: (
          <p>
            <b>Selective Universities as per their Prime List</b>, the students
            profile, their competitive exam scores, University & Course applied
            for, Co-Applicants (Blood Relations) IT Returns & Cibil Report.
          </p>
        ),
      },
      {
        id: "tax-benefits",
        title: "Tax Benefits",
        content: (
          <p>
            Interest paid on education loan to bank is Tax waived under section
            80E. This benefit is available to the Co-Applicant / Co-Signor. This
            deduction under Section 80E is over & above the Rs.1.50 Lakhs
            deduction allowed under Section 80C & there is no maximum limit for
            claiming this deduction under Section 80E.
          </p>
        ),
      },
      {
        id: "margin-money",
        title: "Margin Money",
        content: (
          <p>
            This is the amount that a student is supposed to pay from their side
            on every disbursement. Axis Bank requires that the student
            compulsorily pays 15 % of the cost of education on their own. They
            would be ready to fund up to a maximum of 85 % of the total loan
            required by the student.
          </p>
        ),
      },
      {
        id: "loan-to-value",
        title: "Loan To Value",
        content: (
          <p>
            Axis Bank provides approximately 85 % of the value of the Mortgaged
            Collateral (If Co-Applicant is Salaried) & up to 75 % of the value
            of the Mortgaged Collateral (If Co-Applicant is Self Employed) & up
            to 65 % for unapproved university's. They do the latest property
            evaluation & provide loan maximum up to 85 % for the student
          </p>
        ),
      },
      {
        id: "repayment",
        title: "Repayment-period",
        content: (
          <p>
            They have a long term for the repayment of the Loan which is up to
            15 years (180 months) & it starts after the Moratorium Period gets
            over.
          </p>
        ),
      },
      {
        id: "moratorium-period",
        title: "Moratorium Period",
        content: (
          <p>
            Moratorium on repayment during study period available + 6 Months
            (for Unsecured Loans) & 1 Year after completion of course (for
            Secured Loans).
          </p>
        ),
      },
      {
        id: "loan-preceeds",
        title: "Loan Proceeds ",
        content: (
          <p>
            Door Step Home Service Available. Initially the documents are
            submitted by students to Dedicated Relationship Managers (RM’s) who
            are linked with the Loan Centre’s (Area Wise) & have The Credit Team
            / Underwriters, who get the Valuation & Legal Formalities done, post
            which they decide on the Sanctioning of the Loan. Once the loan is
            Sanctioned, Disbursements happen from these Loan Centre’s.
          </p>
        ),
      },
      {
        id: "credit-grading",
        title: "Credit Grading",
        content: (
          <p>
            For Secured Loan the bank looks at Students Profile, Parents IT
            Returns, Cibil Report & Collateral Security. Axis Bank is heavily
            dependent on the security provided for the loan followed by the
            student profile. Weak IT returns of parents are sometimes considered
            in case the former are strong. Additional Co-applicants / Co-signors
            can even be a 3rd party instead of a relative, if their Collateral
            Mortgage has to be considered.
          </p>
        ),
      },
      {
        id: "time",
        title: "Time for processing",
        content: (
          <p>
            Axis Bank is a large organization & Education Loans are done through
            their Loan Centre’s which are faster compared to other banks. The
            average turnaround time is at least 7 working days (for Secured
            Loans) & 4 working days (for Unsecured Loans).
          </p>
        ),
      },
      {
        id: "low-fees",
        title: "Low processing fees",
        content: (
          <p>
            Very economical processing fees of Rs.15,000 + GST (Refundable) for
            amount of loan up to 20 Lakhs. If the loan amount is above 20 Lakhs
            there would be no processing fees for the initial 20 Lakhs & 0.75 %
            processing fees for anything above 20 Lakhs (Non Refundable). These
            are the same for both Secured & Unsecured Loan Options. Property
            Valuation & Legal charges are extra for the Secured Loan.
          </p>
        ),
      },
      {
        id: "universities-funded",
        title: "Universities Funded",
        content: (
          <p>
            Axis Bank funds majority of the universities & also majority of the
            courses under Secured Loans. They fund only{" "}
            <b>Selective Universities as per their Prime List</b> under
            Unsecured Loans.
          </p>
        ),
      },
      {
        id: "universities-funded",
        title: "Universities Funded",
        content: (
          <p>
            They can give a Sanction Letter prior to receiving I20 for US.
            Validity of Sanction Letter is up to 6 months for Secured & up to 2
            months for Unsecured (Can be extended).
          </p>
        ),
      },
      {
        id: "sanction-letter",
        title: "Sanction Letter",
        content: (
          <p>
            They can give a Sanction Letter prior to receiving I20 for US.
            Validity of Sanction Letter is up to{" "}
            <b>6 months for Secured & up to 2 months for Unsecured</b> (Can be
            extended).
          </p>
        ),
      },
      {
        id: "collateral-facility",
        title: "Partial Collateral Facility",
        content: (
          <p>
            If the amount of loan required is more than the Unsecured
            eligibility for a student then additional amount can be taken by
            mortgaging a Collateral (Non Agricultural Property, FD’s, LIC
            Policy, Shares, Mutual Funds, Govt. Bonds).
          </p>
        ),
      },
      {
        id: "balance-transfer-facility",
        title: "Balance Transfer Facility",
        content: (
          <p>
            An existing loan of a customer that’s going on with some other
            Financial Institution can be taken over by doing a payment to the
            latter of the full remaining Balance.
          </p>
        ),
      },
    ],
  },
];

const LeftSidebar = () => {
  return (
    <div className="left-sidebar" style={{ padding: "24px" }}>
      <div className="index-menu-bar">
        {menuData.map((item, index) => (
          <ul key={index} className="menu">
            <div className="menu-title">
              <a href={`#${item.id}`}>{item.title}</a>
            </div>
            <div className="submenu">
              {item.subMenu.map((subMenuItem, subIndex) => (
                <li key={subIndex}>
                  <a href={`#${subMenuItem.id}`}>
                    <AiOutlineRight /> {subMenuItem.title}
                  </a>
                </li>
              ))}
            </div>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default LeftSidebar;
