import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import EventData from "../../data/event/EventData.json";
import InstructorData from "../../data/instructor/InstructorData.json";
import CourseData from "../../data/course/CourseData.json";
import CategoryMba from "../../components/category/CategoryMba";
import ScrollAnimation from "react-animate-on-scroll";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import TestimonialSectionFour from "../../components/testimonial-section/TestimonialSectionFour";
import AboutSix from "../../components/about/AboutSix";
import { slugify } from "../../utils";
import CurriculumTabContent from "../../data/course/CurriculumTabContent.json";

const accordionItems = [
  {
    id: 1,
    title: "1.	Should I take IELTS before studying abroad?",
    body: "Yes, in almost all foreign institutions, English language assessments are required. Hence, students are required to submit IELTS, TOEFL, and PTE scores to universities.",
  },
  {
    id: 2,
    title: "2.	What is the procedure for applying to a university?",
    body: "University applications might be difficult. To expedite the procedure, you can: Do some research on the nation, college, and courses you intend to enrol in,	Choose a course, then apply to the university.Get a visa and get checked out for health (if needed Accepting a proposal",
  },
  {
    id: 3,
    title: "3.	How long do study abroad programs take?",
    body: "Your degree level will determine what you can do. The average undergraduate degree takes about three to four years to complete if you plan to study full-time. In the UK, most subjects take three years to complete. In the US, the subject lasts for four years. ",
  },
  {
    id: 4,
    title: "4.	How much does the undergrad course charge?",
    body: "If you are a student at a foreign university, you may be required to pay a tuition charge along with a semester or annual fee. In addition, you will be responsible for paying for your rent, food, and other necessities.",
  },
];

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button">
      {children}
    </button>
  );
};

const CurriculumContent = () => {
  const [activeId, setActiveId] = useState("0");

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
      {CurriculumTabContent.map((accordion, i) => (
        <Accordion.Item
          eventKey={i.toString()}
          key={i}
          onClick={() => toggleActive(i.toString())}
          bsPrefix={`edu-accordion-item ${
            activeId === i.toString() ? "bg-active" : ""
          }`}>
          <div className="edu-accordion-header">
            <CustomToggle eventKey={i.toString()}>
              {accordion.title}
            </CustomToggle>
          </div>
          <Accordion.Body bsPrefix="edu-accordion-body">
            <ul>
              {accordion.content.map((title, index) => (
                <li key={index}>
                  <div className="text">
                    <i className="icon-draft-line"></i>
                    {title}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const MbaDreamz = () => {
  const { id } = useParams();
  const eventId = parseInt(1, 10);
  const data = EventData.filter((event) => event.id === eventId);
  const eventItem = data[0];

  const [activeId, setActiveId] = useState("0");
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const courseId = parseInt(1, 10);
  const dataCourse = CourseData.filter((course) => course.id === courseId);
  const courseItem = dataCourse[0];

  const indexOfInstructor = InstructorData.findIndex(function (instructor) {
    return slugify(instructor.name) === slugify(courseItem.instructor);
  });
  const instructor = InstructorData[indexOfInstructor];
  const instructorExcerpt = instructor.details.substring(0, 190) + "...";

  const [contentTab, setContentTab] = useState("overview");
  const handleTab = (content) => {
    if (content === "overview") {
      setContentTab("overview");
    } else if (content === "curriculum") {
      setContentTab("curriculum");
    } else if (content === "instructor") {
      setContentTab("instructor");
    } else if (content === "reviews") {
      setContentTab("reviews");
    }
  };

  return (
    <>
      {/* <SEO title={eventItem.title} /> */}
      <SEO
        title="Undergraduate study abroad consultants in pune- GlobalDreamz"
        description="Get the top-notch undergraduate study abroad consultants in Pune for invaluable guidance and support."
        keywords="undergraduate best business schools, undergraduate courses abroad for indian students, undergraduate study abroad consultants in pune, best undergraduate business schools in the world"
        canonical="https://globaldreamzedu.com/undergrad"
      />
      <Layout>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_3.jpg`}
                    alt="undergrad students"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="content">
                  <h1 className="title">
                    Why should you do Undergraduate Studies abroad?
                  </h1>
                  <p>
                    To polish your strengths, gain a new perspective, and in
                    formulating an actionable plan that serves as the foundation
                    for a successful career path and your future, the
                    undergraduate years are a perfect time. An undergraduate
                    degree allows you to develop essential skills, learn about
                    new strategies, and prepare a long-term plan. If you wish to
                    join the global workforce, you must possess cross-cultural
                    communication skills. Pursuing your undergrads overseas
                    gives you the required confidence and grooms you for a
                    global life. Interested in studying Undergraduate degree
                    abroad? Get in touch with our Undergraduate Counsellors at
                    GlobalDreamz today to learn more about the admissions
                    process and how to apply!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <h4>Five major elements that we offer:</h4>
                <ul className="list-style-1 mb--20">
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Rich Experience – Learn from A Team with A Collective
                    Experience of Over 100+ Years
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Customised Flexibility – Rolling Batches with Timings
                    Designed to Suit the Students
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Personal Attention – Get Your Own Counsellor to Help You
                    Deal with All Your Queries and Difficulties
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>{" "}
                    Comprehensive Solutions – From Test Prep to Financial Help
                    to Arrival in Your Study Destination
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Online
                    Services - Learn Online with A State of Art System from The
                    Comfort of Your Own Home
                  </li>
                </ul>
                <p>
                  The goal of counselling is to identify an individual's unique
                  attributes that will set him or her apart from others, and
                  assist in getting into the school of their choice. During the
                  application season, multiple interactions are conducted with
                  the applicant.
                </p>

                <CategoryMba />
              </div>

              <div className="col-12">
                <div className="edu-accordion-area mediashark-home-three-accordion accordion-shape-1 edu-section-gap bg-color-white">
                  <div className="container mediashark-animated-shape">
                    <div className="row g-5">
                      <div className="col-lg-6 gallery-wrapper">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="thumbnail">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-01.jpg`}
                                alt="About Thumb 1"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 mt--35 mt_sm--15">
                            <div className="thumbnail">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-02.jpg`}
                                alt="students discussion"
                              />
                            </div>
                            <div className="thumbnail mt--15">
                              <img
                                className="radius-small w-100"
                                src={`${process.env.PUBLIC_URL}/images/about/about-02/accordion-gallery-03.jpg`}
                                alt="students discussion"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <SectionTitle
                          classes="text-start mb--40"
                          slogan="FAQs"
                          title="Get Every General Answers From Here"
                        />
                        <ScrollAnimation
                          animateIn="fadeInUp"
                          animateOut="fadeInOut"
                          className="accordion-style-1"
                          animateOnce={true}>
                          <Accordion
                            bsPrefix="edu-accordion"
                            defaultActiveKey="0"
                            flush>
                            {accordionItems.map((accordion, i) => (
                              <Accordion.Item
                                eventKey={i.toString()}
                                key={i}
                                onClick={() => toggleActive(i.toString())}
                                bsPrefix={`edu-accordion-item ${
                                  activeId === i.toString() ? "active-item" : ""
                                }`}>
                                <div className="edu-accordion-header">
                                  <CustomToggle eventKey={i.toString()}>
                                    {accordion.title}
                                  </CustomToggle>
                                </div>
                                <Accordion.Body bsPrefix="edu-accordion-body">
                                  {accordion.body}
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </ScrollAnimation>
                      </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                      <div className="shape shape-1">
                        <span className="shape-dot"></span>
                      </div>
                      <div className="shape-image shape-image-2">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/shapes/shape-11-04.png`}
                          alt="Shape Thumb"
                        />
                      </div>
                      <div className="shape-image shape-image-3">
                        <img
                          src={`${process.env.PUBLIC_URL}/images/shapes/shape-25-01.png`}
                          alt="Shape Thumb"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="course-details-content">
                  <h3 className="title">Our Services</h3>
                  <ul className="edu-course-tab nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "overview"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Overview"
                        onClick={() => handleTab("overview")}>
                        Course Description
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "curriculum"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Curriculum"
                        onClick={() => handleTab("curriculum")}>
                        Curriculum
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "instructor"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Instructor"
                        onClick={() => handleTab("instructor")}>
                        Instructor
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={
                          contentTab === "reviews"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        type="button"
                        aria-label="Reviews"
                        onClick={() => handleTab("reviews")}>
                        Reviews
                      </button>
                    </li>
                  </ul>

                  <div className="tab-content">
                    {contentTab === "overview" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "overview" ? "active" : ""
                        } `}>
                        {/* <div
                          className="course-tab-content"
                          dangerouslySetInnerHTML={{
                            __html: courseItem.details,
                          }}
                        /> */}
                        <div className="course-tab-content">
                          <h6>Overview</h6>
                          <p>
                            The Undergrad is divided into several categories
                            based on the comfort and affordability of the
                            students to stay away from the job market, such as -
                            Full-time Undergrad which is for students who are
                            willing to leave the workforce and devote their
                            full-time to weekday classes and regular studies,
                            and the other one is a Part-time Undergrad option
                            for aspirants who want to advance their careers by
                            learning managerial skills and additional degree
                            while still working.
                          </p>

                          <h6>What will you learn from this course?</h6>
                          <p>
                            {" "}
                            Undergraduate programmes foster the development of
                            transferrable abilities with a wide range of
                            applications, such as critical thinking,
                            problem-solving, communication, cooperation, and
                            independence, even though some of your learning will
                            be research-specific.
                          </p>

                          <h6>Certification</h6>
                          <p>
                            Earn degrees from the top colleges around the world
                            which are globally recognised and approved, helping
                            you to progress faster.
                          </p>
                        </div>
                        <br></br>
                      </div>
                    )}

                    {contentTab === "curriculum" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "curriculum" ? "active" : ""
                        } `}>
                        <div className="course-tab-content">
                          {/* <CurriculumContent /> */}
                          <p>
                            The primary goal of an MBA is to educate students in
                            Accounting, Marketing, Human Resources, and
                            Operations Management.
                          </p>
                          <p>
                            <b>An MBA will cover the following topics:</b>
                          </p>
                          <ul>
                            <li>Introduction to Marketing </li>
                            <li>Micro Economics </li>
                            <li>Accounting</li>
                            <li>Human resource management </li>
                            <li>Business Law</li>
                            <li>Introduction to Finance</li>
                            <li>Operations and Information management </li>
                            <li>Organizational Behaviour </li>
                          </ul>
                        </div>
                      </div>
                    )}

                    {contentTab === "instructor" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "instructor" ? "active" : ""
                        } `}>
                        <div className="course-tab-content">
                          <div className="course-author-wrapper">
                            <div className="thumbnail">
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  `/instructor-details/${slugify(
                                    courseItem.instructor
                                  )}`
                                }>
                                <img
                                  src={`${process.env.PUBLIC_URL}/images/instructor/course-details/${instructor.image}`}
                                  alt="Author Thumb"
                                />
                              </Link>
                            </div>
                            <div className="author-content">
                              <h6 className="title">
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    `/instructor-details/${slugify(
                                      courseItem.instructor
                                    )}`
                                  }>
                                  {instructor.name}
                                </Link>
                              </h6>
                              <span className="subtitle">
                                {instructor.designation}
                              </span>
                              <p>{instructorExcerpt}</p>
                              <ul className="social-share border-style">
                                <li>
                                  <a href={instructor.facebookUrl}>
                                    <i className="icon-Fb"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={instructor.linkedInUrl}>
                                    <i className="icon-linkedin"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={instructor.pinterest}>
                                    <i className="icon-Pinterest"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href={instructor.twitterUrl}>
                                    <i className="icon-Twitter"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {contentTab === "reviews" && (
                      <div
                        className={`tab-pane fade show ${
                          contentTab === "reviews" ? "active" : ""
                        } `}>
                        <div className="course-tab-content">
                          <div className="row row--30">
                            <div className="col-lg-4">
                              <div className="rating-box">
                                <div className="rating-number">
                                  {courseItem.rating}
                                </div>
                                <div className="rating mediashark-course-rating-stars">
                                  <i className="icon-Star"></i>
                                  <i className="icon-Star"></i>
                                  <i className="icon-Star"></i>
                                  <i className="icon-Star"></i>
                                  <i className="icon-Star"></i>
                                </div>
                                <span>({courseItem.review} Review)</span>
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <div className="review-wrapper">
                                <div className="single-progress-bar">
                                  <div className="rating-text">
                                    5 <i className="icon-Star"></i>
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{ width: "100%" }}
                                      aria-valuenow="100"
                                      aria-valuemin="0"
                                      aria-valuemax="100"></div>
                                  </div>
                                  <span className="rating-value">1</span>
                                </div>

                                <div className="single-progress-bar">
                                  <div className="rating-text">
                                    4 <i className="icon-Star"></i>
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{ width: "0%" }}
                                      aria-valuenow="0"
                                      aria-valuemin="0"
                                      aria-valuemax="100"></div>
                                  </div>
                                  <span className="rating-value">0</span>
                                </div>

                                <div className="single-progress-bar">
                                  <div className="rating-text">
                                    3 <i className="icon-Star"></i>
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{ width: "0%" }}
                                      aria-valuenow="0"
                                      aria-valuemin="0"
                                      aria-valuemax="100"></div>
                                  </div>
                                  <span className="rating-value">0</span>
                                </div>

                                <div className="single-progress-bar">
                                  <div className="rating-text">
                                    2 <i className="icon-Star"></i>
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{ width: "0%" }}
                                      aria-valuenow="0"
                                      aria-valuemin="0"
                                      aria-valuemax="100"></div>
                                  </div>
                                  <span className="rating-value">0</span>
                                </div>

                                <div className="single-progress-bar">
                                  <div className="rating-text">
                                    1 <i className="icon-Star"></i>
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{ width: "0%" }}
                                      aria-valuenow="0"
                                      aria-valuemin="0"
                                      aria-valuemax="100"></div>
                                  </div>
                                  <span className="rating-value">0</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="comment-wrapper pt--40">
                            <div className="section-title">
                              <h5 className="mb--25">Reviews</h5>
                            </div>
                            <div className="edu-comment">
                              <div className="thumbnail">
                                <img
                                  src={`${process.env.PUBLIC_URL}/images/course/student-review/student-1.png`}
                                  alt="Student Thumb"
                                />
                              </div>
                              <div className="comment-content">
                                <div className="comment-top">
                                  <h6 className="title">Elen Saspita</h6>
                                  <div className="rating mediashark-course-rating-stars">
                                    <i className="icon-Star"></i>
                                    <i className="icon-Star"></i>
                                    <i className="icon-Star"></i>
                                    <i className="icon-Star"></i>
                                    <i className="icon-Star"></i>
                                  </div>
                                </div>
                                <span className="subtitle">
                                  “ Outstanding Course ”
                                </span>
                                <p>
                                  As Thomas pointed out, Chegg’s survey appears
                                  more like a scorecard that details obstacles
                                  and challenges that the current university
                                  undergraduate student population is going
                                  through in their universities and countries.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <TestimonialSectionFour /> */}
        </div>
        <AboutSix />
      </Layout>
    </>
  );
};
export default MbaDreamz;
