import React from "react";
import { AiOutlineRight } from "react-icons/ai";

export const menuData = [
  {
    title: "Who can apply?",
    id: "eligibility",
    subMenu: [
      {
        id: "creteria",
        title: "Eligibility Criteria",
        content: (
          <p>
            <ol className="expense-list">
              <li>An Indian National (Including NRI)</li>
              <li>18 years old or above at the time of loan commencement</li>
              <li>
                Admitted in the desired institution after completion of the pre
                requisite qualifications
              </li>
              <li>Admission not confirmed yet, Do not worry</li>
              <li>
                If the admission is not secured, sanction could be subjective
                based on the type of course, country or the institute student
                has applied for
              </li>
            </ol>
          </p>
        ),
      },
    ],
  },

  {
    title: "Documentation",
    id: "documents-required",
    subMenu: [
      {
        id: "student",
        title: "Student",
        content: (
          <p>
            <ul className="expense-list">
              <li>Photo ID proof </li>
              <li>Proof of residence </li>
              <li>Passport sized photograph </li>
              <li>Proof of admission </li>
              <li>Fee structure document </li>
              <li>Salary slips of experienced candidates </li>
              <li>Visa copy </li>
              <li>Academic documents </li>
            </ul>
          </p>
        ),
      },
      {
        id: "co-applicant",
        title: "Co-applicant",
        content: (
          <p>
            <ul className="expense-list">
              <li>Photo ID proof</li>
              <li>Proof of residence</li>
              <li>Passport sized photograph</li>
              <li>Income related documents</li>
            </ul>
          </p>
        ),
      },
      {
        id: "Collateral",
        title: "Collateral",
        content: (
          <p>
            <ul className="expense-list">
              <li>Property documents</li>
              <li>FD documents</li>
            </ul>
          </p>
        ),
      },
    ],
  },

  {
    title: "FAQs",
    id: "bob-loan-faqs",
    subMenu: [
      {
        id: "bob-loan-faqs-intro",
        title: "Most Common FAQs",
        content: (
          <p>
            <div>
              <b>
                {" "}
                1. Which countries are covered under education loan for abroad
                studies?
              </b>{" "}
              <br />
              IDFC FIRST Bank’s student loan for studying abroad could be
              availed for studying in a number of countries – USA, UK, Canada,
              Australia, Germany, New Zealand, Singapore and others. You can
              reach IDFC FIRST Bank’s representative to know the full list of
              countries.
            </div>{" "}
            <br />
            <div>
              <b>
                {" "}
                2. What are the costs covered under the student loan for
                studying abroad?
              </b>{" "}
              <br />
              The student loan for studying abroad would include the following
              expenses towards the course: <br />
              <br />
              <b> College related expenses:</b>
              <ul className="expense-list">
                <li>Tuition and Fees payable to the institute</li>
                <li>Examination / library / laboratory fees</li>
                <li>Purchase of books / Equipment / uniforms</li>
                <li>Living expenses (including Hostel fees)</li>
              </ul>
              <b> Additional Expenses:</b>
              <ul className="expense-list">
                <li>Travel expenses</li>
                <li>Purchase of computers/laptops</li>
                <li>Overseas Insurance</li>
                <li>Cost of health insurance</li>
              </ul>
            </div>{" "}
            <br />
            <div>
              <b>
                {" "}
                3. What are the type of courses that are funded by IDFC FIRST
                Bank education loan for abroad studies?
              </b>{" "}
              <br />
              IDFC FIRST Bank’s education loan for abroad studies covers
              plethora of courses across graduation and post-graduation degrees.
              The list includes technical and professional courses offered by
              the recognized universities and autonomous institutions. Apart
              from the full time courses, we fund education for executive, part
              time, vocational and diploma courses as well.
            </div>{" "}
            <br />
            <div>
              <b>
                {" "}
                4. Do I get tax benefits with student loan for studying abroad?
              </b>{" "}
              <br />
              Yes, you get income tax benefits under education loan for abroad
              studies as well.
            </div>
            <br />
            <div>
              <b>
                {" "}
                5. How much of the education cost will be funded by ICICI Bank
                Education Loan?
              </b>{" "}
              <br />
              ICICI Bank covers 100% of the ICICI Bank education loan up to ₹ 20
              lakh. After that there will be a margin of 15%.
            </div>
            <br />
            <div>
              <b> 6. Does ICICI Bank student loan only cover tuition fee?</b>{" "}
              <br />
              ICICI Bank education loans cover hostel fee, lab fee, and even a
              study tour or laptop if it is required to finish a course.
            </div>
            <br />
            <div>
              <b> 7. Does ICICI Bank student loan cover travel expenses?</b>{" "}
              <br />
              Yes.
            </div>
            <br />
            <div>
              <b> 8. Does ICICI Bank allow prepayment or part payment?</b>{" "}
              <br />
              ICICI Bank has not placed any restrictions on pre-payment or part
              payment of ICICI Bank overseas education loan.
            </div>
            <br />
            <div>
              <b>
                {" "}
                9. How soon will the loan be disbursed for ICICI Bank overseas
                education loan?
              </b>{" "}
              <br />
              The applicants can expect a pre-visa disbursal of ICICI Bank
              overseas education loan amount.
            </div>
          </p>
        ),
      },
    ],
  },
];

const LeftSidebar = () => {
  return (
    <div className="left-sidebar" style={{ padding: "24px" }}>
      <div className="index-menu-bar">
        {menuData.map((item, index) => (
          <ul key={index} className="menu">
            <div className="menu-title">
              <a href={`#${item.id}`}>{item.title}</a>
            </div>
            <div className="submenu">
              {item.subMenu.map((subMenuItem, subIndex) => (
                <li key={subIndex}>
                  <a href={`#${subMenuItem.id}`}>
                    <AiOutlineRight /> {subMenuItem.title}
                  </a>
                </li>
              ))}
            </div>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default LeftSidebar;
