import React from "react";

const SBILoanFeatures = () => {
  return (
    <div
      className="tile"
      id="student"
      style={{
        margin: 0,
      }}>
      {/* create a dot */}
      <ol>
        <li>Loan Amount</li>
        <p>
          The State Bank of India provides secured loans above 20 lakhs upto
          1.25 crore with deviation for preferential list universities.
        </p>

        <li>Percentage of Loan Against Collateral Provided</li>
        <p>
          The bank provides a loan amount upto 90 percent of Property’s
          Realizable Value and FD value and loan upto 90 percent of the LIC
          Surrender value and KPV and NSC Value.
        </p>

        <li>Collateral Property</li>
        <p>
          The State Bank of India provides secured loans above 20 lakhs upto
          1.25 crore with deviation for preferential list universities.
        </p>

        <li>Student’s Profile</li>
        <p>
          To provide <b>State Bank of India education loan</b> to any applicant,
          the bank will check the academic background of the student (no. of KTs
          throughout his last education) along with the course and institution
          he is applying for.
        </p>
        <li>Interest Rates</li>
        <p>
          The current interest rate is{" "}
          <b>10.20 % (3 Yr.s MCLR + 2.00 % Spread)</b>. The interest rate varies
          with the change in MCLR. SBI further offers 0.50 % Concession (Girl
          Student) as well as a 0.50 % Discount against Rinn Raksha or any other
          existing policy assigned in favour of{" "}
          <b>SBI(SBI Life) Insurance Policy</b>. The Rin Raksha is generally 1 %
          of the Loan Amount.
        </p>
        <li>Interest Paying During Moratorium Period</li>
        <p>
          The bank also provides an option of paying the interest on SBI
          overseas education loan during moratorium period or along with EMIs.
        </p>
      </ol>
    </div>
  );
};

export default SBILoanFeatures;
