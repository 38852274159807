import React from "react";
import SEO from "../../../common/SEO";
import HeaderTwo from "../../../common/header/HeaderTwo";
import LeftSidebar, { menuData } from "./LeftSidebar";

const AVANSE = () => {
  return (
    <>
      <SEO
        title="Study abroad consultants in India | GlobalDreamz"
        description="Looking for the best study abroad consultants in India? GlobalDreamz leading study abroad consultants in India."
        keywords="study abroad consultants in india, abroad study visa consultants, higher education consultant in pune, best overseas education consultant in pune, GlobalDreamz"
        canonical="https://globaldreamzedu.com/"
      />
      <HeaderTwo styles="header-transparent header-style-2" />

      {/* CONTAINER */}

      <div className="eduloans-container">
        {/* TITLE */}
        <LeftSidebar />

        <div className="content">
          <div className="title bg-primary-color">
            <h2>
              <span>AVANSE</span>
            </h2>
          </div>

          <div
            className="description"
            style={{
              margin: "20px 10px",
            }}>
            <p>
              Avanse makes sure that it provides a hassle-free Avanse Education
              loan application process to help students reach prominent Indian
              and foreign educational platforms. Students can apply for an
              Avanse education loan or Avanse overseas education loan even
              before enrolling in the desired courses.
            </p>
          </div>
          {/* O */}
          {menuData?.map((category, index) => (
            <div className="features" id={category.id} key={index}>
              <h3>{category.title}</h3>

              {category?.subMenu?.map((section) => (
                <div className="tile" key={section.id} id={section.id}>
                  <li>{section.title}</li>
                  {section.content}
                </div>
              ))}
            </div>
          ))}
          {/* <ISB />
          <PIAS /> */}
        </div>
      </div>
    </>
  );
};

export default AVANSE;
