import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";
import BlogLoadMore from "./pages/blog/BlogLoadMore";
import BlogStandard from "./pages/blog/BlogStandard";
import BlogDetailsOne from "./pages/detailspages/BlogDetailsOne";
import HomeDreamz from "./pages/homepages/HomeDreamz";
import GalleryDreamz from "./pages/innerpages/GalleryDreamz";
import WallofFameDreamz from "./pages/innerpages/WallofFameDreamz";
import EventsDreamz from "./pages/innerpages/EventsDreamz";
import ActivityDreamz from "./pages/innerpages/ActivityDreamz";
import OfficeDreamz from "./pages/innerpages/OfficeDreamz";
import GDEnquiryDreamz from "./pages/innerpages/GDEnquiryDreamz";
import PrivacyPolicy from "./pages/innerpages/PrivacyPolicy";
import Error from "./pages/innerpages/Error";
import KnowledgePartnerDreamz from "./pages/innerpages/KnowledgePartnerDreamz";
import AboutDreamz from "./pages/innerpages/AboutDreamz";
import ThankYouDreamz from "./pages/innerpages/ThankYouDreamz";
import AboutTeamDreamz from "./pages/innerpages/AboutTeamDreamz";
import ContactDreamz from "./pages/innerpages/ContactDreamz";
import CareersDreamz from "./pages/innerpages/CareersDreamz";
import StudyInUSADreamz from "./pages/innerpages/StudyInUSADreamz";
import StudyInUKDreamz from "./pages/innerpages/StudyInUKDreamz";
import StudyInCanadaDreamz from "./pages/innerpages/StudyInCanadaDreamz";
import StudyInAustraliaDreamz from "./pages/innerpages/StudyInAustraliaDreamz";
import StudyInGermanyDreamz from "./pages/innerpages/StudyInGermanyDreamz";
import StudyInIndiaDreamz from "./pages/innerpages/StudyInIndiaDreamz";

import GreDreamz from "./pages/innerpages/GreDreamz";
import GmatDreamz from "./pages/innerpages/GmatDreamz";
import ToeflDreamz from "./pages/innerpages/ToeflDreamz";
import IeltsDreamz from "./pages/innerpages/IeltsDreamz";
import PteDreamz from "./pages/innerpages/PteDreamz";
import SatDreamz from "./pages/innerpages/SatDreamz";
import MbaDreamz from "./pages/innerpages/MbaDreamz";
import MsDreamz from "./pages/innerpages/MsDreamz";
import PhdDreamz from "./pages/innerpages/PhdDreamz";
import UndergradDreamz from "./pages/innerpages/UndergradDreamz";
import TermsConditions from "./pages/innerpages/TermsConditions";

import "./assets/scss/style.scss";
import Forex from "./components/forex/Forex";
import SBI from "./components/NPB/sbi/SBI";
import UBI from "./components/NPB/ubi/UBI";
import BOB from "./components/NPB/bob/BOB";
import ICICI from "./components/NPB/icici/ICICI";
import AXIS from "./components/NPB/axis/AXIS";
import IDFC from "./components/NPB/idfc/IDFC";
import COSMOS from "./components/NPB/cosmos/COSMOS";
import HDFC from "./components/NPB/hdfc/HDFC";
import AVANSE from "./components/NPB/avanse/AVANSE";
import INCRED from "./components/NPB/incred/INCRED";
import AUXILO from "./components/NPB/auxilo/AUXILO";
import PRODIGY from "./components/NPB/prodigy/PRODIGY";
import AlliedServices from "./components/allied-services/Allied-services";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <div className="edu-about-area about-style-6 og-float">
          <div className="inner">
            <div className="read-more-btn">
              <a className="info-btn-og" href="tel: +919209401677">
                <i className="icon-call"></i>
              </a>

              <a
                className="info-btn-og-2"
                href="https://api.whatsapp.com/send?phone=919209401677&amp;text=Hi%20I%20am%20Interested%20to%20know%20more%20">
                <div>
                  <img
                    className="gd-icon-whatsapp"
                    src={`${process.env.PUBLIC_URL}/images/icons/whatsapp.png`}
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
        <Routes>
          <Route exact path="/" element={<HomeDreamz />} />
          <Route exact path={"/home"} element={<HomeDreamz />} />
          <Route exact path={"/about"} element={<AboutDreamz />} />
          <Route exact path={"/about-team"} element={<AboutTeamDreamz />} />
          <Route exact path={"/contact"} element={<ContactDreamz />} />
          <Route exact path={"/thank-you"} element={<ThankYouDreamz />} />
          <Route exact path={"/careers"} element={<CareersDreamz />} />
          <Route exact path={"/gallery"} element={<GalleryDreamz />} />
          <Route exact path={"/wall-of-fame"} element={<WallofFameDreamz />} />
          <Route exact path={"/events-gallery"} element={<EventsDreamz />} />
          <Route
            exact
            path={"/activity-gallery"}
            element={<ActivityDreamz />}
          />
          <Route exact path={"/office-gallery"} element={<OfficeDreamz />} />
          <Route
            exact
            path={"/global-dreamz-enquiry"}
            element={<GDEnquiryDreamz />}
          />
          <Route
            exact
            path={"/knowledge-partner"}
            element={<KnowledgePartnerDreamz />}
          />
          <Route exact path={"/study-in-usa"} element={<StudyInUSADreamz />} />
          <Route
            exact
            path={"/study-in-canada"}
            element={<StudyInCanadaDreamz />}
          />
          <Route
            exact
            path={"/study-in-germany"}
            element={<StudyInGermanyDreamz />}
          />

          <Route exact path={"/study-in-uk"} element={<StudyInUKDreamz />} />
          <Route
            exact
            path={"/study-in-australia"}
            element={<StudyInAustraliaDreamz />}
          />
          <Route
            exact
            path={"/study-in-row"}
            element={<StudyInIndiaDreamz />}
          />

          <Route exact path={"/gre"} element={<GreDreamz />} />
          <Route exact path={"/forex"} element={<Forex />} />
          <Route exact path={"/gmat"} element={<GmatDreamz />} />
          <Route exact path={"/toefl"} element={<ToeflDreamz />} />
          <Route exact path={"/ielts"} element={<IeltsDreamz />} />
          <Route exact path={"/pte"} element={<PteDreamz />} />
          <Route exact path={"/sat"} element={<SatDreamz />} />
          <Route exact path={"/mba"} element={<MbaDreamz />} />
          <Route exact path={"/ms"} element={<MsDreamz />} />
          <Route exact path={"/sbi"} element={<SBI />} />
          <Route exact path={"/ubi"} element={<UBI />} />
          <Route exact path={"/bob"} element={<BOB />} />
          <Route exact path={"/icici"} element={<ICICI />} />
          <Route exact path={"/axis"} element={<AXIS />} />
          <Route exact path={"/idfc"} element={<IDFC />} />
          <Route exact path={"/cosmos"} element={<COSMOS />} />
          <Route exact path={"/hdfc"} element={<HDFC />} />
          <Route exact path={"/avanse"} element={<AVANSE />} />
          <Route exact path={"/incred"} element={<INCRED />} />
          <Route exact path={"/auxilo"} element={<AUXILO />} />
          <Route exact path={"/prodigy"} element={<PRODIGY />} />
          <Route exact path={"/phd"} element={<PhdDreamz />} />
          <Route exact path={"/allied-services"} element={<AlliedServices />} />
          <Route exact path={"/undergrad"} element={<UndergradDreamz />} />
          <Route exact path={"/privacy-policy"} element={<PrivacyPolicy />} />
          <Route
            exact
            path={"/contest-terms-conditions"}
            element={<TermsConditions />}
          />
          <Route exact path={"/blog-load-more"} element={<BlogLoadMore />} />
          <Route exact path={"/blog-standard"} element={<BlogStandard />} />
          <Route
            exact
            path={"/blog-details/:id"}
            element={<BlogDetailsOne />}
          />

          <Route path="*" element={<Error />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
