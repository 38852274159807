import React, { useState, useRef } from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import AboutEight from "../../components/about/AboutEight";
import AboutUsTwoService from "../../components/about-us-two/AboutUsTwoService";
import emailjs from "emailjs-com";
import "../../assets/css/contactForm.css";
import axios from "axios";

const Success = () => {
  return (
    <p className="success-message">
      Thanks for your query. We will contact with you soon.
    </p>
  );
};

const Error = () => {
  return (
    <p className="error-message">
      Something went wrong. Please try again later. You can also contact us on
      below details.
    </p>
  );
};

const ContactUs = (formStyle) => {
  const [result, setResult] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [form, setForm] = useState({
    Name: "",
    Email: "",
    PhoneNo: "",
    CountryOfStudy: "",
    // AdmissionCounselling: "",
    // ServiceType: "",
  });

  const sendEmail = (e) => {
    e.preventDefault();
    if (!isChecked) {
      alert("Please accept the terms and conditions");
      return;
    }
    axios.post("https://api.globaldreamzedu.com/contact", form).then(
      (response) => {
        console.log(response);
        if (response.status === 200) {
          setIsSuccess(true);
          setResult(true);
          setForm({
            Name: "",
            Email: "",
            PhoneNo: "",
            CountryOfStudy: "",
          });
          setIsChecked(false);
          setTimeout(() => {
            setResult(false);
          }, 5000);
        }
      },
      (error) => {
        setIsSuccess(false);
        setResult(true);
        setForm({
          Name: "",
          Email: "",
          PhoneNo: "",
          CountryOfStudy: "",
        });

        setTimeout(() => {
          setResult(false);
        }, 5000);
        console.log(error);
      }
    );
  };

  return (
    <>
      <SEO
        title="Best overseas education consultants in pune"
        description="Choosing the right education consultant is a very important decision. If you are looking for a good overseas education consultant, GlobalDreamz  is a best overseas education consultants in pune for you."
        keywords="Best study abroad consultants in pune, Overseas education consultant, Best overseas education consultant, Overseas education consultant in pune"
        canonical="https://globaldreamzedu.com/"
      />
      <Layout>
        {/* <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--100"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/sh-1.jpg`}
                    alt="Event Thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div
          className="edu-form-container"
          style={{
            border: "none",
          }}>
          <div
            className="left-side"
            style={{
              // border: "1px solid red",
              width: "50%",
              padding: "40px",
            }}>
            <h1
              style={{
                color: "red",
                // make this a title with letter width max 3
                fontSize: "2.8em",
                textAlign: "center",
                // fontStretch: "expanded",
                fontWeight: "900",
                // add
              }}>
              {" "}
              <span>Your passport to </span>
              <br />
              Global Education is here
            </h1>
            <p
              style={{
                color: "gray",
                // make this a title with letter width max 3
                fontSize: "1.2em",
                textAlign: "center",
                // fontStretch: "expanded",
                // fontWeight: "900",
                // add
              }}>
              <span>A Guaranteed Secured Education Loan</span>
              <br /> at an attractive 8.45% interest rate.
            </p>

            <div
              className="benefits"
              style={{
                // border: "1px solid red",
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
              }}>
              <div
                className="benefit-1"
                style={{
                  borderRight: "1px solid gray",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}>
                {" "}
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "90px",
                    height: "90px",
                    // border: "1px solid green",
                    margin: "auto",
                    borderRadius: "50%",
                    backgroundColor: "#F0F0F0",
                  }}>
                  <img
                    className="gd-icon-whatsapp"
                    style={{
                      width: "80px",
                    }}
                    src={`${process.env.PUBLIC_URL}/images/edu-loans/eduloan-1.png`}
                  />
                </div>
                <h3
                  style={{
                    fontSize: "1.3em",
                    textAlign: "center",
                    color: "#000",
                  }}>
                  Hassle Free <br /> Education Loan
                </h3>
              </div>{" "}
              <div
                className="benefit-2"
                style={{
                  borderRight: "1px solid gray",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}>
                {" "}
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "90px",
                    height: "90px",
                    // border: "1px solid green",
                    margin: "auto",
                    borderRadius: "50%",
                    backgroundColor: "#F0F0F0",
                  }}>
                  <img
                    className="gd-icon-whatsapp"
                    style={{
                      width: "80px",
                    }}
                    src={`${process.env.PUBLIC_URL}/images/edu-loans/eduloan-2.png`}
                  />
                </div>
                <h3
                  style={{
                    fontSize: "1.3em",
                    textAlign: "center",
                    color: "#000",
                  }}>
                  Absolutely Free
                  <br /> Service No Extra Cost
                </h3>
              </div>{" "}
              <div
                className="benefit-3"
                style={{
                  // borderRight: "1px solid gray",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}>
                {" "}
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "90px",
                    height: "90px",
                    // border: "1px solid green",
                    margin: "auto",
                    borderRadius: "50%",
                    backgroundColor: "#F0F0F0",
                  }}>
                  <img
                    className="gd-icon-whatsapp"
                    style={{
                      width: "80px",
                    }}
                    src={`${process.env.PUBLIC_URL}/images/edu-loans/eduloan-3.png`}
                  />
                </div>
                <h3
                  style={{
                    fontSize: "1.3em",
                    textAlign: "center",
                    color: "#000",
                  }}>
                  Borrow Big, <br /> Save big
                </h3>
              </div>{" "}
            </div>

            {/* LENDING PARTNERS */}
            <div
              className="partners"
              style={{
                marginTop: "50px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0px",
              }}>
              <h1
                style={{
                  color: "red",
                  fontSize: "1.8em",
                  textAlign: "center",
                  // padding: "40px",
                }}>
                <span>Our Lending Partners</span> <br />
                <p>
                  <span>Secured & Unsecured Education Loan</span>
                </p>
              </h1>

              <div
                className="icons"
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  position: "relative",
                  bottom: "30px",
                  gap: "20px",
                }}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/edu-loans/hdfc-credilla.png`}
                />{" "}
                <img
                  src={`${process.env.PUBLIC_URL}/images/edu-loans/avanse.png`}
                />{" "}
                <img
                  src={`${process.env.PUBLIC_URL}/images/edu-loans/auxilo.png`}
                />{" "}
                <img
                  src={`${process.env.PUBLIC_URL}/images/edu-loans/incred.png`}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/images/edu-loans/sbi.png`}
                />{" "}
                <img
                  src={`${process.env.PUBLIC_URL}/images/edu-loans/bob.png`}
                />{" "}
                <img
                  src={`${process.env.PUBLIC_URL}/images/edu-loans/icici.png`}
                />{" "}
                <img
                  src={`${process.env.PUBLIC_URL}/images/edu-loans/axis.png`}
                />
              </div>
            </div>
          </div>
          <div className="right-side">
            <h1 className="title">
              <span>Fill in the below and we'll find the</span>
              <br /> best Education Loan for you
            </h1>

            <form
              className='"rnt-contact-form rwt-dynamic-form row'
              action=""
              onSubmit={sendEmail}>
              <div className="">
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // gap: "5px",
                    // paddingY: "40px",
                  }}>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    name="fullname"
                    onChange={(e) => {
                      setForm({ ...form, Name: e.target.value });
                    }}
                    value={form?.Name}
                    placeholder="Name*"
                    style={{
                      width: "60%",
                      height: "20px",
                      borderRadius: "5px",
                      border: "1px solid #fff",
                      padding: "10px",
                      marginBottom: "10px",
                      borderRadius: "25px",
                    }}
                    required
                  />

                  <input
                    type="email"
                    className="form-control form-control-lg"
                    name="email"
                    onChange={(e) => {
                      setForm({ ...form, Email: e.target.value });
                    }}
                    value={form?.Email}
                    placeholder="Email*"
                    required
                    style={{
                      width: "60%",
                      height: "20px",
                      borderRadius: "5px",
                      border: "1px solid #fff",
                      padding: "10px",
                      marginBottom: "10px",
                      borderRadius: "25px",
                    }}
                  />

                  <input
                    type="text"
                    className="form-control form-control-lg"
                    name="phone"
                    placeholder="Phone*"
                    onChange={(e) => {
                      setForm({ ...form, PhoneNo: e.target.value });
                    }}
                    value={form?.PhoneNo}
                    required
                    style={{
                      width: "60%",
                      height: "20px",
                      borderRadius: "5px",
                      border: "1px solid #fff",
                      padding: "10px",
                      marginBottom: "10px",
                      borderRadius: "25px",
                    }}
                  />

                  {/* country of study */}
                  <select
                    class="form-control form-control-lg"
                    aria-label="Default select example"
                    name="CountryOfStudy"
                    style={{
                      width: "60%",
                      height: "40px",
                      borderRadius: "5px",
                      border: "1px solid #fff",
                      padding: "10px",
                      marginBottom: "10px",
                      borderRadius: "25px",
                    }}
                    onChange={(e) => {
                      setForm({ ...form, CountryOfStudy: e.target.value });
                    }}
                    value={form?.CountryOfStudy}
                    required>
                    <option selected>Country of Study</option>
                    <option value="US">Study in US</option>
                    <option value="Canada">Study in Canada</option>
                    <option value="Germany">Study in Germany</option>
                    <option value="UK">Study in UK</option>
                    <option value="Australia">Study in Australia</option>
                    <option value="row">Study in RoW</option>
                  </select>

                  {/* terms and condition */}
                  <div
                    className="form-check"
                    style={{
                      width: "80%",
                      height: "50px",
                      borderRadius: "5px",
                      // border: "1px solid #fff",
                      padding: "10px",
                      marginBottom: "10px",
                      borderRadius: "25px",
                      margin: "30px auto",
                    }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={isChecked}
                      id="flexCheckDefault"
                      onClick={() => {
                        setIsChecked(!isChecked);
                      }}
                    />
                    <label
                      className="form-check-label terms-conditions"
                      for="flexCheckDefault">
                      <span>
                        By entering my phone number here, I hereby authorize
                        Global Dreamz to contact me through call,
                      </span>{" "}
                      message or WhatsApp. it will override my registry on the
                      NCPR/NDNC
                    </label>
                  </div>

                  {/* submit button */}
                  <div
                    className="col-lg-12"
                    style={{
                      width: "200px",
                      marginTop: "50px",
                      border: "none",
                    }}>
                    <button
                      className="rn-btn edu-btn w-100"
                      type="submit"
                      style={{
                        border: "1px solid #fff",
                      }}>
                      <span
                        style={{
                          fontSize: "0.8em",
                        }}>
                        Submit Now
                      </span>
                      <i className="icon-arrow-right-line-right"></i>
                    </button>
                  </div>
                  {result ? (
                    <div className="form-group">
                      {isSuccess ? <Success /> : <Error />}
                    </div>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="mediashark-contact-us edu-contact-us-area edu-section-gap bg-color-white">
          <div className="container mediashark-animated-shape">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="pre-title">Need Help?</span>
                  <h3 className="title">Get In Touch With us</h3>
                </div>
              </div>
            </div>
            <div className="row g-5 mt--20">
              <div className="col-lg-6">
                <div className="contact-info pr--70 pr_lg--0 pr_md--0 pr_sm--0">
                  <div className="row g-5">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 phone">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-call"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Call Us On</h6>
                            <p>
                              <a href="tel:+919209401677">+91 92094 01677</a>
                            </p>
                            <p>
                              <a href="tel:+919209401688">+91 92094 01688</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <div className="contact-address-card-1 email">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-mail-open-line"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Email Us</h6>
                            <p>
                              <a href="mailto:info@globaldreamzedu.com">
                                info@globaldreamzedu.com
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-address-card-1 location">
                  <div className="inner">
                    <div className="icon">
                      <i className="icon-map-pin-line"></i>
                    </div>
                    <div className="content">
                      <h6 className="title">Our Location</h6>
                      <p>
                        Office No. 2, 2nd Floor, Aditya Centeegra, Above RBL
                        Bank, Dnyaneshwar Paduka Chowk, F C Road, Pune – 411005
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
              <div className="shape-image shape-image-1">
                <img
                  src={`${process.env.PUBLIC_URL}/images/shapes/shape-04-01.png`}
                  alt="Shape Thumb"
                />
              </div>
              <div className="shape-image shape-image-2">
                <img
                  src={`${process.env.PUBLIC_URL}/images/shapes/shape-02-08.png`}
                  alt="Shape Thumb"
                />
              </div>
              <div className="shape-image shape-image-3">
                <img
                  src={`${process.env.PUBLIC_URL}/images/shapes/shape-15.png`}
                  alt="Shape Thumb"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="edu-contact-map-area edu-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="google-map alignwide">
                  <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d236.44127235757279!2d73.8412644691564!3d18.526174402846287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bfa70b82c5a1%3A0xcbb9c65eeb5e6516!2sAditya%20Centeegra!5e0!3m2!1sen!2sin!4v1661914170221!5m2!1sen!2sin"
                    height="500"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AboutEight />

        <AboutUsTwoService />
      </Layout>
    </>
  );
};
export default ContactUs;
