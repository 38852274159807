import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import HeaderTwo from "../../common/header/HeaderTwo";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../../components/sectionTitle/SectionTitle";


const KnowledgePartnerDreamz = () => {
  return (
    <>
      <SEO title="About" />
      <SEO title="Knowledge Partner - GlobalDreamz" description="Knowledge Partner" keywords="Knowledge Partner" canonical="https://globaldreamzedu.com/knowledge-partner" />
      <HeaderTwo styles="header-transparent header-style-2" />
      <Layout>
      <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
src={`${process.env.PUBLIC_URL}/images/event/event-details/sh-1.jpg`}
                    alt="Event Thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mediashark-home-five-about edu-about-area about-style-6 edu-section-gap bg-color-white">
      <div className="container mediashark-animated-shape">
        <div className="row g-5 align-items-center">
          <ScrollAnimation
            animateIn="fadeIn"
            animateOut="fadeInOut"
            className="col-xl-5 col-lg-6"
            animateOnce={true}
          >
            <div className="about-image-gallery">
              <img
                className="image-1 w-100"
                src={`${process.env.PUBLIC_URL}/images/about/about-08/about-image-10.jpg`}
                alt="About Thumb"
              />
             
            </div>
          </ScrollAnimation>
          <div className="col-xl-6 col-lg-6 offset-xl-1">
            <div className="inner mt_md--40 mt_sm--20">
              <SectionTitle
                slogan="About"
                title="T.M.Sathyanarayanan"
              />
              
              <div className="feature-list-wrapper">
             
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="feature-list"
                  animateOnce={true}
                >
                  <i className="icon-checkbox-circle-fill"></i>Economist &amp; Specialists in International relations
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="feature-list"
                  animateOnce={true}
                >
                  <i className="icon-checkbox-circle-fill"></i>31 years of teaching experience, teaching both in India and abroad
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="feature-list"
                  animateOnce={true}
                >
                  <i className="icon-checkbox-circle-fill"></i>Advisor and consultant to Universities, corporate entities in India and abroad
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="feature-list"
                  animateOnce={true}
                >
                  <i className="icon-checkbox-circle-fill"></i>Consultant on International Education to Institutes in India and overseas including the
Consulates.
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="feature-list"
                  animateOnce={true}
                >
                  <i className="icon-checkbox-circle-fill"></i>MD , OECS India , Pune
                </ScrollAnimation>
              </div>
              <div className="read-more-btn mt--75 mt_lg--30 mt_md--40 mt_sm--40">
                
              </div>
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image shape-image-1">
            <img
              src={`${process.env.PUBLIC_URL}/images/shapes/shape-05-04.png`}
              alt="Shape Thumb"
            />
          </div>
          <div className="shape-image shape-image-2">
            <img
              src={`${process.env.PUBLIC_URL}/images/shapes/shape-33.png`}
              alt="Shape Thumb"
            />
          </div>
          <div className="shape-image shape-image-3">
            <img
              src={`${process.env.PUBLIC_URL}/images/shapes/shape-13-05.png`}
              alt="Shape Thumb"
            />
          </div>
          <div className="shape-image shape-image-4">
            <img
              src={`${process.env.PUBLIC_URL}/images/shapes/shape-01-03.png`}
              alt="Shape Thumb"
            />
          </div>
          <div className="shape-image shape-image-5">
            <img
              src={`${process.env.PUBLIC_URL}/images/shapes/shape-06.png`}
              alt="Shape Thumb"
            />
          </div>
        </div>
      </div>
    </div>

        <div className="mediashark-about-three-mission edu-mission-vision-area edu-section-gap border-bottom-1 bg-color-white">
     

      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6">
            <div className="our-mission">
              <div className="section-title text-start">
                {/* <span className="pre-title">Our Mission</span> */}
                <h5 className="gd-red">
                Resource Personnel/Consultant
                </h5>
               
                <ul className="list-style-1 mb--20">
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>For World Bank Projects on Sustained Economic
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Development in third World Countries
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Visited / Participated in International Academic / Business ventures abroad
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Team leader for United Nations Special Interest group on
inter country relations
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>International Education Consultant for East Asian Countries.
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Setting up Business in the overseas and B2B Business
                  </li>
                </ul>

                 
               
              </div>
            </div>
          </div>
         
          <div className="col-lg-6">
            <div className="our-mission">
              <div className="section-title text-start">
                {/* <span className="pre-title">Our Mission</span> */}
                <h5 className="gd-red">
                International Affiliation
                </h5>
                
                <ul className="list-style-1 mb--20">
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Nominated as Fulbright Scholar by Fulbright
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Academy USA. Participated in the Global
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Conference at Heidelberg, Germany as the only
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Representative from Asia in March 2011
                  </li>
                  </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="our-vision">
              <div className="section-title text-start">
                {/* <span className="pre-title">Our Vision</span> */}
                <h5 className="gd-red">
                International recognition
                </h5>
                <ul className="list-style-1 mb--20">
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Nominated as one of the Top 500 professionals
                    By Who is Who Academia, Washington USA
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Awarded Silver Plaque for the contribution to Cross border Student Development by East Asia School of Management, Singapore
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Appointed by the Ministry as International
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Consultant for Business Development and Economic Modeling for Macedonia, Mexico and Yugoslavian economy ( March 2011)
                  </li>
                 
                  </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="our-mission">
              <div className="section-title text-start">
                {/* <span className="pre-title">Our Mission</span> */}
                <h5 className="gd-red">
                Special International Awards
                </h5>
                <ul className="list-style-1 mb--20">
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Represented India at International Convention at Geneva in November 1999 and was awarded Silver Medal for the contribution made to promote International Education.
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Rotary and Lion’s Award for Best Counselor
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>International Excellence Award for the Year 2002-2003
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Best Counselor Award at the International Convention, Los Angeles for the year 2003
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Award a certificate of Merit from the University Richmond, Virginia, USA, for the Contribution made in Intellectual Development of Students in India.
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Received several awards internationally for the promotion
of International Education and represented India in UN.
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Nominated in Madison Who is Who (USA) New York Best
Magazine as one of the Top 100 Professionals for the year 2005-2006.
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Received the Top Silver Award from EASB, Singapore for
the top Educator award of the YEAR 2009 out of 41 contenders from 12 countries
                  </li>
                  </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="our-vision">
              <div className="section-title text-start">
                {/* <span className="pre-title">Our Vision</span> */}
                <h5 className="gd-red">
                International Membership
                </h5>
                <ul className="list-style-1 mb--20">
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Member of SIG (International Education) at United Nations USA under NAFSA
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Member Fulbright Academy (USA)
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>NAFSA, (USA) , EU Chamber of Commerce
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>ICEF (Germany) Approved Agency
                  </li>
                  </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="our-vision">
              <div className="section-title text-start">
                {/* <span className="pre-title">Our Vision</span> */}
                <h5 className="gd-red">
                Local Membership
                </h5>
                <ul className="list-style-1 mb--20">
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>Indo-American Chamber of Commerce, Pune
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>European Chamber of Commerce, Mumbai
                  </li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

        
      </Layout>
      
    </>
  );
};

export default KnowledgePartnerDreamz;
