import React from "react";
import { AiOutlineRight } from "react-icons/ai";

export const menuData = [
  {
    title: "Special Union Education Scheme for ISB",
    id: "special-scheme-for-isb",
    subMenu: [
      {
        id: "special-scheme-for-isb-eligibility", // Updated ID
        title: "Eligibility",
        content: (
          <p>
            Students who secure admission in Indian School of Business (ISB) at
            Hyderabad / Mohali campus for the academic year 2023-24 only for the
            Post Graduate Programme in Management (PGPM) course. <br /> <br />
            The scheme does not cover executive courses (other than PGP-PRO &
            PGP-MAX) and short duration courses / programmes at ISB.
          </p>
        ),
      },
      {
        id: "special-scheme-for-isb-purpose", // Updated ID
        title: "Purpose",
        content: (
          <p>
            To meet tuition fees, cost of books, caution deposit (refundable)*,
            hostel charges, mess charges, desktop/laptop/tablet**, living
            expenses, insurance premium, student exchange programme, admission
            acceptance fee, etc.
            <br />
            <br />
            <span style={{ fontWeight: "bold" }}>Note:</span>
            <br />
            **Student to deposit security deposit to the loan account
            immediately on refund from the institute. An undertaking to be
            obtained from the student in this respect.
            <br />
            **Desktop/laptop/tablet cost can be reimbursed to the student if it
            was purchased for the purpose of study at ISB.
          </p>
        ),
      },
      {
        id: "special-scheme-for-isb-quantum-of-loan", // Updated ID
        title: "Quantum of Loan",
        content: (
          <p>
            Rs.46.50 lakh
            <br /> <br />
            <span style={{ fontWeight: "bold" }}>Note:</span>
            <br />
            **Maximum quantum mentioned above is inclusive of Rs.4.00 lakh for
            Student Exchange Programme. This can be considered initially or as
            and when the student requires.
          </p>
        ),
      },
      {
        id: "special-scheme-for-isb-margin", // Updated ID
        title: "Margin",
        content: <p>Nil</p>,
      },
      {
        id: "special-scheme-for-isb-rate-of-interest", // Updated ID
        title: "Rate of Interest",
        content: (
          <p>
            EBLR - 0.75%
            <ol className="expense-list">
              <li>
                Rate of Interest mentioned above is applicable for both male and
                female students.
              </li>
              <li>
                No further concession/rebate for servicing interest during
                course/moratorium period is to be allowed.
              </li>
              <li>
                Servicing of interest during course/moratorium period is
                optional.
              </li>
              <li>
                If the loan is taken over by any other bank/FI, then normal
                education loan rate of interest, as applicable on the date of
                sanction, to be charged ab-initio. However, no takeover charges
                to be levied.
              </li>
            </ol>
          </p>
        ),
      },
      {
        id: "special-scheme-for-isb-processing-charges", // Updated ID
        title: "Processing Charges",
        content: <p>Nil</p>,
      },
      {
        id: "special-scheme-for-isb-repayment-period", // Updated ID
        title: "Repayment Period",
        content: (
          <p>
            Maximum repayment tenure (after course/moratorium period) will be 15
            years.
          </p>
        ),
      },
      {
        id: "special-scheme-for-isb-moratorium-period", // Updated ID
        title: "Moratorium Period",
        content: <p>Course period + 1 year</p>,
      },
      {
        id: "special-scheme-for-isb-co-applicant-guarantor", // Updated ID
        title: "Co-applicant/Guarantor",
        content: <p>NIL</p>,
      },
      {
        id: "special-scheme-for-isb-security", // Updated ID
        title: "Security",
        content: (
          <p>
            No collateral security or third-party guarantee is required,
            irrespective of the loan amount.
          </p>
        ),
      },
      {
        id: "special-scheme-for-isb-insurance", // Updated ID
        title: "Insurance",
        content: (
          <p>
            <ol className="expense-list">
              <li>
                Term life insurance policy to be obtained & assigned in favor of
                the bank.
              </li>
              <li>
                The policy should be in the name of the student, for a sum not
                less than the loan amount and for the tenure covering course,
                moratorium, and repayment period.
              </li>
              <li>
                The premium of the insurance can be included in the loan amount
                if requested by the student subject to a maximum limit.
              </li>
              <li>
                Insurance is mandatory as there is no other security
                proposed/available. However, the student may have the option to
                approach any insurance company for a lesser premium or the
                branch to arrange for the same.
              </li>
            </ol>
          </p>
        ),
      },
      {
        id: "special-scheme-for-isb-second-educational-loan", // Updated ID
        title: "Second Educational Loan",
        content: (
          <p>
            Students who have earlier availed an educational loan from our bank
            or any other bank for graduation studies may approach us for the
            sanction of a (second) educational loan under this special scheme.
            The guidelines are as follows:
            <ol className="expense-list">
              <li>
                If the repayment of the first loan is yet to commence, a
                repayment holiday should be extended for the first loan by the
                bank concerned, or the student/parents/spouse should undertake
                the repayment of the loan.
              </li>
              <li>
                If the repayment of the first loan has commenced, the account
                status should be 'Regular' as of the date of the loan's sanction
                for ISB study, and a repayment holiday should be obtained for
                the first loan or student/parents should undertake repayment of
                the same. The total outstanding balance of the first loan and
                the limit proposed to be sanctioned under the special
                educational loan scheme for ISB should not exceed the maximum
                quantum of the loan specified.
              </li>
            </ol>
          </p>
        ),
      },
      {
        id: "special-scheme-for-isb-take-over-of-education-loan-of-isb-students", // Updated ID
        title: "Take-over of Education Loan of ISB Students",
        content: (
          <p>
            To permit the following:
            <ol className="expense-list">
              <li>
                To permit take-over of educational loans with collateral
                security / third-party guarantee and also unsecured loans.
              </li>
              <li>
                Only eligible students of ISB can take-over the loan. Such
                students should be having a sanction for a special educational
                loan for ISB by the bank.
              </li>
              <li>
                Only one educational loan can be taken-over per student, and the
                balance limit available to the student as on the date of
                takeover, including the take-over amount, should not exceed the
                maximum quantum of the loan.
              </li>
              <li>
                The sanction of take-over of the loan should be issued after
                completion of the moratorium period under the special
                educational loan scheme for ISB.
              </li>
              <li>
                Rate of interest mentioned under the special scheme for ISB is
                applicable.
              </li>
            </ol>
          </p>
        ),
      },
      {
        id: "special-scheme-for-isb-other-terms-conditions", // Updated ID
        title: "Other Terms & Conditions",
        content: (
          <p>
            <ol className="expense-list">
              <li>
                The rate of interest, processing charges, prepayment penalty,
                and all other terms of the loan may be subject to revision /
                change in terms and conditions by the bank from time to time.
              </li>
              <li>
                The bank may consider changes in the applicable terms and
                conditions and may revise the rate of interest or any other
                terms and conditions for the student's loan.
              </li>
            </ol>
          </p>
        ),
      },
      {
        id: "special-scheme-for-isb-loan-for-2nd-term-of-academic-year-2023-24", // Updated ID
        title: "Loan for 2nd Term of Academic Year 2023-24",
        content: (
          <p>
            Students should apply afresh for a second term loan. The quantum,
            margin, rate of interest, security, and all other terms and
            conditions will be the same as mentioned above. Such students need
            not pay the processing charges once again.
          </p>
        ),
      },
    ],
  },
  {
    title:
      "Special Union Education Loan Schemes for Premier Institute Abroad Studies",
    id: "pias",
    subMenu: [
      {
        id: "pias-eligibility", // Updated ID
        title: "Eligibility",
        content: (
          <p>
            The student should be an Indian National and Secured admission in{" "}
            <b>
              full time Post Graduate Courses in the Premium University Abroad
            </b>{" "}
            through entrance examinations conducted by recognized universities /
            institutions and other approved authorities.
          </p>
        ),
      },
      {
        id: "pias-purpose-expenses-considered",
        title: "Purpose/ Expenses considered",
        content: (
          <p>
            <ol className="expense-list">
              <li>Tuition fee payable to college/ school/ university.</li>
              <li>Hostel / mess charges.</li>
              <li>Examination/ Library/ Laboratory fee.</li>
              <li>Insurance premium for the student borrower.</li>
              <li>
                Caution deposit, building fund/ refundable deposit supported by
                Institution bills/ receipts
              </li>
              <li>Travel expenses /passage money for studies abroad.</li>
              <li>Purchase of books/ equipment’s/ instruments/ uniforms</li>
              <li>
                Purchase of computer/laptop at reasonable cost, if required for
                completion of the course
              </li>
              <li>
                Any other expense required to complete the course - like study
                tours, project work, thesis, etc
              </li>
              <li>
                In case of University/institute in Germany, wherein these
                institutions/universities do not charge any tuition fee for
                their course. The expenses such as cost of books, hostel & mess
                charges, etc can be funded subject to a minimum margin of 30% of
                such expenses (irrespective of the loan amount).
              </li>
              <li>
                Reasonable lodging and boarding charges will be considered in
                case the student chooses/is required to opt for outside
                accommodation.
              </li>
              <li>
                These expenses can be considered subject to the condition that
                the amount does not exceed 10% of the total tuition fees for the
                entire course.
              </li>
              <li>
                It is likely that expenditure under Para 2.7, 2.8 &2.9 above may
                not be available in the schedule of fees and charges prescribed
                by the college authorities. Therefore, a realistic assessment
                may be made of the requirement under these heads. However, the
                maximum expenses included under Para 2.7, 2.8 &2.9 is to be
                capped at 20% of the total tuition fees payable for completion
                of the course.
              </li>
            </ol>
          </p>
        ),
      },
      {
        id: "pias-quantum-of-loan",
        title: "Quantum of Loan",
        content: (
          <p>
            <ol className="expense-list">
              <li>Minimum: Rs.7.50 Lakhs</li>
              <li>Maximum: Rs.150.00 Lakhs</li>
            </ol>
          </p>
        ),
      },
      {
        id: "pias-eligible-masters-degrees-abroad",
        title: "Eligible Masters Degrees Abroad",
        content: (
          <p>
            The courses eligible for loan in top 150 premier institutes is
            enclosed in Annexure -I.
          </p>
        ),
      },
      {
        id: "pias-margin",
        title: "Margin",
        content: (
          <p>
            <b>15% - Irrespective of the loan amount</b> <br />
            (Note: Any scholarship received by the student is to be treated as
            margin and borrower has to deposit the scholarship amount in the
            bank account.) <br />
            <b>
              Margin should be brought-in on year-to-year basis as and when
              disbursements are made on a pro-rata basis.
            </b>{" "}
            <br />
            i.e. at all points of time the margin should be maintained in
            relation to the loan disbursed and total expenditure incurred.
          </p>
        ),
      },
      {
        id: "pias-reimbursement-of-fee",
        title: "Reimbursement of fee (for 1st year students)",
        content: (
          <p>
            If the student has paid the first term fee for securing the
            admission in the colleges/ universities and approaches us for loan
            along with reimbursement of the fee paid, then the same is permitted
            within 6 months of securing admission; on production of original
            receipt / proof of payment. <br />
            If the borrower temporarily arranges for the funds to pay the term
            fee during the sanction process, then the same can be reimbursed to
            the student, upon request and production of original receipt / proof
            of payment.
          </p>
        ),
      },
      {
        id: "pias-living-expenses",
        title: "Mess Charges / Living Expenses",
        content: (
          <p>
            In cases where the mess charges/living expenses are not
            mentioned/indicated in the fee structure provided by the institute,
            a reasonable amount is to be considered based on the living expenses
            of the country. The mess charges/living expenses will be allowed
            within the overall sanctioned limit.
          </p>
        ),
      },
      {
        id: "pias-interest-rate",
        title: "Rate of Interest",
        content: (
          <ul className="expense-list">
            <table
              className="table table-bordered"
              style={{
                margin: "10px 0px",
              }}>
              <thead>
                <tr>
                  <th scope="col">Quantum of Loan</th>
                  <th scope="col">ROI</th>
                  <th scope="col">Collateral</th>
                  <th scope="col">Effective ROI</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Up to Rs. 40 Lakh</th>
                  <td>EBLR + 0.50%(without Collateral)</td>
                  <td>
                    50% Up to &lt; 100% <br /> 100% and above
                  </td>
                  <td>
                    EBLR+0.25% <br />
                    EBLR
                  </td>
                </tr>
                <tr>
                  <th scope="row">Above Rs. 40 Lakh</th>
                  <td>EBLR+2.00%(50% Up to &lt; 100% Collateral)</td>
                  <td>100% and above</td>
                  <td>EBLR+1.50%</td>
                </tr>
              </tbody>
            </table>
          </ul>
        ),
      },
      {
        id: "pias-processing-charges",
        title: "Processing Charges",
        content: (
          <p>
            <ol className="expense-list">
              <li>No processing charges to be levied.</li>
              <li>
                However, an upfront fee of Rs.5000 to be charged to the student,
                which will be refunded if the student avails the loan within a
                period of 3 months from the date of sanction.
              </li>
              <li>
                If the student does not avail the loan within a period of 3
                months from the date of sanction, then the deposit of Rs.5000 to
                be appropriated as processing charges.
              </li>
            </ol>
          </p>
        ),
      },
      {
        id: "pias-legal-valuation-charges",
        title: "Legal & Valuation Charges",
        content: (
          <p>
            <ol className="expense-list">
              <li>
                Wherever property is being mortgaged as security for educational
                loan, the borrower has to bear the professional fee charged by
                bank’s advocate for title deed verification and professional fee
                payable to bank’s approved valuer for valuation of the property
                proposed to be mortgaged.
              </li>
              <li>
                The above charges will not be part of loan amount and has to be
                paid by the borrower as and when incurred.
              </li>
            </ol>
          </p>
        ),
      },

      {
        id: "pias-repayment-period",
        title: "Repayment Period",
        content: <p>180 months /15 years (After moratorium period)</p>,
      },
      {
        id: "pias-moratorium-period",
        title: "Moratorium Period",
        content: <p>Course period + 1 year</p>,
      },
      {
        id: "pias-co-applicant-guarantor",
        title: "Co-applicant/Guarantor",
        content: (
          <p>
            Either of the parents or spouse to be made co-applicant / guarantor.
          </p>
        ),
      },
      {
        id: "pias-security",
        title: "Security",
        content: (
          <ol className="expense-list">
            <li>
              <b>Up to Rs.40.00 lakh : </b>No collateral security or third-party
              guarantee to be taken, irrespective of the income / means of the
              guarantor. <br />
              *However, if collateral is offered for the loan below Rs.40.00
              Lakh interest concession is available.{" "}
            </li>
            <li>
              <b>Above Rs.40.00 lakh : </b>Tangible collateral security of
              minimum value of 50% of total loan amount and interest
              accumulation during the course and moratorium period, acceptable
              to bank. Interest concession of 0.50% is available if collateral
              offered is 100% or more.{" "}
            </li>
            <li>
              The property offered as security should be in the name of borrower
              / co-borrower or other immediate family members or any in the name
              of 3rd party; can be taken as security, property owner must join
              in as co-applicant or offer personal guarantee.
            </li>
            <b>Note:</b>
            *Collateral free loans up to Rs.40 lakhs shall be made available for
            the students who have secured admissions based on merit. For
            students who secured admissions through management quota, tangible
            collateral security with minimum value of 50% of total loan amount
            and interest accumulation during the course and moratorium period
            shall be stipulated.
          </ol>
        ),
      },
      {
        id: "pias-insurance",
        title: "Insurance",
        content: (
          <p>
            <ol className="expense-list">
              <li>
                Term life insurance policy to be obtained & assigned in favour
                of the bank.{" "}
              </li>
              <li>
                The policy should be in the name of student, for an amount not
                less than the loan amount and for the tenure covering course,
                moratorium and repayment period.{" "}
              </li>
              <li>
                The premium of insurance can be included in the loan amount, if
                requested by the student.
              </li>
            </ol>
          </p>
        ),
      },
      {
        id: "pias-other-conditions",
        title: "Other conditions",
        content: (
          <p>
            <ol className="expense-list">
              <li>
                The loan shall be sanctioned / disbursed from the branch nearest
                to the place of domicile of the student{" "}
              </li>
              <li>Loan would be disbursed directly to the institute</li>
            </ol>
          </p>
        ),
      },
    ],
  },
  {
    title:
      "Special Education Loan Scheme For Premier Medical Institutes (UNION MEDICOS)",
    id: "scheme-pmi",
    subMenu: [
      {
        id: "scheme-pmi-eligibility",
        title: "Eligibility",
        content: (
          <p>
            Students who secure admission in full time medical courses (MBBS,
            MD, MS) in the premier institutes mentioned in the list through
            entrance examinations. <br />
            <br />
            Eligible Colleges and Courses under this scheme are detailed in
            Annexure-I.
          </p>
        ),
      },
      {
        id: "scheme-pmi-purpose",
        title: "Purpose",
        content: (
          <p>
            To meet tuition fees, cost of books, caution deposit*, hostel
            charges, mess charges, equipment’s such as desktop/laptop**, living
            expenses, insurance premium, admission acceptance fee etc. <br />
            <br />
            <ol className="expense-list">
              <li>
                Student to deposit the money in the loan account immediately on
                refund from the institute. An undertaking to be obtained from
                the student in this respect.{" "}
              </li>
              <li>
                Laptop/desktop maximum amount Rs.1.00 lakh can be considered,
                and it is to be purchased after getting admission and for study
                purpose at these institutes.
              </li>
            </ol>
          </p>
        ),
      },
      {
        id: "scheme-pmi-quantum-of-loan",
        title: "Quantum of Loan",
        content: <p>Maximum Upto Rs. 200.00 lakhs.</p>,
      },
      {
        id: "scheme-pmi-margin",
        title: "Margin",
        content: (
          <p>
            10% - Irrespective of the loan amount
            <br /> <br />
            <span style={{ fontWeight: "bold" }}>Note:</span>
            <br />
            Any scholarship received by the student is to be treated as margin
            and borrower has to deposit the scholarship amount in the bank
            account
          </p>
        ),
      },
      {
        id: "scheme-pmi-interest-rate",
        title: "Interest rate",
        content: (
          <ul className="expense-list">
            <table
              className="table table-bordered"
              style={{
                margin: "10px 0px",
              }}>
              <thead>
                <tr>
                  <th scope="col">Quantum</th>
                  <th scope="col">ROI</th>
                  <th scope="col">Collateral</th>
                  <th scope="col">Effective ROI</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Up to Rs. 40 Lakh</th>
                  <td>EBLR + 1.50% (Without Collateral)</td>
                  <td>
                    50% Up to 100% <br /> 100% and above
                  </td>
                  <td>
                    EBLR+1.25% <br />
                    EBLR+1.00%
                  </td>
                </tr>
                <tr>
                  <th scope="row">Above Rs. 40 Lakh</th>
                  <td>EBLR+2.00%(50% Up to &lt; 100% Collateral)</td>
                  <td>100% and above</td>
                  <td>EBLR+1.50%</td>
                </tr>
              </tbody>
            </table>
          </ul>
        ),
      },
      {
        id: "scheme-pmi-processing-charges",
        title: "Processing charges",
        content: <p>NIL</p>,
      },
      {
        id: "scheme-pmi-repayment-period",
        title: "Repayment period",
        content: <p> 180 months /15 years (After moratorium period)</p>,
      },
      {
        id: "scheme-pmi-moratorium-period",
        title: "Moratorium period",
        content: <p> Course period + 1 year</p>,
      },
      {
        id: "scheme-pmi-co-applicant-guarantor",
        title: "Co-applicant/Guarantor",
        content: (
          <ol className="expense-list">
            <li>
              Either of the parents or spouse to be made co-applicant /
              guarantor.
            </li>
            <li>
              Income/means of the co-applicant / guarantor is not to be
              considered while sanctioning the loan.
            </li>
            <li>
              Minimum CIC score or equivalent CIC score of the
              co-borrower/guarantor shall be 650 & above and -1.
            </li>
          </ol>
        ),
      },
      {
        id: "scheme-pmi-security",
        title: "Security",
        content: (
          <p>
            <ol className="expense-list">
              <li>
                <b>Up to Rs.40.00 lakh : </b>No collateral security or
                third-party guarantee to be taken, irrespective of the income /
                means of the guarantor. <br />
                <b>
                  {" "}
                  However, if collateral is offered for the loan below Rs. 40.00
                  Lakh interest concession is available.
                </b>
              </li>
              <li>
                <b>Above Rs.40.00 lakh : </b>Tangible collateral security of
                minimum value of 50% of total loan amount and interest
                accumulation during the course and moratorium period, acceptable
                to bank. <br />
                <b>
                  Interest concession of 0.50% is available if collateral
                  offered is 100% and above
                </b>
              </li>{" "}
              <br />
              <span style={{ fontWeight: "bold" }}>Note:</span>
              Collateral free loans up to Rs.40 lakhs shall be made available
              for the students who have secured admissions based on merit. For
              students who secured admissions through management quota, tangible
              collateral security with minimum value of 50% of total loan amount
              and interest accumulation during the course and moratorium period
              shall be stipulated.
            </ol>
          </p>
        ),
      },
      {
        id: "scheme-pmi-insurance",
        title: "Insurance",
        content: (
          <ol className="expense-list">
            <li>
              Term life insurance policy to be obtained & assigned in favour of
              the bank.
            </li>
            <li>
              The policy should be in the name of student, for an amount not
              less than the loan amount and for the tenure covering course,
              moratorium and repayment period.
            </li>
          </ol>
        ),
      },
      {
        id: "scheme-pmi-loans-to-students-in-subsequent-years",
        title: "Loans to students in subsequent years",
        content: (
          <ol className="expense-list">
            <li>
              The loan can also be granted to the students, who seek a loan in
              the subsequent years / terms and who have not availed educational
              loan from other bank / FI for the initial years.
            </li>
            <li>
              The earlier year fees and desktop/laptop expenses would not be
              reimbursed.
            </li>
            <li>
              The loan will include tuition fees, cost of books, hostel charges,
              mess charges, living expenses, insurance premium etc. for the
              subsequent years.
            </li>
            <li>
              All other terms & conditions will be as per the Union Education
              Scheme for Inland Studies.
            </li>
          </ol>
        ),
      },
    ],
  },
  {
    title:
      "Special Scheme for students of Premier Management & Technical Institutes for Academic Year 2022-2023",
    id: "scheme-pmt",
    subMenu: [
      {
        id: "scheme-pmt-eligibility",
        title: "Eligibility",
        content: (
          <ol className="expense-list">
            <li>A Bachelor degree in any discipline</li>
            <li>Minimum completed age of 25 years</li>
            <li>A valid GMAT / GRE score</li>
            <li>
              Minimum 4 years of full-time work experience after completion of
              graduation.
            </li>
          </ol>
        ),
      },
      {
        id: "scheme-pmt-margin",
        title: "Margin",
        content: <p>Nil.</p>,
      },
      {
        id: "scheme-pmt-quantum-of-loan",
        title: "Quantum of Loan",
        content: <p>Maximum up to Rs.35.00 Lakh</p>,
      },
      {
        id: "scheme-pmt-interest-rate",
        title: "Interest rate",
        content: (
          <p>
            <span
              style={{
                fontWeight: "bold",
              }}>
              EBLR + 0.10% (floating)
            </span>
            <ol className="expense-list">
              <li>
                EBLR prevailing on the date of disbursement (whether partial or
                full) shall be applicable.
              </li>
              <li>
                The External Benchmark shall be reset at least once in three
                months or any other frequency as prescribed by Bank / RBI.
              </li>
              <li>
                The spread will remain fixed for 3 years for the borrower. The
                first reset will be 3 years from the date of sanction and
                subsequent resets will be every 3 years from the last reset date
              </li>
              <li>
                The premium component will remain fixed till maturity of the
                loan.
              </li>
            </ol>
          </p>
        ),
      },
      {
        id: "scheme-pmt-repayment-period",
        title: "Repayment period",
        content: (
          <p>
            Maximum repayment period is 15 years (after completion of the
            course) or up to 60 years of age of the borrower, whichever is
            earlier
          </p>
        ),
      },
      {
        id: "scheme-pmt-moratorium-period",
        title: "Moratorium period",
        content: (
          <p>
            Moratorium up to course period only. Repayment to start from the
            next month of completion of course.
          </p>
        ),
      },
      {
        id: "scheme-pmt-other-terms-and-conditions",
        title: "Other terms and conditions",
        content: (
          <ol className="expense-list">
            <li>
              Life insurance cover equivalent to the loan amount is mandatory,
              preferably to be done with our channel partners under tie-up
              arrangement with our Bank.
            </li>
            <li>
              All other terms and conditions as per Special Education Loan
              Scheme for Premier Management & Technical Institutes to be
              complied with.
            </li>
          </ol>
        ),
      },
    ],
  },
  {
    title:
      "Premier Management & Technical Institutes (Tier-II) for Academic Year 2022-2023",
    id: "scheme-pmt-II",
    subMenu: [
      {
        id: "scheme-pmt-II-eligibility",
        title: "Eligibility",
        content: (
          <p>
            Mere admission to the course would suffice for loan, as admissions
            are secured through a competitive merit-based admission test and
            rigorous selection process.
          </p>
        ),
      },
      {
        id: "scheme-pmt-II-purpose",
        title: "Purpose",
        content: (
          <ol className="expense-list">
            <li>
              To meet tuition fees, cost of books, caution deposit*, hostel
              charges, mess charges, equipment such as desktop/laptop**, living
              expenses, insurance premium, expense for overseas student exchange
              programme, admission acceptance fee etc.
            </li>
            <li>
              *Student to deposit the money in the loan account immediately on
              refund from the institute. An undertaking to be obtained from the
              student in this respect.
            </li>
            <li>
              **Laptop/desktop cost can be reimbursed to the student if it is
              purchased for the purpose of study at these institutes.
            </li>
          </ol>
        ),
      },
      {
        id: "scheme-pmt-II-quantum-of-loan",
        title: "Quantum of Loan",
        content: <p>Maximum quantum of loan – Rs.20 Lakh</p>,
      },
      {
        id: "scheme-pmt-II-margin",
        title: "Margin",
        content: (
          <ul className="expense-list">
            <table
              className="table table-bordered"
              style={{
                margin: "10px 0px",
              }}>
              <thead>
                <tr>
                  <th scope="col">Loan amount</th>
                  <th scope="col">Margin</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Upto Rs.7.50 Lakh </th>
                  <td>Above Rs. 7.50 Lakh & up to Rs.20 Lakh</td>
                </tr>
                <tr>
                  <th scope="row">NIL</th>
                  <td>5% </td>
                </tr>
              </tbody>
            </table>
          </ul>
        ),
      },
      {
        id: "scheme-pmt-II-reimbursement-of-fee-for-1st-year-students",
        title: "Reimbursement of fee (for 1st year students)",
        content: (
          <p>
            <ol className="expense-list">
              <li
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}>
                Admission acceptance fee (AAF) :
              </li>
              As per the admission procedures, the student has to pay AAF to the
              institute within a short period of 15 days to confirm the
              selection. After this payment, the student approaches us for loan.
              In such cases, where the student has paid AAF to confirm the
              admission, he/she can be reimbursed the same once the loan is
              sanctioned (within the overall limit of Rs.20 Lakh) on production
              of original receipt and after adjusting the margin requirement.
              However, <b>loan should not be disbursed for payment of AAF.</b>
              <br />
              <li
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}>
                Term Fee :
              </li>
              1. If the student has paid the first term fee and approaches us
              for loan along with reimbursement of the fee paid, then the same
              is permitted on production of original receipt. <br />
              2. If the student approaches us during the subsequent terms along
              with request seeking reimbursement of previous terms fee, then the
              same is permitted on production of original receipt. <br />
              3. If there is a delay in sanctioning of loan by the field
              functionaries, and the borrower temporarily arranges for the funds
              to pay the term fee, then the same is permitted to be reimbursed
              to the student, upon request and on production of original
              receipt.
            </ol>
          </p>
        ),
      },
      {
        id: "scheme-pmt-II-mess-charges",
        title: "Mess charges",
        content: (
          <ol className="expense-list">
            <li>
              In cases where the mess charges are not mentioned/ indicated in
              the fee structure provided by the institute, then Branches to
              sanction an amount of upto Rs.5000 (maximum) per month towards the
              mess charges.
            </li>
            <li>
              In cases where the mess charges are included in the fee structure,
              then it will be sanctioned as mentioned.
            </li>
            <li>
              The mess charges will be allowed within the overall limit of Rs.20
              Lakh.
            </li>
          </ol>
        ),
      },
      {
        id: "scheme-pmt-II-living-expenses",
        title: "Living expenses",
        content: (
          <ol className="expense-list">
            <li>Living expenses will be upto Rs.5000 (maximum) per month.</li>
            <li>
              Living expenses will be allowed within the overall limit of Rs.20
              Lakh.
            </li>
          </ol>
        ),
      },
      {
        id: "scheme-pmt-II-student-exchange-programme",
        title: "Student exchange programme",
        content: (
          <ol className="expense-list">
            <li>
              The Institute releases the list of students going abroad on
              Student-Exchange Programme at the end of 1st year. Hence, the
              student may not know whether or not he is eligible for the said
              programme at the time of sanction of loan.{" "}
            </li>
            <li>
              The sanctioning authority can consider loan for the student
              exchange programme at the time of initial sanction or later on
              when the student gets to know that he has been selected.{" "}
            </li>
            <li>
              The expenses to be considered are tuition fee payable at the
              institute abroad along with lodging & boarding expenses, travel
              expenses for passage abroad and living expenses as mentioned above{" "}
            </li>
            <li>
              The student-exchange programme expenses will be allowed within the
              overall limit of Rs.20 Lakh.{" "}
            </li>
            <li>
              Loan to be disbursed only after obtaining proof of selection of
              the student. A copy of original receipt to be kept on records
              after completion of the programme.
            </li>
          </ol>
        ),
      },
      {
        id: "scheme-pmt-II-interest-rate",
        title: "Interest rate",
        content: (
          <ul className="expense-list">
            <table
              className="table table-bordered"
              style={{
                margin: "10px 0px",
              }}>
              <thead>
                <tr>
                  <th scope="col">Male</th>
                  <th scope="col"> Female / SC / ST / Minority</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">EBLR + 1.75% (floating)</th>
                  <td>EBLR + 1.25% (floating)</td>
                </tr>
              </tbody>
            </table>
            <b
              style={{
                color: "black",
              }}>
              Note:
            </b>
            <li>
              EBLR prevailing on the date of disbursement (whether partial or
              full) shall be applicable..
            </li>
            <li>
              The External Benchmark shall be reset at least once in three
              months or any other frequency as prescribed by Bank / RBI..
            </li>
            <li>
              The spread will remain fixed for 3 years for the borrower. The
              first reset will be 3 years from the date of sanction and
              subsequent resets will be every 3 years from the last reset date..
            </li>
            <li>
              The premium component will remain fixed till maturity of the
              loan..
            </li>
            <li>
              If loan is taken over by another bank/FI, then normal rate of
              interest, as applicable on the date of sanction, to be charged
              ab-initio. However, no take over charge to be levied..
            </li>
            <li>
              Servicing of interest during course/moratorium period is optional.
            </li>
          </ul>
        ),
      },
      {
        id: "scheme-pmt-II-processing-charges",
        title: "Processing Charges",
        content: <p>NIL</p>,
      },
      {
        id: "scheme-pmt-II-repayment-period",
        title: "Repayment period",
        content: <p>15 years (180 months).</p>,
      },
      {
        id: "scheme-pmt-II-moratorium-period",
        title: "Moratorium period",
        content: <p>Course period + 1 year</p>,
      },
      {
        id: "scheme-pmt-II-co-applicant-guarantor",
        title: "Co-applicant/Guarantor",
        content: (
          <ol className="expense-list">
            <li>
              Either of the parents / spouse (or guardian in case both parents
              are not alive) to join in as co-applicant.{" "}
            </li>
            <li>
              Minimum CIC score of the co-borrower/guarantor should be 700. CIC
              score of -1 can be accepted for co-borrower/guarantor.{" "}
            </li>
            <li>
              Parent’s or spouse’s income/means need not be reckoned for
              sanctioning the loan.
            </li>
          </ol>
        ),
      },
      {
        id: "scheme-pmt-II-security",
        title: "Security",
        content: (
          <ul className="expense-list">
            <table
              className="table table-bordered"
              style={{
                margin: "10px 0px",
              }}>
              <tbody>
                <tr>
                  <th scope="row">Upto 4.00</th>
                  <td>
                    <ul>
                      <li>No security is required.</li>
                      <li>
                        However, loan has to compulsorily covered under CGFSEL.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th scope="row">4.00 to 7.50</th>
                  <td>
                    <ul>
                      <li>
                        Suitable third party guarantee acceptable to the bank to
                        be taken OR
                      </li>
                      <li>Loan to be covered under CGFSEL</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th scope="row">7.50 to 20.00</th>
                  <td>
                    <ul>
                      <li>
                        Obtaining collateral security is not mandatory. However
                        suitable third-party guarantee with adequate means (i.e.
                        minimum equivalent to loan amount) acceptable to bank to
                        be taken for loan above Rs.7.50 lakh.
                      </li>
                      <li>
                        However, if collateral security is offered by the
                        applicant incentives like waiver of margin are
                        applicable.
                      </li>
                      <li>
                        Collateral coverage of the loan will be decided on
                        principal loan amount and interest accumulation during
                        course and moratorium period. The property offered as
                        security should be in the name of borrower / co-borrower
                        or other immediate family members (mother/ brother/
                        sister/ spouse).
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </ul>
        ),
      },
      {
        id: "scheme-pmt-II-insurance",
        title: "Insurance",
        content: (
          <ol className="expense-list">
            <li>
              Term life insurance policy to be obtained & assigned in favour of
              the bank.
            </li>
            <li>
              The policy should be in the name of student, for an amount not
              less than the loan amount and for the tenure covering course,
              moratorium and repayment period.
            </li>
          </ol>
        ),
      },
      {
        id: "scheme-pmt-II-other-terms-and-conditions",
        title: "Other terms and conditions",
        content: (
          <ol className="expense-list">
            <li>NRI students are not covered under the scheme. </li>
            <li>
              All other terms & conditions as per Union Education Scheme for
              studies in India are to be complied with.
            </li>
          </ol>
        ),
      },
      {
        id: "scheme-pmt-II-loans-to-students-in-subsequent-years",
        title: "Loans to students in subsequent years",
        content: (
          <ol className="expense-list">
            <li>
              The loan can also be granted to the students, who seek a loan in
              the subsequent years / terms (i.e. academic year 2021-22 onwards)
              and who have not availed educational loan from other bank / FI for
              the initial years.{" "}
            </li>
            <li>
              The fee for earlier years / terms and laptop/desktop expenses
              would not be reimbursed.{" "}
            </li>
            <li>
              The loan will include tuition fees, cost of books, hostel charges,
              mess charges, living expenses, insurance premium, student exchange
              programme etc. required that academic year / term onwards.{" "}
            </li>
            <li>
              All other terms & conditions as mentioned in Para (3), (4), (6) to
              (16) above shall apply equally in this case also.
            </li>
          </ol>
        ),
      },
    ],
  },
  {
    title: "EDUCATION LOAN – STUDY IN INDIA/ABROAD/NRI STUDENTS",
    id: "education-loan",
    subMenu: [
      {
        id: "education-loan-purpose",
        title: "Purpose",
        content: (
          <p>
            To the meritorious students to pursue higher / professional/
            technical education in recognized institution in India & A
          </p>
        ),
      },
      {
        id: "education-loan-eligibility",
        title: "Student Eligibility",
        content: (
          <ol className="expense-list">
            <li>Should be an Indian national.</li>
            <li>
              Should have secured admission in India or abroad to pursue higher
              / professional/ technical education, through appropriate selection
              process and cleared the qualifying examination, if any.
            </li>{" "}
            <li>
              NRI student should hold Indian Passport & has secured admission to
              a course for studies in India only.
            </li>
          </ol>
        ),
      },
      {
        id: "education-loan-age-of-student",
        title: "Age of student",
        content: (
          <p>No restriction with regard to the minimum and maximum age.</p>
        ),
      },
      {
        id: "education-loan-co-applicant",
        title: "Co-Applicant",
        content: (
          <ol className="expense-list">
            <li>
              The parent(s) of the student has to compulsorily join in as
              co-applicant.
            </li>
            <li>
              In case of a married person, co-applicant can be
              spouse/parent(s)/parent(s)-in-law.
            </li>
            <li>
              In cases where both the parents are not alive, close relative or
              guardian to be taken as co-applicant.
            </li>
            <li>The co-applicant should be Indian national</li>
          </ol>
        ),
      },
      {
        id: "education-loan-eligible-courses",
        title: "Eligible Courses",
        content: (
          <p>
            <b>Courses covered in India:</b>
            <ol className="expense-list">
              <li>
                Graduation / post-graduation / diploma courses from recognized
                universities
              </li>
              <li>GraduationTechnical / professional / management courses</li>
              <li>GraduationPhD courses</li>
            </ol>
            <b>Courses covered outside India:</b>
            <ol className="expense-list">
              <li>
                Graduation / post-graduation degrees offered by recognized
                universities
              </li>
              <li>
                Certified degree courses conducted by CIMA, London, CPA, USA and
                other such institutes
              </li>
              <li>PhD courses</li>
              <li>Diploma courses abroad are not covered</li>
            </ol>
          </p>
        ),
      },
      {
        id: "education-loan-subsequent-years",
        title: "Loan in subsequent years",
        content: (
          <p>
            Educational loans can also be considered for eligible students in
            case they approach the bank in the subsequent years of the course
            provided the student has not availed loan for initial years from any
            other bank / FI.
          </p>
        ),
      },
      {
        id: "education-loan-quantum",
        title: "Quantum",
        content: (
          <ol className="expense-list">
            <li>
              Need based finance to meet the expenses will be considered taking
              into account margins norms.
            </li>
            <li>
              There is no restriction on maximum amount of loan that can be
              sanctioned under the education loan scheme.
            </li>
            <li>
              For NRI students Maximum quantum Rs.20 Lakhs & Rs.30 Lakhs for
              premium institutes.
            </li>
          </ol>
        ),
      },
      {
        id: "education-loan-margin",
        title: "Margin",
        content: (
          <table
            className="table table-bordered"
            style={{
              margin: "10px 0px",
            }}>
            <thead>
              <tr>
                <th scope="col">Quantum of Loan</th>
                <th scope="col">Margin</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Up to Rs. 4 Lakh</th>
                <td>Nil</td>
              </tr>
              <tr>
                <th scope="row">Above Rs. 4 Lakh </th>
                <td>
                  Studies in India (5%) <br />
                  Studies in India (15%)
                </td>
              </tr>
              <tr>
                <th scope="row">NRI students (irrespective of loan amount)</th>
                <td>15%</td>
              </tr>
            </tbody>
          </table>
        ),
      },
      {
        id: "education-loan-expenses-considered",
        title: "Expenses considered for loan",
        content: (
          <ol className="expense-list">
            <li>Tuition fee payable to college/ school/ university</li>
            <li>Hostel / mess charges</li>
            <li>Examination/ Library/ Laboratory fee</li>
            <li>Insurance premium for the student borrower</li>
            <li>
              Caution deposit, Building fund/ refundable deposit supported by
              Institution bills/ receipts
            </li>
            <li>Purchase of books/ equipment’s/ instruments/ uniforms</li>
            <li>
              Purchase of computer/laptop at reasonable cost, if required for
              completion of the course
            </li>
            <li>Travel expenses/ passage money for studies abroad</li>
            <li>Admission acceptance fee</li>
            <li>
              Any other expense required to complete the course - like study
              tours, project work, thesis, etc.
            </li>
          </ol>
        ),
      },
      {
        id: "education-loan-repayment-period",
        title: "Repayment Tenure",
        content: (
          <ol className="expense-list">
            <li>
              The maximum repayment tenure will be 15 years (after course &
              moratorium period) Irrespective of quantum of loan.{" "}
            </li>
            <li>
              The accrued interest during the moratorium period (if not
              serviced) will be added to the principal amount while calculating
              Equated Monthly Installments (EMI) for repayment.
            </li>
          </ol>
        ),
      },
      {
        id: "education-loan-interest-rate",
        title: "Rate of Interest",
        content: (
          <ol className="expense-list">
            <li>Please click here to know our latest interest rates </li>
            <li>
              If interest is regularly serviced during the course and moratorium
              period, i.e. prior to the commencement of repayment, then the
              student is eligible for interest rebate.{" "}
            </li>
            <li>
              Simple interest will be calculated during course / moratorium
              period; subsequently compounding interest will be cha
            </li>
          </ol>
        ),
      },
      {
        id: "education-loan-security",
        title: "Security",
        content: (
          <table
            className="table table-bordered"
            style={{
              margin: "10px 0px",
            }}>
            <thead>
              <tr>
                <th scope="col">Quantum of Loan</th>
                <th scope="col">Security</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Up to Rs. 4 Lakh</th>
                <td>
                  No security is required. However, loan has to be compulsory
                  covered under CGFSEL
                </td>
              </tr>
              <tr>
                <th scope="row">Above Rs.4 Lakh & up to Rs.7.50 Lakh</th>
                <td>
                  Suitable third party guarantee acceptable to the bank to be
                  taken OR Loan to be covered unde
                </td>
              </tr>
              <tr>
                <th scope="row">Above Rs.7.50 Lakh</th>
                <td>
                  Tangible collateral security of minimum value equivalent to
                  the loan amount and interest accumulation during course &
                  moratorium period, acceptable to the bank**Tangible collateral
                  security of minimum value equivalent to the loan amount and
                  interest accumulation during course & moratorium period,
                  acceptable to the bank**
                </td>
              </tr>
            </tbody>
          </table>
        ),
      },
      {
        id: "education-loan-insurance",
        title: "Insurance",
        content: (
          <p>
            Life insurance of the student is mandatory to the tune of the loan
            amount.
          </p>
        ),
      },
      {
        id: "education-loan-loans-to-students-in-subsequent-years",
        title: "Loans to students in subsequent years",
        content: (
          <ol className="expense-list">
            <li>
              The loan shall be sanctioned / disbursed from the branch nearest
              to the place of domicile of the student
            </li>
            <li>Loan would be disbursed directly to the institute</li>
          </ol>
        ),
      },
    ],
  },
  {
    title:
      "UNION KISAN SHIKSHA SUVIDHA (UKSS) – STUDENTS BELONGING TO AGRARIAN FAMILY",
    id: "UKSS",
    subMenu: [
      {
        id: "UKSS-eligibility",
        title: "Student Eligibility",
        content: (
          <ol className="expense-list">
            <li>Should be an Indian national.</li>
            <li>
              Belong to farming community with Primary source of income (above
              50% of total income) should be from agriculture allied activity.
            </li>
            <li>Secured at least 60% marks at higher secondary level.</li>
            <li>No restriction with regard to the minimum and maximum age</li>
            <li>
              The parent(s) of the student has to compulsorily join in as
              co-applicant. In case of a married person, co-applicant can be
              spouse/parent(s)/parent(s)-in-law.
            </li>
          </ol>
        ),
      },
      {
        id: "UKSS-purpose",
        title: "Purpose",
        content: (
          <p>
            To the meritorious students to pursue Medical / Engineering /
            Management courses leading to graduate / post graduate education.
          </p>
        ),
      },
      {
        id: "UKSS-courses",
        title: "Eligible Courses",
        content: (
          <p>
            Medical / Engineering / Management courses leading to graduate /
            post graduate education only from NAAC accredited institutions or
            accredited by NBA or Institutions of National Importance or Central
            Funded Technical Institutions. Medical courses should have been
            approved by Medical Council of India (MCI).
          </p>
        ),
      },
      {
        id: "UKSS-loan-subsequent-years",
        title: "Loans in subsequent years of course",
        content: (
          <p>
            Educational loans can also be considered for eligible students in
            case the student approach the bank in the subsequent years of the
            course provided the student has not availed loan for initial years
            from any other bank / FI.
          </p>
        ),
      },
      {
        id: "UKSS-quantum",
        title: "Quantum",
        content: (
          <ol className="expense-list">
            <li>
              Need based finance to meet the expenses worked out will be
              considered taking into account margins norms.{" "}
            </li>
            <li>
              The minimum quantum of loan under the Scheme should be above ₹7.50
              lakh.{" "}
            </li>
            <li>
              The maximum quantum of loan under the Scheme will be Rs.15.00 lakh
              for inland studies & Rs.30.00 lakh for abroad studies
            </li>
          </ol>
        ),
      },
      {
        id: "UKSS-margin",
        title: "Margin",
        content: (
          <ol className="expense-list">
            <li>Study in India – 5% </li>
            <li>Study Abroad – 15% </li>
          </ol>
        ),
      },
      {
        id: "UKSS-loan-expenses",
        title: "Expenses Considered",
        content: (
          <ol className="expense-list">
            <li>Tuition fee payable to college/ school/ university</li>
            <li>Hostel / mess charges</li>
            <li>Examination/ Library/ Laboratory fee</li>
            <li>Insurance premium for the student borrower</li>
            <li>Purchase of books/ equipment’s/ instruments/ uniforms</li>
            <li>
              Purchase of computer/laptop at reasonable cost, if required for
              completion of the course
            </li>
            <li>Travel expenses/ passage money for studies abroad</li>
            <li>
              Any other expense required to complete the course - like study
              tours, project work, thesis, etc.
            </li>
          </ol>
        ),
      },
      {
        id: "UKSS-repayment-period",
        title: "Repayment tenure",
        content: (
          <ol className="expense-list">
            <li>
              The maximum repayment tenure will be 15 years (after course &
              moratorium period).
            </li>
            <li>
              The accrued interest during the moratorium period (if not
              serviced) will be added to the principal amount while calculating
              Equated Monthly Installments (EMI) for repayment.
            </li>
          </ol>
        ),
      },
      {
        id: "UKSS-rate-of-interest",
        title: "Rate of Interest",
        content: (
          <ol className="expense-list">
            <li>Please click here to know our latest interest rates</li>
            <li>
              If interest is regularly serviced during the course and moratorium
              period, i.e. prior to the commencement of repayment, then the
              student is eligible for interest rebate**.
            </li>
          </ol>
        ),
      },
      {
        id: "UKSS-security",
        title: "Security",
        content: (
          <p>
            <b>
              Mortgage of Agricultural land as collateral security subject to
              following conditions:
            </b>
            <ol className="expense-list">
              <li>
                Agricultural properties will be accepted as Collateral Security
                for Education loans, only in States where it is permitted to
                mortgage Agricultural Land for purpose other than Agricultural
                Activities.{" "}
              </li>
              <li>
                Agricultural Land accepted as collateral security should be
                properly demarcated with boundaries and easily
                accessible/marketable.
              </li>
            </ol>
          </p>
        ),
      },
      {
        id: "UKSS-insurance",
        title: "Insurance",
        content: (
          <p>
            Life insurance of the student is mandatory to the tune of the loan
            amount.
          </p>
        ),
      },
    ],
  },
];

const LeftSidebar = () => {
  return (
    <div className="left-sidebar" style={{ padding: "24px" }}>
      <div className="index-menu-bar">
        {menuData.map((item, index) => (
          <ul key={index} className="menu">
            <div className="menu-title">
              <a href={`#${item.id}`}>{item.title}</a>
            </div>
            <div className="submenu">
              {item.subMenu.map((subMenuItem, subIndex) => (
                <li key={subIndex}>
                  <a href={`#${subMenuItem.id}`}>
                    <AiOutlineRight /> {subMenuItem.title}
                  </a>
                </li>
              ))}
            </div>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default LeftSidebar;
