import React from "react";

const Security = () => {
  return (
    <div className="tile" id="security">
      {/* create a dot */}
      <li> Security for SBI education loan</li>
      <ul className="expense-list">
        <li>State Bank of India Education Loan :</li>

        <table
          className="table table-bordered"
          style={{
            margin: "10px 0px",
          }}>
          <thead>
            <tr>
              <th scope="col">
                Loan Amount for State Bank of India education loan
              </th>
              <th scope="col">
                Security for State Bank of India education loan
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Up to Rs.4 lakhs</th>
              <td>Parent or guardian as co-borrower</td>
            </tr>
            <tr>
              <th scope="row">Above Rs.4 lakhs to up to Rs.7.5 lakhs</th>
              <td>
                Parent or guardian as co-borrower and collateral security in the
                form of a third party guarantee
              </td>
            </tr>
            <tr>
              <th scope="row">Above Rs.7.5 lakhs</th>
              <td>
                Parent or guardian as co-borrower and tangible collateral
                security
              </td>
            </tr>
          </tbody>
        </table>

        <li>SBI Scholar Education Loan </li>
        <li>
          The student’s parents or guardian who so ever sign the application as
          co-borrower needs to provide tangible collateral security of full
          value only to avail extra loan amount for list A and list C
          institutions. <b>State Bank of India education loans</b> can also be
          availed without security under the scholar scheme.
        </li>
        <li>
          The bank does not take the guarantee of any collateral or third-party.
          But, parents or guardians will have to execute the loan documents as
          co-borrower.
        </li>
        <p>
          <span
            style={{
              fontWeight: "bold",
              margin: "10px 0px",
            }}>
            Margin for State Bank of India education loan :
          </span>
          <br /> For <b>State Bank of India education loan Scheme</b>, there is
          no margin for loans up to Rs.4 lakhs. For loans above Rs.4 lakhs, the
          margin is 5% for studies in India and 15% for SBI overseas education
          loan.
        </p>
      </ul>

      {/* CREATE A table with three columns and 2 rows */}
    </div>
  );
};

export default Security;
