import React from "react";
import { AiOutlineRight } from "react-icons/ai";

export const menuData = [
  {
    title: "Attractive Features",
    id: "features",
    subMenu: [
      {
        id: "features-avaibility",
        title: "Wide Ranging Availability",
        content: (
          <p>
            Cosmo Educational Loan Scheme is available for a wide range of
            graduate and post-graduate programme.
          </p>
        ),
      },
      {
        id: "long-term-period",
        title: "Long Time Period",
        content: (
          <p>Maximum period will be 10 yrs (including moratorium period)</p>
        ),
      },
      {
        id: "fast-processing",
        title: "Fast Processing",
        content: <p>Easy and Immediate processing of the loan.</p>,
      },
    ],
  },
  {
    title: "The wing to your dreams!",
    id: "wing",
    subMenu: [
      {
        id: "loan-scheme",
        title: "Education Loan Scheme",
        content: (
          <p>
            Cosmo Education Loan Scheme is designed to give deserving
            meritorious students the financial support they require to study and
            build a successful career. The young generation of India is perhaps
            its most priceless resource, and we really want them to pursue their
            goals in life unburdened from the pressures of earning while still
            studying.
          </p>
        ),
      },
    ],
  },
  {
    title: "Loan highlights",
    id: "loan-highlights",
    subMenu: [
      {
        title: "Loan Limit",
        id: "loan-limit",
        content: (
          <ul className="expense-list">
            <li>Education Loan in India- Loan up to Rs. 50.00 Lacs</li>
            <li>Education Loan abroad- Rs. 125.00 Lacs</li>
          </ul>
        ),
      },
      {
        title: "Rate of Interest",
        id: "rate-of-interest",
        content: (
          <ul className="expense-list">
            <li>8.00%* p.a. for Female Students</li>
            <li>8.40%* p.a. for Male Students in India and Abroad</li>
          </ul>
        ),
      },
      {
        title: "Time of Disbursement",
        id: "time-of-disbursement",
        content: (
          <p
            style={{
              marginLeft: "20px",
            }}>
            30 Days
          </p>
        ),
      },
      {
        title: "Repayment Period",
        id: "repayment-period",
        content: (
          <p
            style={{
              marginLeft: "20px",
            }}>
            10 Years
          </p>
        ),
      },
      {
        title: "Prepayment Charges",
        id: "prepayment-charges",
        content: (
          <p
            style={{
              marginLeft: "20px",
            }}>
            Nil
          </p>
        ),
      },
      {
        title: "Loan Charges",
        id: "loan-charges",
        content: (
          <p
            style={{
              marginLeft: "20px",
            }}>
            NIL for studies in India.
            <br /> 0.50% for studies abroad
          </p>
        ),
      },
      {
        title: "Foreclosure Charges",
        id: "foreclosure-charges",
        content: (
          <ul className="expense-list">
            <li> upto 12 mths - 3%</li>
            <li>12 Mths Before 24 mths - 2%</li>
            <li>After 24 months - 1%</li>
          </ul>
        ),
      },
    ],
  },
  {
    title: "Eligibility",
    id: "eligibility",
    subMenu: [
      {
        title: "Criteria",
        id: "eligibility-1",
        content: (
          <ul className="expense-list">
            <li>
              The applicant should be regular member or nominal member of bank.
            </li>
            <li>
              Applicant/parents should have banking relation with our bank.
            </li>
            <li>The student should be an Indian Resident.</li>
            <li>
              The student should have secured admission to
              professional/technical course in India or abroad through entrance
              test /merit based selection process.
            </li>
            <li>
              Courses through Educational Institutes / Organizations of repute &
              recognition will be eligible for loan limit.
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    title: "Documents Required",
    id: "docs-required",
    subMenu: [
      {
        title: "List of Documents",
        id: "docs-required-1",
        content: (
          <ul className="expense-list">
            <li>
              All documents as per KYC norms viz. Residence proof and identity
              proof of student borrower and his parents / guardian.{" "}
            </li>
            <li>Mark sheet of last qualifying examination. </li>
            <li>Proof of admission, scholarship, assistantship </li>
            <li>Schedule of expenses for the specified course. </li>
            <li>
              Salary slips of student borrower and or his parents / guardian /
              spouse.{" "}
            </li>
            <li>Account statement for last six months of borrower. </li>
            <li>Statement of assets & liabilities of co-borrower. </li>
            <li>Proof of income – salary slip / form 16. </li>
            <li>
              Passport & visa documents in case of loan for abroad studies.{" "}
            </li>
            <li>
              In case of change of course / completion of course / termination
              of studies / any refund of fees college / institution / successful
              placement / abstention of job / change of job etc. student
              borrower should inform bank.
            </li>
          </ul>
        ),
      },
    ],
  },
];

const LeftSidebar = () => {
  return (
    <div className="left-sidebar" style={{ padding: "24px" }}>
      <div className="index-menu-bar">
        {menuData.map((item, index) => (
          <ul key={index} className="menu">
            <div className="menu-title">
              <a href={`#${item.id}`}>{item.title}</a>
            </div>
            <div className="submenu">
              {item.subMenu.map((subMenuItem, subIndex) => (
                <li key={subIndex}>
                  <a href={`#${subMenuItem.id}`}>
                    <AiOutlineRight /> {subMenuItem.title}
                  </a>
                </li>
              ))}
            </div>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default LeftSidebar;
