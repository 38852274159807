import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import EventData from "../../data/event/EventData.json";
import InstructorData from "../../data/instructor/InstructorData.json";
import CourseData from "../../data/course/CourseData.json";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import AboutSix from "../../components/about/AboutSix";
import { slugify } from "../../utils";
import CurriculumTabContent from "../../data/course/CurriculumTabContent.json";

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button">
      {children}
    </button>
  );
};

const CurriculumContent = () => {
  const [activeId, setActiveId] = useState("0");

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
      {CurriculumTabContent.map((accordion, i) => (
        <Accordion.Item
          eventKey={i.toString()}
          key={i}
          onClick={() => toggleActive(i.toString())}
          bsPrefix={`edu-accordion-item ${
            activeId === i.toString() ? "bg-active" : ""
          }`}>
          <div className="edu-accordion-header">
            <CustomToggle eventKey={i.toString()}>
              {accordion.title}
            </CustomToggle>
          </div>
          <Accordion.Body bsPrefix="edu-accordion-body">
            <ul>
              {accordion.content.map((title, index) => (
                <li key={index}>
                  <div className="text">
                    <i className="icon-draft-line"></i>
                    {title}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const StudyInIndiaDreamz = () => {
  const { id } = useParams();
  const eventId = parseInt(1, 10);
  const data = EventData.filter((event) => event.id === eventId);
  const eventItem = data[0];

  const [activeId, setActiveId] = useState("0");
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const courseId = parseInt(1, 10);
  const dataCourse = CourseData.filter((course) => course.id === courseId);
  const courseItem = dataCourse[0];

  const indexOfInstructor = InstructorData.findIndex(function (instructor) {
    return slugify(instructor.name) === slugify(courseItem.instructor);
  });
  const instructor = InstructorData[indexOfInstructor];
  const instructorExcerpt = instructor.details.substring(0, 190) + "...";

  const [contentTab, setContentTab] = useState("overview");
  const handleTab = (content) => {
    if (content === "overview") {
      setContentTab("overview");
    } else if (content === "curriculum") {
      setContentTab("curriculum");
    } else if (content === "instructor") {
      setContentTab("instructor");
    } else if (content === "reviews") {
      setContentTab("reviews");
    }
  };

  return (
    <>
      <SEO
        title="Study Abroad – Colleges, Courses, Exams, Free Counseling"
        description="Find your international education on Educations.com - university and college study abroad education programs, student reviews, and course information."
        keywords="Study abroad,International education,Overseas education,Higher education abroad,Study opportunities worldwide,Education opportunities abroad,Foreign universities"
        canonical="https://globaldreamzedu.com/study-in-row"
      />
      <Layout>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_13.jpg`}
                    alt="Event Thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h3 className="title">Study in Rest of World?</h3>
                  <p>
                    A prime location for anyone seeking a top-notch education,
                    which is one of the main reasons why international graduate
                    and undergraduate students choose to study there. For
                    international students interested in breath taking
                    landscapes, and ancient culture and architecture, lovely
                    mountain and coastline hikes, Ireland is a perfect choice.
                  </p>
                  <p>
                    IMD World Competitiveness Yearbook ranks Ireland's higher
                    education system among the top 20 worldwide in 2014. It is
                    marked by innovation, adaptability, and has strong ties to
                    the industry. Ireland's economy has grown at one of the
                    quickest rates in the world in the previous ten years, and
                    the contribution of its education sector has been vital in
                    it.
                  </p>
                  <p>
                    There's something for nearly every single pupil among
                    Ireland's highly regarded education institutions, which
                    offer more than 4000-course programmes in a range of
                    disciplines including science, engineering, medicine,
                    business, technology, law, history, languages, literature,
                    and psychology.
                  </p>
                  <p>
                    Moreover, Ireland is home to more than thousand
                    international corporations, mostly due to its welcoming FDI
                    regulations and business-friendly environment.
                  </p>
                  <p>
                    In fields like artificial intelligence, ICT, telecom, and
                    the biological sciences, many of these MNCs are looking for
                    MS degree holders from Irish colleges. The European
                    headquarters of several firms, including Google, Airbnb,
                    Facebook, Roche, Abbott, and others, are located in Ireland.
                    There are thousands of internship opportunities available
                    for new Indian students studying in Ireland.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <h4>Why Do You Need to Consult with Career Counsellors?</h4>

                <p>
                  Getting in touch with the study abroad consultants might seem
                  unnecessary to you. Contrarily, the consultants can help you
                  in a variety of ways and ensures you achieve your goals of
                  studying abroad.
                </p>
              </div>

              <div className="col-lg-12">
                <h4>Career Guidance</h4>
                <p>
                  Based on your profile and objectives, international education
                  experts can assist you to choose the right courses and
                  universities. By giving you adequate information on the
                  courses relevant to your area of interest, they also assist
                  you in making an informed choice.
                </p>

                <h4>Test Preparation</h4>
                <p>
                  You almost always have to take entrance exams for studying
                  abroad. In addition to helping with studying abroad, we also
                  offer qualifying test preparation services. Therefore, you can
                  use these consultations to be ready for the IELTS, TOEFL, etc.
                </p>

                <h4>University Selection</h4>
                <p>
                  GlobalDreamz helps you choose the right universities for you
                  based on your academic and non-academic strengths and career
                  ambitions. Our counsellors finds the right courses and
                  colleges which are most suited to you thus ensuring your
                  long-term success. Our education counsellors have helped
                  students make the best programme choices.
                </p>

                <h4>Course Selection</h4>
                <p>
                  Not sure which path to follow? Numerous aspects, such as
                  deadlines, budgets, employability, career aspirations, and
                  many more, must be taken into account when selecting a course.
                  Come to us, and we'll help you assess your abilities and
                  choose the right course for you.
                </p>

                <h4>Visa and Financial Help</h4>
                <p>
                  Getting a visa is a tough process that requires a tonne of
                  paperwork that you just cannot afford to overlook.
                  Consultation services for international education walk you
                  through every step of the procedure, compile the necessary
                  paperwork, and submit your visa application on time.
                  Additionally, they train you for visa interviews. The top
                  study abroad advisors informs you about the scholarships that
                  are available and put you in touch with the appropriate people
                  to get financial help.
                  <br></br>
                  <br></br>
                  Would you like to study abroad? Dreaming of studying abroad at
                  a top university? GlobalDreamz Study Abroad Counselling can
                  help you realize your dream of overseas education.
                </p>
              </div>
            </div>
          </div>
        </div>
        <AboutSix />
      </Layout>
    </>
  );
};
export default StudyInIndiaDreamz;
