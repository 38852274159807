import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import EventData from "../../data/event/EventData.json";
import InstructorData from "../../data/instructor/InstructorData.json";
import CourseData from "../../data/course/CourseData.json";
import { Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import AboutSix from "../../components/about/AboutSix";
import { slugify } from "../../utils";
import CurriculumTabContent from "../../data/course/CurriculumTabContent.json";

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type="button"
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className="edu-accordion-button">
      {children}
    </button>
  );
};

const CurriculumContent = () => {
  const [activeId, setActiveId] = useState("0");

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
      {CurriculumTabContent.map((accordion, i) => (
        <Accordion.Item
          eventKey={i.toString()}
          key={i}
          onClick={() => toggleActive(i.toString())}
          bsPrefix={`edu-accordion-item ${
            activeId === i.toString() ? "bg-active" : ""
          }`}>
          <div className="edu-accordion-header">
            <CustomToggle eventKey={i.toString()}>
              {accordion.title}
            </CustomToggle>
          </div>
          <Accordion.Body bsPrefix="edu-accordion-body">
            <ul>
              {accordion.content.map((title, index) => (
                <li key={index}>
                  <div className="text">
                    <i className="icon-draft-line"></i>
                    {title}
                  </div>
                  <div className="icon">
                    <i className="icon-lock-password-line"></i>
                  </div>
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const StudyInUKDreamz = () => {
  const { id } = useParams();
  const eventId = parseInt(1, 10);
  const data = EventData.filter((event) => event.id === eventId);
  const eventItem = data[0];

  const [activeId, setActiveId] = useState("0");
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  const courseId = parseInt(1, 10);
  const dataCourse = CourseData.filter((course) => course.id === courseId);
  const courseItem = dataCourse[0];

  const indexOfInstructor = InstructorData.findIndex(function (instructor) {
    return slugify(instructor.name) === slugify(courseItem.instructor);
  });
  const instructor = InstructorData[indexOfInstructor];
  const instructorExcerpt = instructor.details.substring(0, 190) + "...";

  const [contentTab, setContentTab] = useState("overview");
  const handleTab = (content) => {
    if (content === "overview") {
      setContentTab("overview");
    } else if (content === "curriculum") {
      setContentTab("curriculum");
    } else if (content === "instructor") {
      setContentTab("instructor");
    } else if (content === "reviews") {
      setContentTab("reviews");
    }
  };

  return (
    <>
      <SEO
        title="Complete Guide to Study in UK for Indian Students"
        description="Complete guide to study in the UK for Indian students, covering visa requirements, top universities, accommodation, and more. Get expert advice for a successful study abroad journey"
        keywords="study, UK, education, universities, colleges, scholarships, international students, postgraduate, undergraduate, higher education, student visa, academic programs, British education system, study abroad, British culture, language proficiency, student accommodation."
        canonical="https://globaldreamzedu.com/study-in-uk"
      />
      <Layout>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img
                    className="mt--80"
                    src={`${process.env.PUBLIC_URL}/images/event/event-details/inner_9.jpg`}
                    alt="study uk students"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="edu-event-details-area edu-event-details  bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h3 className="title">Why should you study in UK?</h3>
                  <p>
                    Based on the World University Rankings, three of the top 10
                    universities in the world are located in the UK, which is a
                    popular study abroad destination for students from around
                    the world. Universities, international corporations, and
                    government organisations around the world will honour
                    degrees earned from UK universities. A wide range of choices
                    exists for your topic of study and institution when you want
                    to study in the UK. You can choose from the varied 140,000
                    courses from the universities in Scotland, Wales, England,
                    Northern Ireland, etc. You can also order prospectuses by
                    contacting the college or university directly.
                  </p>
                  <p>
                    The one-year span of all Master's programmes may prove to be
                    a significant advantage if you are intending to pursue a
                    postgraduate degree in the UK. The Quality Assurance Agency
                    for Higher Education periodically inspects UK universities
                    to make sure they uphold the expectedly high levels of
                    learning.
                  </p>
                  <p>
                    You will have the chance to learn from some of the most
                    accomplished academics in the world, be creative, build your
                    skill set, and gain confidence. Inspiring instruction,
                    first-rate facilities, and a cutting-edge research
                    environment are all features of higher education in the UK.
                    Inspiring students to develop their abilities and creativity
                    is the main goal of education in the UK.
                  </p>
                  <p>
                    A wide range of courses and study options are available. It
                    is estimated that more than 300 universities and colleges
                    educate students in the country. A total of 70000
                    undergraduate, postgraduate, and higher level courses are
                    offered at these institutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <h4>Best Courses to Study in the UK</h4>
                <ul className="list-style-1 mb--20">
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Business Management
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Economics
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Arts and design
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i> Computer
                    Science
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Accounting and Finance
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Mechanical Engineering
                  </li>
                  <li>
                    <i className="icon-checkbox-circle-fill-solid"></i>
                    Biological Science
                  </li>
                </ul>
              </div>
              <div className="col-12">
                <div className="course-details-content">
                  <h3 className="title">Our Services</h3>
                  <h5>University Selection</h5>
                  <p>
                    Students frequently perceive their strengths and weaknesses
                    based on the bandwagon effect. Contrary to other
                    conventional foreign educational counsellors, GlobalDreamz
                    assists aspirants in identifying their talents, ambitions,
                    and desired careers and seeks to provide them with suitable
                    job options.
                    <br></br>
                    <br></br>
                    Based on the coursework and research opportunities provided
                    by the institution, our education counsellors have
                    consistently helped students select the best programmes.
                  </p>
                  <h5>Resume</h5>
                  <p>
                    At GlobalDreamz, we assess the client's accomplishments in
                    school, extracurricular activities, and the workplace. We
                    work together to write each applicant's CV in an organised
                    and clear manner, highlighting their skills.
                  </p>
                  <h5>Choosing Objectives</h5>
                  <p>
                    The main objective of GlobalDreamz is to assist candidates
                    in achieving both their short- and long-term objectives. We
                    help you get through the most crucial phase of your career
                    and assists in making the right choices as per your goals.
                  </p>
                  <h5>Visa Counselling</h5>
                  <p>
                    We are a specialised visa counselling company in India that
                    emphasises providing prompt services. By assisting you in
                    overcoming the last barrier in class and convenience,
                    GlobalDreamz enables you to fulfil your dream to pursue an
                    international education.
                  </p>
                  <h5>Application Procedure</h5>
                  <p>
                    With a degree from one of the top institutions in the UK,
                    you have several opportunities. Therefore, we make every
                    effort to ensure that you get accepted into the college and
                    programme of your preference. We guide you in completing the
                    necessary procedures for admission. Your chances of being
                    accepted into a UK institution will be increased by the
                    direct involvement of our professional counsellors.
                  </p>
                </div>
              </div>

              <div className="col-12">
                <div className="course-details-content">
                  <h3 className="title">Why choose us?</h3>

                  <p>
                    At GlobalDreamz, we guide you through the maze of questions
                    to find the ones that apply to your circumstance the most,
                    so you can still make the best career choice. At
                    GlobalDreamz, qualified and experienced counsellors are on
                    hand to assist students in determining their career goals,
                    assisting them in making more informed academic decisions,
                    and offering one-on-one counselling as they choose the
                    courses that best suit their needs. Additionally, we arrange
                    face-to-face meetings with university representatives and
                    offer comprehensive, unbiased information on various study
                    alternatives.
                    <br></br>
                    <br></br>
                    GlobalDreamz aims to help you make the right choices through
                    career counselling, as well as to arm you with the knowledge
                    and skills which you'll need to make better decisions about
                    your career.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AboutSix />
      </Layout>
    </>
  );
};
export default StudyInUKDreamz;
