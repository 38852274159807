import React, { useEffect, useState } from "react";
import SEO from "../../common/SEO";
import HeaderTwo from "../../common/header/HeaderTwo";
import BannerTwo from "../../components/banner/BannerTwo";
import AboutEight from "../../components/about/AboutEight";
import AboutUsTwoService from "../../components/about-us-two/AboutUsTwoService";
import AboutSix from "../../components/about/AboutSix";
import FooterOne from "../../common/footer/FooterOne";
import RegistrationPopup from "../../components/GRE Registration/RegistrationPopup";

const HomeDreamz = ({ classes }) => {
  return (
    <>
      {/* <RegistrationPopup isModalOpen={isModalOpen} closeModal={closeModal}/> */}
      <SEO
        title="Study abroad consultants in India | GlobalDreamz"
        description="Looking for the best study abroad consultants in India? GlobalDreamz leading study abroad consultants in India."
        keywords="study abroad consultants in india, abroad study visa consultants, higher education consultant in pune, best overseas education consultant in pune, GlobalDreamz"
        canonical="https://globaldreamzedu.com/"
      />
      <HeaderTwo styles="header-transparent header-style-2" />
      <BannerTwo />
      <AboutEight />
      <AboutUsTwoService />
      <AboutSix />
      <FooterOne />
    </>
  );
};

export default HomeDreamz;
