import React from "react";
import Student from "./Student";
import CoApplicant from "./CoApplicant";

const DocumentsRequired = () => {
  return (
    <div className="features" id="documents">
      <h3>Documentation Required</h3>
      <p>
        Some of the important documents needed to apply for State Bank of India
        education loan are:
      </p>
      <Student />
      <CoApplicant />
    </div>
  );
};

export default DocumentsRequired;
