import React from "react";
import Amount from "./Amount";
import EligibleCourse from "./EligibleCourse";
import Expenses from "./Expenses";
import InterestRates from "./InterestRate";
import ProcessingFee from "./ProcessingFee";
import Purpose from "./Purpose";
import RepaymentTenure from "./RepaymentTenure";
import Security from "./Security";

const Features = () => {
  return (
    <div className="features" id="features">
      <h3>Features & Benefits</h3>
      <Purpose />
      <Expenses />
      <Amount />
      <InterestRates />
      <ProcessingFee />
      <RepaymentTenure />
      <Security />
      <EligibleCourse />
    </div>
  );
};

export default Features;
